import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    headerName: "",
    headerImage: "",
    headerImageSrc: "",
    checkSwitch : ""
}

const headerSlice = createSlice({
    name: "header",
    initialState,
    reducers: {
        setHeaderDetails(state, { payload }) {
            return { ...state, ...payload };
        },
    },
});

export const {
    setHeaderDetails,
} = headerSlice.actions;

export default headerSlice.reducer;
