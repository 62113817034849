import React from 'react'
import "./Button.css"

function Button({ ...props }) {

  return (
    <button {...props}>
        {props.title}
    </button>
  )
}

export default Button