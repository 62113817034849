import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "../AdvanceAnalytics/AdvanceAnalytics.css";
import COCPOPTabs from "../../../components/AnalyticsTabs/AnalyticsTabs";
import COCDatePicker from "../../../components/COCDatepicker/COCDatepicker";
import {
  Box,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {
  createLocationCOCChart,
  createMaterialCocChart,
  createMaterialPOPChart,
  exportLocationCOCGraphExcel,
  exportLocationPOPGraphExcel,
  exportMaterialCOCGraphExcel,
  exportMaterialPOPGraphExcel,
  getCocLocation,
  getCocMaterials,
  getLocationInLocationGraph,
  getMaterialCocUnits,
  getMaterialInLocationGraph,
  getPOPMaterials,
} from "./services";
import { useDispatch, useSelector } from "react-redux";
import { setAdvanceAnalyticsDetail } from "./AnalyticsSlice";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { MaterialCOCChart } from "./MaterialCOCChart";
import { MaterialCOCPiechart } from "./MaterialCOCPieChart";
import POPDatePicker from "../../../components/POPDatePicker/POPDatePicker";
import { MaterialPOPGroupChart } from "./MaterialPOPGroupChart";
import { MaterialPOPPiechart } from "./MaterialPOPOPiechart";

const AdvanceAnalytics = () => {
  const navigate = useNavigate();

  const [activeClass, setActiveClass] = useState("Material");
  const [active, setActive] = useState("Consumer");
  const [getUnit, setGetUnits] = useState([]);
  const [getMaterialData, setGetMatrialData] = useState([]);
  const [getPOPMaterialData, setPOPMaterialData] = useState([]);
  const [totalsum, setTotalsum] = useState();
  const [getLocationData, setLocationData] = useState([]);
  const [getPOPLocationData, setPOPLocationData] = useState([]);
  const [materialCocChartData, setMaterialChartData] = useState([]);
  const [materialPOPChartData, setMaterialPOPChartData] = useState([]);
  const [materialPOP1ChartData, setMaterialPOP1ChartData] = useState([]);
  const [popTotalSum, setPOPTotalSum] = useState();
  const [pop1TotalSum, setPOP1TotalSum] = useState();
  const [locationCOClocationData, setLocationCOCLocationData] = useState([]);
  const [locationPOPlocationData, setLocationPOPLocationData] = useState([]);
  const [materialLocationData, setMaterialLocationData] = useState([]);
  const [locationCOCData, setLocationCOCData] = useState([]);
  const [locationPOPData, setLocationPOPData] = useState([]);
  const [locationPOP1Data, setLocationPOP1Data] = useState([]);
  const [locationUnit, setLocationUnits] = useState();
  const [locationTotalSum, setLocationTotalSum] = useState();
  const [materialPOPLocationData, setMaterialPOPLocationData] = useState([]);
  const [locationPOPUnit, setLocationPOPUnits] = useState();
  const [locationPOPTotalSum, setLocationPOPTotalSum] = useState();
  const [locationPOP1TotalSum, setLocationPOP1TotalSum] = useState();
  const [searchUnits, setSearchUnits] = useState("");
  const [searchMaterials, setSearchMaterials] = useState("");
  const [searchLocation, setSearchLocation] = useState("");
  const [searchPOPMaterials, setSearchPOPMaterials] = useState("");
  const [searchPOPLocations, setSearchPOPLocations] = useState("");
  const [searchLocationFilter, setSearchLocationFilter] = useState("");
  const [searchLocationMaterials, setSearchLocationMaterials] = useState("");
  const [searchLocationPOPfilter, setSearchLocationPOPfilter] = useState("");
  const [searchLocationPOPMaterial, setSearchLocationPOPMaterial] =
    useState("");
  const [count, setCount] = useState(0);

  const MAX_DATE_RANGE_DAYS = 365; // Maximum duration of one year in days

  const {
    unitsChecked,
    getUnitRedux,
    materialCocStartDate,
    materialCocEndDate,
    materialIdRedux,
    materialColors,
    fromLocation,
    toLocation,
    locationChecked,
    locationId,
    materialPOPColors,
    materialIdPOPRedux,
    popFromLocation,
    popToLocation,
    locationPOPId,
    locationPopChecked,
    materialPOPStartDate,
    materialPOPEndDate,
    materialPOP1StartDate,
    materialPOP1EndDate,
    lLocationChecked,
    lLocationId,
    lLocationPOPId,
    lLocationPOPChecked,
    lMaterialPOPChecked,
    locationfromlocation,
    locationToLocation,
    locationPopFromLocation,
    locationPopToLocation,
    locationColors,
    locationStartDate,
    locationEndDate,
    locationPOPStartDate,
    locationPOPEndDate,
    locationPOP1StartDate,
    locationPOP1EndDate,
    locationMaterialId,
    locationPOPMaterialId,
    locationPOPColors,
    showTime,
    POPshowTime,
    showLocationTime,
    showLocationPopTime,
  } = useSelector((state) => state.analyticsState);


  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm:ss";

  const startDateReFormat = materialCocStartDate
    ? dayjs(materialCocStartDate)
    : null;
  const endDateReFormat = materialCocEndDate ? dayjs(materialCocEndDate) : null;

  const startDatePOPReFormat = materialPOPStartDate
    ? dayjs(materialPOPStartDate)
    : null;
  const endDatePOPReFormat = materialPOPEndDate
    ? dayjs(materialPOPEndDate)
    : null;

  const startDatePOP1ReFormat = materialPOP1StartDate
    ? dayjs(materialPOP1StartDate)
    : null;
  const endDatePOP1ReFormat = materialPOP1EndDate
    ? dayjs(materialPOP1EndDate)
    : null;

  const startDateLocationReFormat = locationStartDate
    ? dayjs(locationStartDate)
    : null;
  const endDateLocationReFormat = locationEndDate
    ? dayjs(locationEndDate)
    : null;

  const startDatePOPLocationReFormat = locationPOPStartDate
    ? dayjs(locationPOPStartDate)
    : null;
  const endDatePOPLocationReFormat = locationPOPEndDate
    ? dayjs(locationPOPEndDate)
    : null;

  const startDatePOPLocation1ReFormat = locationPOP1StartDate
    ? dayjs(locationPOP1StartDate)
    : null;
  const endDatePOPLocation1ReFormat = locationPOP1EndDate
    ? dayjs(locationPOP1EndDate)
    : null;

  const formatLocationStartDate = locationStartDate
    ? dayjs(locationStartDate).format(dateFormat)
    : "";
  const formatLocationEndDate = locationEndDate
    ? dayjs(locationEndDate).format(dateFormat)
    : "";
  const formatLocationStartTime = locationStartDate
    ? dayjs(locationStartDate).format(timeFormat)
    : "";
  const formatLocationEndTime = locationEndDate
    ? dayjs(locationEndDate).format(timeFormat)
    : "";

  const formatPOPLocationStartDate = locationPOPStartDate
    ? dayjs(locationPOPStartDate).format(dateFormat)
    : "";
  const formatPOPLocationEndDate = locationPOPEndDate
    ? dayjs(locationPOPEndDate).format(dateFormat)
    : "";

  const formatPOPLocationStartTime = locationPOPStartDate
    ? dayjs(locationPOPStartDate).format(timeFormat)
    : "";
  const formatPOPLocationEndTime = locationPOPEndDate
    ? dayjs(locationPOPEndDate).format(timeFormat)
    : "";

  const formatPOP1LocationStartDate = locationPOP1StartDate
    ? dayjs(locationPOP1StartDate).format(dateFormat)
    : "";
  const formatPOP1LocationEndDate = locationPOP1EndDate
    ? dayjs(locationPOP1EndDate).format(dateFormat)
    : "";

  const formatPOP1LocationStartTime = locationPOP1StartDate
    ? dayjs(locationPOP1StartDate).format(timeFormat)
    : "";
  const formatPOP1LocationEndTime = locationPOP1EndDate
    ? dayjs(locationPOP1EndDate).format(timeFormat)
    : "";

  const formatStartDate = materialCocStartDate
    ? dayjs(materialCocStartDate).format(dateFormat)
    : "";
  const formatEndDate = materialCocEndDate
    ? dayjs(materialCocEndDate).format(dateFormat)
    : "";

  const formatStartTime = materialCocStartDate
    ? dayjs(materialCocStartDate).format(timeFormat)
    : "";
  const formatEndTime = materialCocEndDate
    ? dayjs(materialCocEndDate).format(timeFormat)
    : "";

  const formatPOPStartDate = materialPOPStartDate
    ? dayjs(materialPOPStartDate).format(dateFormat)
    : "";
  const formatPOPEndDate = materialPOPEndDate
    ? dayjs(materialPOPEndDate).format(dateFormat)
    : "";

  const formatPOPStartTime = materialPOPStartDate
    ? dayjs(materialPOPStartDate).format(timeFormat)
    : "";
  const formatPOPEndTime = materialPOPEndDate
    ? dayjs(materialPOPEndDate).format(timeFormat)
    : "";

  const formatPOP1StartDate = materialPOP1StartDate
    ? dayjs(materialPOP1StartDate).format(dateFormat)
    : "";
  const formatPOP1EndDate = materialPOP1EndDate
    ? dayjs(materialPOP1EndDate).format(dateFormat)
    : "";

  const formatPOP1StartTime = materialPOP1StartDate
    ? dayjs(materialPOP1StartDate).format(timeFormat)
    : "";
  const formatPOP1EndTime = materialPOP1EndDate
    ? dayjs(materialPOP1EndDate).format(timeFormat)
    : "";
  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });
  const notifySuccess = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const handleSearchChange = (event) => {
    setSearchUnits(event.target.value);
  };
  const handleSearchMaterialChange = (event) => {
    setSearchMaterials(event.target.value);
  };
  const handleStartDate = (newValue) => {
    const durationInDays = endDateReFormat
      ? endDateReFormat.diff(newValue, "days")
        ? endDateReFormat.diff(newValue, "days") > 0
          ? endDateReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(
        setAdvanceAnalyticsDetail({
          materialCocStartDate: null,
        })
      );
      notifyError("Date range must be within one year");
      return true;
    }

    if (endDateReFormat && newValue.isBefore(endDateReFormat)) {
      dispatch(
        setAdvanceAnalyticsDetail({
          materialCocStartDate: newValue,
        })
      );
    } else if (!endDateReFormat) {
      dispatch(
        setAdvanceAnalyticsDetail({
          materialCocStartDate: newValue,
        })
      );
    } else if (endDateReFormat && newValue.isSame(endDateReFormat, "day")) {
      dispatch(
        setAdvanceAnalyticsDetail({
          materialCocStartDate: newValue,
        })
      );
    } else {
      dispatch(
        setAdvanceAnalyticsDetail({
          materialCocStartDate: null,
        })
      );
      notifyError("End date must be greater than start date");
    }
  };

  const handleEndDate = (newValue) => {
    const durationInDays = startDateReFormat
      ? startDateReFormat.diff(newValue, "days")
        ? startDateReFormat.diff(newValue, "days") > 0
          ? startDateReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(
        setAdvanceAnalyticsDetail({
          materialCocEndDate: null,
        })
      );
      notifyError("Date range must be within one year");
      return true;
    }

    if (startDateReFormat && newValue.isAfter(startDateReFormat)) {
      dispatch(
        setAdvanceAnalyticsDetail({
          materialCocEndDate: newValue,
        })
      );
    } else if (!startDateReFormat) {
      dispatch(
        setAdvanceAnalyticsDetail({
          materialCocEndDate: newValue,
        })
      );
    } else if (startDateReFormat && newValue.isSame(startDateReFormat, "day")) {
      // End date is not set yet
      dispatch(
        setAdvanceAnalyticsDetail({
          materialCocEndDate: newValue,
        })
      );
    } else {
      // Start date is after end date
      dispatch(
        setAdvanceAnalyticsDetail({
          materialCocEndDate: null,
        })
      );
      notifyError(" startDate must less than endDate");
    }
  };

  const handlePOPStartDate = (newValue) => {
    const durationInDays = endDatePOPReFormat
      ? endDatePOPReFormat.diff(newValue, "days")
        ? endDatePOPReFormat.diff(newValue, "days") > 0
          ? endDatePOPReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;
    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      // Valid date range (within one year)
      dispatch(setAdvanceAnalyticsDetail({ materialPOPStartDate: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (endDatePOPReFormat && newValue.isBefore(endDatePOPReFormat)) {
      // Start date is before end date
      dispatch(setAdvanceAnalyticsDetail({ materialPOPStartDate: newValue }));
    } else if (!endDatePOPReFormat) {
      // End date is not set yet
      dispatch(setAdvanceAnalyticsDetail({ materialPOPStartDate: newValue }));
    } else if (
      endDatePOPReFormat &&
      newValue.isSame(endDatePOPReFormat, "day")
    ) {
      // End date is not set yet
      dispatch(setAdvanceAnalyticsDetail({ materialPOPStartDate: newValue }));
    } else {
      // Start date is after end date
      dispatch(setAdvanceAnalyticsDetail({ materialPOPStartDate: null }));
      notifyError("End date must be greater than start date");
    }

    // dispatch(setAdvanceAnalyticsDetail({ materialPOPStartDate: newValue }));
  };

  const handlePOPEndDate = (newValue) => {
    const durationInDays = startDatePOPReFormat
      ? startDatePOPReFormat.diff(newValue, "days")
        ? startDatePOPReFormat.diff(newValue, "days") > 0
          ? startDatePOPReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(setAdvanceAnalyticsDetail({ materialPOPEndDate: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (startDatePOPReFormat && newValue.isAfter(startDatePOPReFormat)) {
      dispatch(setAdvanceAnalyticsDetail({ materialPOPEndDate: newValue }));
    } else if (!startDatePOPReFormat) {
      dispatch(setAdvanceAnalyticsDetail({ materialPOPEndDate: newValue }));
    } else if (
      startDatePOPReFormat &&
      newValue.isSame(startDatePOPReFormat, "day")
    ) {
      // End date is not set yet
      dispatch(setAdvanceAnalyticsDetail({ materialPOPEndDate: newValue }));
    } else {
      // Start date is after end date
      dispatch(setAdvanceAnalyticsDetail({ materialPOPEndDate: null }));
      notifyError(" startDate must less than endDate");
    }

    // dispatch(setAdvanceAnalyticsDetail({ materialPOPEndDate: newValue }));
  };
  const handlePOP1StartDate = (newValue) => {
    const durationInDays = endDatePOP1ReFormat
      ? endDatePOP1ReFormat.diff(newValue, "days")
        ? endDatePOP1ReFormat.diff(newValue, "days") > 0
          ? endDatePOP1ReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      // Valid date range (within one year)
      dispatch(setAdvanceAnalyticsDetail({ materialPOP1StartDate: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (endDatePOP1ReFormat && newValue.isBefore(endDatePOP1ReFormat)) {
      // Start date is before end date
      dispatch(setAdvanceAnalyticsDetail({ materialPOP1StartDate: newValue }));
    } else if (!endDatePOP1ReFormat) {
      // End date is not set yet
      dispatch(setAdvanceAnalyticsDetail({ materialPOP1StartDate: newValue }));
    } else if (
      endDatePOP1ReFormat &&
      newValue.isSame(endDatePOP1ReFormat, "day")
    ) {
      // End date is not set yet
      dispatch(setAdvanceAnalyticsDetail({ materialPOP1StartDate: newValue }));
    } else {
      // Start date is after end date
      dispatch(setAdvanceAnalyticsDetail({ materialPOP1StartDate: null }));
      notifyError("End date must be greater than start date");
    }

    // dispatch(setAdvanceAnalyticsDetail({ materialPOP1StartDate: newValue }));
  };

  const handlePOP1EndDate = (newValue) => {
    const durationInDays = startDatePOP1ReFormat
      ? startDatePOP1ReFormat.diff(newValue, "days")
        ? startDatePOP1ReFormat.diff(newValue, "days") > 0
          ? startDatePOP1ReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(setAdvanceAnalyticsDetail({ materialPOP1EndDate: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (startDatePOP1ReFormat && newValue.isAfter(startDatePOP1ReFormat)) {
      dispatch(setAdvanceAnalyticsDetail({ materialPOP1EndDate: newValue }));
    } else if (!startDatePOP1ReFormat) {
      dispatch(setAdvanceAnalyticsDetail({ materialPOP1EndDate: newValue }));
    } else if (
      startDatePOP1ReFormat &&
      newValue.isSame(startDatePOP1ReFormat, "day")
    ) {
      // End date is not set yet
      dispatch(setAdvanceAnalyticsDetail({ materialPOP1EndDate: newValue }));
    } else {
      // Start date is after end date
      dispatch(setAdvanceAnalyticsDetail({ materialPOP1EndDate: null }));
      notifyError(" startDate must less than endDate");
    }

    // dispatch(setAdvanceAnalyticsDetail({ materialPOP1EndDate: newValue }));
  };

  const handleLocationStartDate = (newValue) => {
    const durationInDays = endDateLocationReFormat
      ? endDateLocationReFormat.diff(newValue, "days")
        ? endDateLocationReFormat.diff(newValue, "days") > 0
          ? endDateLocationReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(
        setAdvanceAnalyticsDetail({
          locationStartDate: null,
        })
      );
      notifyError("Date range must be within one year");
      return true;
    }

    if (endDateLocationReFormat && newValue.isBefore(endDateLocationReFormat)) {
      dispatch(
        setAdvanceAnalyticsDetail({
          locationStartDate: newValue,
        })
      );
    } else if (!endDateLocationReFormat) {
      dispatch(
        setAdvanceAnalyticsDetail({
          locationStartDate: newValue,
        })
      );
    } else if (
      endDateLocationReFormat &&
      newValue.isSame(endDateLocationReFormat, "day")
    ) {
      dispatch(
        setAdvanceAnalyticsDetail({
          locationStartDate: newValue,
        })
      );
    } else {
      dispatch(
        setAdvanceAnalyticsDetail({
          locationStartDate: null,
        })
      );
      notifyError("End date must be greater than start date");
    }
  };

  const handleLocationEndDate = (newValue) => {
    const durationInDays = startDateLocationReFormat
      ? startDateLocationReFormat.diff(newValue, "days")
        ? startDateLocationReFormat.diff(newValue, "days") > 0
          ? startDateLocationReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(
        setAdvanceAnalyticsDetail({
          locationEndDate: null,
        })
      );
      notifyError("Date range must be within one year");
      return true;
    }

    if (
      startDateLocationReFormat &&
      newValue.isAfter(startDateLocationReFormat)
    ) {
      dispatch(
        setAdvanceAnalyticsDetail({
          locationEndDate: newValue,
        })
      );
    } else if (!startDateLocationReFormat) {
      dispatch(
        setAdvanceAnalyticsDetail({
          locationEndDate: newValue,
        })
      );
    } else if (
      startDateLocationReFormat &&
      newValue.isSame(startDateLocationReFormat, "day")
    ) {
      // End date is not set yet
      dispatch(
        setAdvanceAnalyticsDetail({
          locationEndDate: newValue,
        })
      );
    } else {
      // Start date is after end date
      dispatch(
        setAdvanceAnalyticsDetail({
          locationEndDate: null,
        })
      );
      notifyError(" startDate must less than endDate");
    }
  };

  const handleLocationPOPStartDate = (newValue) => {
    const durationInDays = endDatePOPLocationReFormat
      ? endDatePOPLocationReFormat.diff(newValue, "days")
        ? endDatePOPLocationReFormat.diff(newValue, "days") > 0
          ? endDatePOPLocationReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;
    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      // Valid date range (within one year)
      dispatch(setAdvanceAnalyticsDetail({ locationPOPStartDate: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (
      endDatePOPLocationReFormat &&
      newValue.isBefore(endDatePOPLocationReFormat)
    ) {
      // Start date is before end date
      dispatch(setAdvanceAnalyticsDetail({ locationPOPStartDate: newValue }));
    } else if (!endDatePOPLocationReFormat) {
      // End date is not set yet
      dispatch(setAdvanceAnalyticsDetail({ locationPOPStartDate: newValue }));
    } else if (
      endDatePOPLocationReFormat &&
      newValue.isSame(endDatePOPLocationReFormat, "day")
    ) {
      // End date is not set yet
      dispatch(setAdvanceAnalyticsDetail({ locationPOPStartDate: newValue }));
    } else {
      // Start date is after end date
      dispatch(setAdvanceAnalyticsDetail({ locationPOPStartDate: null }));
      notifyError("End date must be greater than start date");
    }

    // dispatch(setAdvanceAnalyticsDetail({ materialPOPStartDate: newValue }));
  };

  const handleLocationPOPEndDate = (newValue) => {
    const durationInDays = startDatePOPLocationReFormat
      ? startDatePOPLocationReFormat.diff(newValue, "days")
        ? startDatePOPLocationReFormat.diff(newValue, "days") > 0
          ? startDatePOPLocationReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(setAdvanceAnalyticsDetail({ locationPOPEndDate: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (
      startDatePOPLocationReFormat &&
      newValue.isAfter(startDatePOPLocationReFormat)
    ) {
      dispatch(setAdvanceAnalyticsDetail({ locationPOPEndDate: newValue }));
    } else if (!startDatePOPLocationReFormat) {
      dispatch(setAdvanceAnalyticsDetail({ locationPOPEndDate: newValue }));
    } else if (
      startDatePOPLocationReFormat &&
      newValue.isSame(startDatePOPLocationReFormat, "day")
    ) {
      // End date is not set yet
      dispatch(setAdvanceAnalyticsDetail({ locationPOPEndDate: newValue }));
    } else {
      // Start date is after end date
      dispatch(setAdvanceAnalyticsDetail({ locationPOPEndDate: null }));
      notifyError(" startDate must less than endDate");
    }

    // dispatch(setAdvanceAnalyticsDetail({ materialPOPEndDate: newValue }));
  };
  const handleLocationPOP1StartDate = (newValue) => {
    const durationInDays = endDatePOPLocation1ReFormat
      ? endDatePOPLocation1ReFormat.diff(newValue, "days")
        ? endDatePOPLocation1ReFormat.diff(newValue, "days") > 0
          ? endDatePOPLocation1ReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      // Valid date range (within one year)
      dispatch(setAdvanceAnalyticsDetail({ locationPOP1StartDate: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (
      endDatePOPLocation1ReFormat &&
      newValue.isBefore(endDatePOPLocation1ReFormat)
    ) {
      // Start date is before end date
      dispatch(setAdvanceAnalyticsDetail({ locationPOP1StartDate: newValue }));
    } else if (!endDatePOPLocation1ReFormat) {
      // End date is not set yet
      dispatch(setAdvanceAnalyticsDetail({ locationPOP1StartDate: newValue }));
    } else if (
      endDatePOPLocation1ReFormat &&
      newValue.isSame(endDatePOPLocation1ReFormat, "day")
    ) {
      // End date is not set yet
      dispatch(setAdvanceAnalyticsDetail({ locationPOP1StartDate: newValue }));
    } else {
      // Start date is after end date
      dispatch(setAdvanceAnalyticsDetail({ locationPOP1StartDate: null }));
      notifyError("End date must be greater than start date");
    }

    // dispatch(setAdvanceAnalyticsDetail({ materialPOP1StartDate: newValue }));
  };

  const handleLocationPOP1EndDate = (newValue) => {
    const durationInDays = startDatePOPLocation1ReFormat
      ? startDatePOPLocation1ReFormat.diff(newValue, "days")
        ? startDatePOPLocation1ReFormat.diff(newValue, "days") > 0
          ? startDatePOPLocation1ReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(setAdvanceAnalyticsDetail({ locationPOP1EndDate: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (
      startDatePOPLocation1ReFormat &&
      newValue.isAfter(startDatePOPLocation1ReFormat)
    ) {
      dispatch(setAdvanceAnalyticsDetail({ locationPOP1EndDate: newValue }));
    } else if (!startDatePOPLocation1ReFormat) {
      dispatch(setAdvanceAnalyticsDetail({ locationPOP1EndDate: newValue }));
    } else if (
      startDatePOPLocation1ReFormat &&
      newValue.isSame(startDatePOPLocation1ReFormat, "day")
    ) {
      // End date is not set yet
      dispatch(setAdvanceAnalyticsDetail({ locationPOP1EndDate: newValue }));
    } else {
      // Start date is after end date
      dispatch(setAdvanceAnalyticsDetail({ locationPOP1EndDate: null }));
      notifyError(" startDate must less than endDate");
    }

    // dispatch(setAdvanceAnalyticsDetail({ materialPOP1EndDate: newValue }));
  };

  useEffect(() => {
    handlegetUnits();
  }, []);

  useEffect(() => {
    if (materialIdRedux && (fromLocation || toLocation)) {
      handleGetLocation();
    } else {
      setLocationData([]);
    }
  }, [materialIdRedux, fromLocation, toLocation]);

  useEffect(() => {
    if (materialIdPOPRedux && (popFromLocation || popToLocation)) {
      handleGetPOPLocation();
    } else {
      setPOPLocationData([]);
    }
  }, [materialIdPOPRedux, popFromLocation, popToLocation]);

  const handlegetUnits = async () => {
    try {
      const resp = await getMaterialCocUnits();
      if (resp.status == 200 || resp.status == 201) {
        setGetUnits(resp.data.payload.unit);
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };
  const handleToggleTime = () => {
    dispatch(setAdvanceAnalyticsDetail({ showTime: !showTime }));
  };
  const handlePOPToggleTime = () => {
    dispatch(setAdvanceAnalyticsDetail({ POPshowTime: !POPshowTime }));
  };
  const handleLocationToggleTime = () => {
    dispatch(
      setAdvanceAnalyticsDetail({ showLocationTime: !showLocationTime })
    );
  };
  const handleLocationPOPToggleTime = () => {
    dispatch(
      setAdvanceAnalyticsDetail({ showLocationPopTime: !showLocationPopTime })
    );
  };

  useEffect(() => {
    handelPOPMaterial();
  }, []);

  const handelPOPMaterial = async () => {
    try {
      const resp = await getPOPMaterials();
      if (resp.status == 200 || resp.status == 201) {
        setPOPMaterialData(resp.data.payload);
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };

  const switchColors = [
    "#FEBB22",
    "#002B2A",
    "#C99C00",
    "#EB9E79",
    "#69927A",
    "#872B81",
    "#F06292",
    "#0EB520",
    "#CD4D4A",
    "#123782",
    "#544632",
    "#94655B",
    "#0F4C81",
    "#8B0000",
    "#C60BC8",
    "#7D2264",
    "#BC2F00",
    "#556830",
    "#FCA311",
    "#021116",
    "#808080",
    "#300338",
    "#634C23",
    "#841E62",
    "#F34424",
    "#003333",
    "#020000",
    "#305355",
    "#5C3A31",
    "#D9750C",
    "#0C4562",
    "#001A30",
    "#00838F",
    "#785049",
    "#FF8800",
    "#0B7C8E",
    "#08535E",
    "#0E3504",
    "#C48200",
    "#846748",
    "#075571",
    "#9C27B0",
    "#A97E07",
    "#CE286D",
    "#8409DF",
    "#0B648D",
    "#29445A",
    "#1D733F",
    "#6E02B1",
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    if (getUnitRedux !== "") {
      handleMaterialData();
    }
  }, [getUnitRedux]);

  const handleMaterialData = async () => {
    const params = {
      unit: getUnitRedux,
    };

    try {
      const resp = await getCocMaterials(params);
      if (resp.status === 200 || resp.status === 201) {
        setGetMatrialData(resp.data.payload);
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };

  const handleSelectUnit = async (index) => {
    const newUnitIndex = unitsChecked === index ? null : index;

    dispatch(
      setAdvanceAnalyticsDetail({
        unitsChecked: newUnitIndex,
      })
    );

    if (newUnitIndex === null) {
      dispatch(
        setAdvanceAnalyticsDetail({
          getUnitRedux: null,
          materialIdRedux: [],
          materialColors: [],
          toLocation: false,
          fromLocation: false,
        })
      );

      setGetMatrialData([]);
      setLocationData([]);
      setMaterialChartData([]);
      // setFromLocation(false);
      // setToLocation(false);
    } else {
      dispatch(
        setAdvanceAnalyticsDetail({
          getUnitRedux: getUnit[index],
        })
      );
    }
  };

  const handleFromLocation = (checked) => {
    dispatch(setAdvanceAnalyticsDetail({ fromLocation: checked }));
    if (checked) {
      dispatch(setAdvanceAnalyticsDetail({ toLocation: false }));
      setLocationData([]);
    } else {
      setLocationData([]);
      dispatch(
        setAdvanceAnalyticsDetail({ locationChecked: null, locationId: null })
      );
    }
  };

  const handleToLocation = (checked) => {
    dispatch(setAdvanceAnalyticsDetail({ toLocation: checked }));
    if (checked) {
      dispatch(setAdvanceAnalyticsDetail({ fromLocation: false }));
    } else {
      setLocationData([]);
      dispatch(
        setAdvanceAnalyticsDetail({ locationChecked: null, locationId: null })
      );
    }
  };

  const handlePOPFromLocation = (checked) => {
    dispatch(setAdvanceAnalyticsDetail({ popFromLocation: checked }));
    if (checked) {
      dispatch(setAdvanceAnalyticsDetail({ popToLocation: false }));
      setPOPLocationData([]);
    } else {
      setPOPLocationData([]);
    }
  };

  const handlePOPToLocation = (checked) => {
    dispatch(setAdvanceAnalyticsDetail({ popToLocation: checked }));
    if (checked) {
      dispatch(setAdvanceAnalyticsDetail({ popFromLocation: false }));
    } else {
      setPOPLocationData([]);
    }
  };

  const handleLocationFromLocation = (checked) => {
    dispatch(setAdvanceAnalyticsDetail({ locationfromlocation: checked }));
    if (checked) {
      dispatch(setAdvanceAnalyticsDetail({ locationToLocation: false }));
      setLocationCOCLocationData([]);
      setMaterialLocationData([]);
      setLocationCOCData([]);
    } else {
      setLocationCOCLocationData([]);
      setMaterialLocationData([]);
      setLocationCOCData([]);
      dispatch(
        setAdvanceAnalyticsDetail({ lLocationId: [], lLocationChecked: null })
      );
    }
  };

  const handleLocationToLocation = (checked) => {
    dispatch(setAdvanceAnalyticsDetail({ locationToLocation: checked }));
    if (checked) {
      dispatch(setAdvanceAnalyticsDetail({ locationfromlocation: false }));
      setLocationCOCLocationData([]);
      setMaterialLocationData([]);
      setLocationCOCData([]);
    } else {
      setLocationCOCLocationData([]);
      setMaterialLocationData([]);
      setLocationCOCData([]);
      dispatch(
        setAdvanceAnalyticsDetail({ lLocationId: [], lLocationChecked: null })
      );
    }
  };

  const handleLocationPOPFromLocation = (checked) => {
    dispatch(setAdvanceAnalyticsDetail({ locationPopFromLocation: checked }));
    if (checked) {
      dispatch(setAdvanceAnalyticsDetail({ locationPopToLocation: false }));
      setLocationPOPData([]);
      setLocationPOPLocationData([]);
      setLocationPOP1Data([]);
    } else {
      dispatch(
        setAdvanceAnalyticsDetail({
          lLocationPOPChecked: null,
          lLocationPOPId: null,
          lMaterialPOPChecked: null,
          locationPOPMaterialId: null,
        })
      );
    }
  };

  const handleLocationPOPToLocation = (checked) => {
    dispatch(setAdvanceAnalyticsDetail({ locationPopToLocation: checked }));
    if (checked) {
      dispatch(setAdvanceAnalyticsDetail({ locationPopFromLocation: false }));
      setLocationPOPData([]);
      setLocationPOPLocationData([]);
      setLocationPOP1Data([]);
    } else {
      dispatch(
        setAdvanceAnalyticsDetail({
          lLocationPOPChecked: null,
          lLocationPOPId: null,
          lMaterialPOPChecked: null,
          locationPOPMaterialId: null,
        })
      );
    }
  };

  const handleLocationCOCExcel = async () => {
    const params = {
      material: locationMaterialId,
      from_location: locationfromlocation,
      to_location: locationToLocation,
      location: lLocationId.map((id) => id.deviceID),
      start_datetime: `${formatLocationStartDate} 00:00:00`,
      end_datetime: `${formatLocationEndDate} 23:59:59`,
    };
    if (showLocationTime) {
      params[
        "start_datetime"
      ] = `${formatLocationStartDate} ${formatLocationStartTime}`;
      params[
        "end_datetime"
      ] = `${formatLocationEndDate} ${formatLocationEndTime}`;
    }
    try {
      const resp = await exportLocationCOCGraphExcel(params);
      if (resp.status == 200 || resp.status == 201) {
        notifySuccess(resp.data.message);
      } else {
        notifyError(resp.data.message);
      }
    } catch (error) {
      notifyError(error.response.data.message);
    }
  };

  const handleLocationPOPExcel = async () => {
    const params = {
      material: locationPOPMaterialId,
      from_location: locationPopFromLocation,
      to_location: locationPopToLocation,
      location: lLocationPOPId,
      start_datetime1: `${formatPOPLocationStartDate} 00:00:00`,
      end_datetime1: `${formatPOPLocationEndDate} 23:59:59`,
      start_datetime2: `${formatPOP1LocationStartDate} 00:00:00`,
      end_datetime2: `${formatPOP1LocationEndDate} 23:59:59`,
    };

    if (showLocationPopTime) {
      params[
        "start_datetime1"
      ] = `${formatPOPLocationStartDate} ${formatPOPLocationStartTime}`;
      params[
        "end_datetime1"
      ] = `${formatPOPLocationEndDate} ${formatPOPLocationEndTime}`;
      params[
        "start_datetime2"
      ] = `${formatPOP1LocationStartDate} ${formatPOP1LocationStartTime}`;
      params[
        "end_datetime2"
      ] = `${formatPOP1LocationEndDate} ${formatPOP1LocationEndTime}`;
    }
    try {
      const resp = await exportLocationPOPGraphExcel(params);
      if (resp.status == 200 || resp.status == 201) {
        notifySuccess(resp.data.message);
      } else {
        notifyError(resp.data.message);
      }
    } catch (error) {
      notifyError(error.response.data.message);
    }
  };

  const handleMaterialCOCExcel = async()=>{
    const params = {
      unit: getUnitRedux,
      material: materialIdRedux.map((id) => id.deviceID),
      from_location: fromLocation,
      to_location: toLocation,
      location: locationId,
      start_datetime: `${formatStartDate} 00:00:00`,
      end_datetime: `${formatEndDate} 23:59:59`,
    };
    if (showTime) {
      params["start_datetime"] = `${formatStartDate} ${formatStartTime}`;
      params["end_datetime"] = `${formatEndDate} ${formatEndTime}`;
    }
    try{
      const resp = await exportMaterialCOCGraphExcel(params)
      if(resp.status == 200 || resp.status == 201){
        notifySuccess(resp.data.message)
      }
      else{
        notifyError(resp.data.message)
      }
    }
    catch(error){
      notifyError(error.response.data.message)
    }
  }
  const handleMaterialPOPExcel = async()=>{
    const params = {
      material: materialIdPOPRedux[0]?.deviceID,
      from_location: popFromLocation,
      to_location: popToLocation,
      location: locationPOPId,
      start_datetime1: `${formatPOPStartDate} 00:00:00`,
      end_datetime1: `${formatPOPEndDate} 23:59:59`,
      start_datetime2: `${formatPOP1StartDate} 00:00:00`,
      end_datetime2: `${formatPOP1EndDate} 23:59:59`,
    };
    if (POPshowTime) {
      params["start_datetime1"] = `${formatPOPStartDate} ${formatPOPStartTime}`;
      params["end_datetime1"] = `${formatPOPEndDate} ${formatPOPEndTime}`;
      params["start_datetime2"] = `${formatPOP1StartDate} ${formatPOP1StartTime}`;
      params["end_datetime2"] = `${formatPOP1EndDate} ${formatPOP1EndTime}`;
    }
    try{
      const resp = await exportMaterialPOPGraphExcel(params)
      if(resp.status == 200 || resp.status == 201){
        notifySuccess(resp.data.message)
      }
      else{
        notifyError(resp.data.message)
      }
    }
    catch(error){
      notifyError(error.response.data.message)
    }
  }


  useEffect(() => {
    if (locationPopFromLocation || locationPopToLocation) {
      handleGetLocationPOP();
    } else {
      setLocationPOPLocationData([]);
    }
  }, [locationPopFromLocation, locationPopToLocation]);

  const handleGetLocationPOP = async () => {
    const params = {
      from_location: locationPopFromLocation,
      to_location: locationPopToLocation,
    };
    try {
      const resp = await getLocationInLocationGraph(params);
      if (resp.status == 200 || resp.status == 201) {
        setLocationPOPLocationData(resp.data.payload);
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };
  const handleGetLocation = async () => {
    const params = {
      material: materialIdRedux.map((id) => id.deviceID),
      from_location: fromLocation,
      to_location: toLocation,
    };
    try {
      const resp = await getCocLocation(params);
      if (resp.status == 200 || resp.status == 201) {
        setLocationData(resp.data.payload);
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };

  const handleGetPOPLocation = async () => {
    const params = {
      material: materialIdPOPRedux.map((id) => id.deviceID),
      from_location: popFromLocation,
      to_location: popToLocation,
    };
    try {
      const resp = await getCocLocation(params);
      if (resp.status == 200 || resp.status == 201) {
        setPOPLocationData(resp.data.payload);
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };

  useEffect(() => {
    if (locationfromlocation || locationToLocation) {
      handleLocationCOCLocation();
    }
  }, [locationfromlocation, locationToLocation]);

  const handleLocationCOCLocation = async () => {
    const params = {
      from_location: locationfromlocation,
      to_location: locationToLocation,
    };
    try {
      const resp = await getLocationInLocationGraph(params);
      if (resp.status == 200 || resp.status == 201) {
        setLocationCOCLocationData(resp.data.payload);
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };

  const handleLocationClick = (index, e) => {
    const newUnitIndex = locationChecked === index ? null : index;
    dispatch(
      setAdvanceAnalyticsDetail({
        locationChecked: newUnitIndex,
        locationId: e.id,
      })
    );
    if (newUnitIndex == null) {
      dispatch(
        setAdvanceAnalyticsDetail({
          locationChecked: null,
          locationId: null,
        })
      );
    }
  };

  const handleLocationPOPLocationClick = (index, e) => {
    const newUnitIndex = lLocationPOPChecked === index ? null : index;

    dispatch(
      setAdvanceAnalyticsDetail({
        lLocationPOPChecked: newUnitIndex,
        lLocationPOPId: e.id,
      })
    );

    if (newUnitIndex == null) {
      setMaterialLocationData([]);
      dispatch(
        setAdvanceAnalyticsDetail({
          lLocationPOPChecked: null,
          lLocationPOPId: null,
          lMaterialPOPChecked: null,
          locationPOPMaterialId: null,
        })
      );
    }
  };

  const handlePOPLocationClick = (index, e) => {
    const newUnitIndex = locationPopChecked === index ? null : index;
    dispatch(
      setAdvanceAnalyticsDetail({
        locationPopChecked: newUnitIndex,
        locationPOPId: e.id,
      })
    );
    if (newUnitIndex == null) {
      dispatch(
        setAdvanceAnalyticsDetail({
          locationPopChecked: null,
          locationPOPId: null,
        })
      );
    }
  };

  console.log("locationId", locationId);

  useEffect(() => {
    if (
      getUnitRedux &&
      materialIdRedux &&
      formatStartDate &&
      formatEndDate &&
      locationId
    ) {
      handleCreateMaterialCocChart();
    } else {
      setMaterialChartData([]);
      setTotalsum();
    }
  }, [
    getUnitRedux,
    materialIdRedux,
    toLocation,
    fromLocation,
    locationId,
    formatStartDate,
    formatEndDate,
    formatStartTime,
    formatEndTime,
    showTime,
  ]);

  useEffect(() => {
    if (
      materialIdPOPRedux &&
      formatPOPStartDate &&
      formatPOPEndDate &&
      locationPOPId
    ) {
      handleCreateMaterialPOPChart();
    } else {
      setMaterialPOPChartData([]);
      setPOPTotalSum();
    }
  }, [
    materialIdPOPRedux,
    popFromLocation,
    popToLocation,
    formatPOPStartDate,
    formatPOPEndDate,
    formatPOPStartTime,
    formatPOPEndTime,
    locationPOPId,
    POPshowTime,
  ]);

  useEffect(() => {
    if (
      materialIdPOPRedux &&
      formatPOP1StartDate &&
      formatPOP1EndDate &&
      locationPOPId
    ) {
      handleCreateMaterialPOP1Chart();
    } else {
      setMaterialPOP1ChartData([]);
      setPOP1TotalSum();
    }
  }, [
    materialIdPOPRedux,
    popFromLocation,
    popToLocation,
    formatPOP1StartDate,
    formatPOP1EndDate,
    formatPOP1StartTime,
    formatPOP1EndTime,
    locationPOPId,
    POPshowTime,
  ]);

  useEffect(() => {
    if (
      locationMaterialId &&
      (locationfromlocation || locationToLocation) &&
      lLocationId &&
      formatLocationStartDate &&
      formatLocationEndDate
    ) {
      handleCreateLocationCOCChart();
    } else {
      setLocationCOCData([]);
      setLocationUnits();
      setLocationTotalSum();
    }
  }, [
    locationMaterialId,
    locationfromlocation,
    locationToLocation,
    lLocationId,
    formatLocationStartDate,
    formatLocationEndDate,
    formatLocationStartTime,
    formatLocationEndTime,
  ]);

  const handleCreateMaterialCocChart = async () => {
    const params = {
      unit: getUnitRedux,
      material: materialIdRedux.map((id) => id.deviceID),
      from_location: fromLocation,
      to_location: toLocation,
      location: locationId,
      start_datetime: `${formatStartDate} 00:00:00`,
      end_datetime: `${formatEndDate} 23:59:59`,
    };
    if (showTime) {
      params["start_datetime"] = `${formatStartDate} ${formatStartTime}`;
      params["end_datetime"] = `${formatEndDate} ${formatEndTime}`;
    }

    try {
      const resp = await createMaterialCocChart(params);
      if (resp.status == 200 || resp.status == 201) {
        setMaterialChartData(resp.data.payload);
        setTotalsum(resp.data.sub_total);
        setCount((prev) => prev + 1);
      }
    } catch (error) {
      setMaterialChartData([]);
      console.log("handleSubmit", error);
    }
  };

  const handleCreateMaterialPOPChart = async () => {
    const params = {
      material: materialIdPOPRedux.map((id) => id.deviceID),
      from_location: popFromLocation,
      to_location: popToLocation,
      location: locationPOPId,
      start_datetime: `${formatPOPStartDate} 00:00:00`,
      end_datetime: `${formatPOPEndDate} 23:59:59`,
    };
    if (POPshowTime) {
      params["start_datetime"] = `${formatPOPStartDate} ${formatPOPStartTime}`;
      params["end_datetime"] = `${formatPOPEndDate} ${formatPOPEndTime}`;
    }
    try {
      const resp = await createMaterialPOPChart(params);
      if (resp.status == 200 || resp.status == 201) {
        setMaterialPOPChartData(resp.data.payload);
        setPOPTotalSum(resp.data.sub_total);
        setCount((prev) => prev + 1);
      }
    } catch (error) {
      setMaterialPOPChartData([]);
      console.log("handleSubmit", error);
    }
  };

  const handleCreateMaterialPOP1Chart = async () => {
    const params = {
      material: materialIdPOPRedux.map((id) => id.deviceID),
      from_location: popFromLocation,
      to_location: popToLocation,
      location: locationPOPId,
      start_datetime: `${formatPOP1StartDate} 00:00:00`,
      end_datetime: `${formatPOP1EndDate} 23:59:59`,
    };
    if (POPshowTime) {
      params[
        "start_datetime"
      ] = `${formatPOP1StartDate} ${formatPOP1StartTime}`;
      params["end_datetime"] = `${formatPOP1EndDate} ${formatPOP1EndTime}`;
    }
    try {
      const resp = await createMaterialPOPChart(params);
      if (resp.status == 200 || resp.status == 201) {
        setMaterialPOP1ChartData(resp.data.payload);
        setPOP1TotalSum(resp.data.sub_total);
      }
    } catch (error) {
      setMaterialPOP1ChartData([]);
      console.log("handleSubmit", error);
    }
  };

  const handleCreateLocationCOCChart = async () => {
    const params = {
      material: locationMaterialId,
      from_location: locationfromlocation,
      to_location: locationToLocation,
      location: lLocationId.map((id) => id.deviceID),
      start_datetime: `${formatLocationStartDate} 00:00:00`,
      end_datetime: `${formatLocationEndDate} 23:59:59`,
    };

    if (showLocationTime) {
      params[
        "start_datetime"
      ] = `${formatLocationStartDate} ${formatLocationStartTime}`;
      params[
        "end_datetime"
      ] = `${formatLocationEndDate} ${formatLocationEndTime}`;
    }

    try {
      const resp = await createLocationCOCChart(params);
      if (resp.status == 200 || resp.status == 201) {
        setLocationCOCData(resp.data.payload);
        setLocationUnits(resp.data.payload[0].unit);
        setLocationTotalSum(resp.data.sub_total);
        setCount((prev) => prev + 1);
      }
    } catch (error) {
      setLocationCOCData([]);
      console.log("handleSubmit", error);
    }
  };

  useEffect(() => {
    if (
      locationPOPMaterialId &&
      (locationPopFromLocation || locationPopToLocation) &&
      lLocationPOPId &&
      formatPOPLocationStartDate &&
      formatPOPLocationEndDate
    ) {
      handleCreateLocationPOPChart();
    } else {
      setLocationPOPData([]);
      setLocationPOPUnits();
      setLocationPOPTotalSum();
    }
  }, [
    locationPOPMaterialId,
    locationPopFromLocation,
    locationPopToLocation,
    lLocationPOPId,
    formatPOPLocationStartDate,
    formatPOPLocationEndDate,
    formatPOPLocationStartTime,
    formatPOPLocationEndTime,
    showLocationPopTime,
  ]);

  const handleCreateLocationPOPChart = async () => {
    const params = {
      material: locationPOPMaterialId,
      from_location: locationPopFromLocation,
      to_location: locationPopToLocation,
      location: [lLocationPOPId],
      start_datetime: `${formatPOPLocationStartDate} 00:00:00`,
      end_datetime: `${formatPOPLocationEndDate} 23:59:59`,
    };

    if (showLocationPopTime) {
      params[
        "start_datetime"
      ] = `${formatPOPLocationStartDate} ${formatPOPLocationStartTime}`;
      params[
        "end_datetime"
      ] = `${formatPOPLocationEndDate} ${formatPOPLocationEndTime}`;
    }

    try {
      const resp = await createLocationCOCChart(params);
      if (resp.status == 200 || resp.status == 201) {
        setLocationPOPData(resp.data.payload);
        setLocationPOPUnits(resp.data.payload[0].unit);
        setLocationPOPTotalSum(resp.data.sub_total);
        setCount((prev) => prev + 1);
      }
    } catch (error) {
      setLocationCOCData([]);
      console.log("handleSubmit", error);
    }
  };
  useEffect(() => {
    if (
      locationPOPMaterialId &&
      (locationPopFromLocation || locationPopToLocation) &&
      lLocationPOPId &&
      formatPOP1LocationStartDate &&
      formatPOP1LocationEndDate
    ) {
      handleCreateLocationPOP1Chart();
    } else {
      setLocationPOP1Data([]);
      setLocationPOP1TotalSum();
    }
  }, [
    locationPOPMaterialId,
    locationPopFromLocation,
    locationPopToLocation,
    lLocationPOPId,
    formatPOP1LocationStartDate,
    formatPOP1LocationEndDate,
    formatPOP1LocationStartTime,
    formatPOP1LocationEndTime,
    showLocationPopTime,
  ]);

  const handleCreateLocationPOP1Chart = async () => {
    const params = {
      material: locationPOPMaterialId,
      from_location: locationPopFromLocation,
      to_location: locationPopToLocation,
      location: [lLocationPOPId],
      start_datetime: `${formatPOP1LocationStartDate} 00:00:00`,
      end_datetime: `${formatPOP1LocationEndDate} 23:59:59`,
    };

    if (showLocationPopTime) {
      params[
        "start_datetime"
      ] = `${formatPOP1LocationStartDate} ${formatPOP1LocationStartTime}`;
      params[
        "end_datetime"
      ] = `${formatPOP1LocationEndDate} ${formatPOP1LocationEndTime}`;
    }

    try {
      const resp = await createLocationCOCChart(params);
      if (resp.status == 200 || resp.status == 201) {
        setLocationPOP1Data(resp.data.payload);
        setLocationPOP1TotalSum(resp.data.sub_total);
        setCount((prev) => prev + 1);
      }
    } catch (error) {
      setLocationCOCData([]);
      console.log("handleSubmit", error);
    }
  };

  useEffect(() => {
    if (!materialColors.length) {
      dispatch(
        setAdvanceAnalyticsDetail({
          fromLocation: false,
          locationChecked: null,
          locationId: null,
        })
      );
    }
  }, [materialColors]);
  const handleSelectDeviceId = (id, index, event, row, colorCode) => {
    console.log("colorCode", colorCode);
    const existingIndex = materialColors.findIndex(
      (color) => color.deviceId === row.id
    );

    if (existingIndex !== -1) {
      const updatedColors = [...materialColors];
      updatedColors.splice(existingIndex, 1);
      dispatch(
        setAdvanceAnalyticsDetail({
          materialColors: updatedColors,
        })
      );
    } else {
      // Add the object to the state array if it doesn't exist
      dispatch(
        setAdvanceAnalyticsDetail({
          materialColors: [
            ...materialColors,
            {
              deviceId: row.id,
              dvName: row.material_description,
              color: colorCode,
            },
          ],
        })
      );
    }

    if (!Array.isArray(materialIdRedux)) {
      dispatch(setAdvanceAnalyticsDetail({ materialIdRedux: [] }));
    }
    const matchingIndex = materialIdRedux.findIndex((f) => f.deviceID === id);

    if (matchingIndex !== -1) {
      // Matching element found, remove it
      console.log("Match found:", materialIdRedux[matchingIndex]);

      const updatedArray = [...materialIdRedux];
      updatedArray.splice(matchingIndex, 1); // Remove the matching element
      dispatch(
        setAdvanceAnalyticsDetail({
          materialIdRedux: updatedArray,
        })
      );
    } else {
      // No match found, add the new object
      console.log("No match found");

      dispatch(
        setAdvanceAnalyticsDetail({
          materialIdRedux: [...materialIdRedux, { deviceID: id }],
        })
      );
    }
  };

  useEffect(() => {
    if (!materialPOPColors.length) {
      dispatch(
        setAdvanceAnalyticsDetail({
          popFromLocation: false,
          popToLocation: false,
          locationPopChecked: null,
          locationPOPId: null,
        })
      );
    }
  }, [materialPOPColors]);

  const handlePOPSelectDeviceId = (id, index, event, row) => {
    const existingIndex = materialPOPColors.findIndex(
      (color) => color.deviceId === row.id
    );

    const existingIndexDevice = materialIdPOPRedux.findIndex(
      (color) => color.deviceID === row.id
    );

    // If a switch is currently on and the same switch is not being toggled, prevent toggling
    if (materialIdPOPRedux.length > 0 && existingIndexDevice === -1) {
      return;
    }

    if (existingIndex !== -1 && existingIndexDevice !== -1) {
      // If the selected device already exists, remove it (turn it off)
      const updatedColors = [...materialPOPColors];
      updatedColors.splice(existingIndex, 1);
      dispatch(
        setAdvanceAnalyticsDetail({
          materialPOPColors: updatedColors,
        })
      );

      // Unselect the device
      const updateDevice = [...materialIdPOPRedux];
      updateDevice.splice(existingIndexDevice, 1);
      dispatch(setAdvanceAnalyticsDetail({ materialIdPOPRedux: updateDevice }));
    } else {
      // Add the selected device to the state array
      dispatch(
        setAdvanceAnalyticsDetail({
          materialPOPColors: [
            {
              deviceId: row.id,
              dvName: row.material_description,
              color: switchColors[index % switchColors.length],
            },
          ],
        })
      );

      // Set the selected device as the only device in the array
      dispatch(
        setAdvanceAnalyticsDetail({
          materialIdPOPRedux: [{ deviceID: id }],
        })
      );
    }
  };

  const handleLocationSelectDeviceId = (index, row) => {
    const newUnitIndex = lLocationChecked === index ? null : index;
    dispatch(
      setAdvanceAnalyticsDetail({
        lLocationChecked: newUnitIndex,
        locationMaterialId: row.id,
      })
    );
    if (newUnitIndex == null) {
      dispatch(
        setAdvanceAnalyticsDetail({
          lLocationChecked: null,
          locationMaterialId: null,
        })
      );
    }
  };

  useEffect(() => {
    if (!locationColors.length) {
      dispatch(
        setAdvanceAnalyticsDetail({
          lLocationChecked: null,
          locationMaterialId: null,
        })
      );
    }
  }, [locationColors]);

  const handleLocationCOCLocationClick = (id, index, event, row) => {
    const existingIndex = locationColors.findIndex(
      (color) => color.deviceId === row.id
    );

    if (existingIndex !== -1) {
      const updatedColors = [...locationColors];
      updatedColors.splice(existingIndex, 1);
      dispatch(
        setAdvanceAnalyticsDetail({
          locationColors: updatedColors,
        })
      );
    } else {
      // Add the object to the state array if it doesn't exist
      dispatch(
        setAdvanceAnalyticsDetail({
          locationColors: [
            ...locationColors,
            {
              deviceId: row.id,
              dvName: row.location_name,
              color: switchColors[index % switchColors.length],
            },
          ],
        })
      );
    }

    if (!Array.isArray(lLocationId)) {
      dispatch(setAdvanceAnalyticsDetail({ lLocationId: [] }));
    }
    const matchingIndex = lLocationId?.findIndex((f) => f.deviceID === id);

    if (matchingIndex !== -1) {
      // Matching element found, remove it
      console.log("Match found:", lLocationId[matchingIndex]);

      const updatedArray = [...lLocationId];
      updatedArray.splice(matchingIndex, 1); // Remove the matching element
      dispatch(
        setAdvanceAnalyticsDetail({
          lLocationId: updatedArray,
        })
      );
    } else {
      // No match found, add the new object
      console.log("No match found");

      dispatch(
        setAdvanceAnalyticsDetail({
          lLocationId: [...lLocationId, { deviceID: id }],
        })
      );
    }
  };

  const handleLocationPOPSelectDeviceId = (index, e) => {
    const newUnitIndex = lMaterialPOPChecked === index ? null : index;
    dispatch(
      setAdvanceAnalyticsDetail({
        lMaterialPOPChecked: newUnitIndex,
        locationPOPMaterialId: e.id,
      })
    );
    if (newUnitIndex == null) {
      dispatch(
        setAdvanceAnalyticsDetail({
          lMaterialPOPChecked: null,
          locationPOPMaterialId: null,
        })
      );
    }
  };
  useEffect(() => {
    if (lLocationId && (locationfromlocation || locationToLocation)) {
      handleLocationMaterialCoc();
    }
  }, [lLocationId, locationfromlocation, locationToLocation]);

  const handleLocationMaterialCoc = async () => {
    const params = {
      location: lLocationId.map((id) => id.deviceID),
      from_location: locationfromlocation,
      to_location: locationToLocation,
    };
    try {
      const resp = await getMaterialInLocationGraph(params);
      if (resp.status == 200 || resp.status == 201) {
        setMaterialLocationData(resp.data.payload);
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  useEffect(() => {
    if (lLocationPOPId && (locationPopFromLocation || locationPopToLocation)) {
      handleLocationMaterialPOP();
    } else {
      setMaterialPOPLocationData([]);
    }
  }, [lLocationPOPId, locationPopFromLocation, locationPopToLocation]);

  const handleLocationMaterialPOP = async () => {
    const params = {
      location: [lLocationPOPId],
      from_location: locationPopFromLocation,
      to_location: locationPopToLocation,
    };
    try {
      const resp = await getMaterialInLocationGraph(params);
      if (resp.status == 200 || resp.status == 201) {
        setMaterialPOPLocationData(resp.data.payload);
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  return (
    <>
      <div className="container-wraper">
        <div className="ehs-form-sec">
          <div
            className="Ehs-form-title"
            onClick={() => navigate("/dashboard")}
          >
            <KeyboardBackspaceIcon />
            <h6 className="mt-1">Advance Analytics</h6>
          </div>
          <div>
            <button
              className={`${
                activeClass == "Material" ? "Material-tabs" : "Location-tabs"
              }`}
              onClick={() => setActiveClass("Material")}
            >
              Material
            </button>
            <button
              className={`${
                activeClass == "Location" ? "Material-tabs" : "Location-tabs"
              }`}
              onClick={() => setActiveClass("Location")}
            >
              Location
            </button>
          </div>
        </div>
        <div className="pt-3 ehs-form-sec">
          <div>
            <COCPOPTabs active={active} setActive={setActive} />
          </div>
          {activeClass == "Material" ? (
            <>
              {active == "Consumer" ? (
                <div className="d-flex gap-3">
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      value="top"
                      style={{ margin: 0 }}
                      control={
                        <Switch
                          checked={showTime}
                          height={20}
                          width={40}
                          onColor="#50C878"
                        />
                      }
                      label="Time"
                      labelPlacement="top"
                      onChange={handleToggleTime}
                    />
                  </FormGroup>
                  <COCDatePicker
                    time={showTime}
                    startDateReFormat={startDateReFormat}
                    endDateReFormat={endDateReFormat}
                    handleStartDate={handleStartDate}
                    handleEndDate={handleEndDate}
                  />
                </div>
              ) : (
                <div className="d-flex gap-2 justify-content-end analytics-datepicker">
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      value="top"
                      style={{ margin: 0 }}
                      control={
                        <Switch
                          checked={POPshowTime}
                          height={20}
                          width={40}
                          onColor="#50C878"
                        />
                      }
                      label="Time"
                      labelPlacement="top"
                      onChange={handlePOPToggleTime}
                    />
                  </FormGroup>
                  <POPDatePicker
                    time={POPshowTime}
                    startDateReFormat={startDatePOPReFormat}
                    endDateReFormat={endDatePOPReFormat}
                    startDate1ReFormat={startDatePOP1ReFormat}
                    endDate1ReFormat={endDatePOP1ReFormat}
                    handleStartDate={handlePOPStartDate}
                    handleEndDate={handlePOPEndDate}
                    handlePOP1StartDate={handlePOP1StartDate}
                    handlePOP1EndDate={handlePOP1EndDate}
                  />
                </div>
              )}
            </>
          ) : active == "Consumer" ? (
            <div className="d-flex gap-3">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value="top"
                  style={{ margin: 0 }}
                  control={
                    <Switch
                      checked={showLocationTime}
                      height={20}
                      width={40}
                      onColor="#50C878"
                    />
                  }
                  label="Time"
                  labelPlacement="top"
                  onChange={handleLocationToggleTime}
                />
              </FormGroup>
              <COCDatePicker
                time={showLocationTime}
                startDateReFormat={startDateLocationReFormat}
                endDateReFormat={endDateLocationReFormat}
                handleStartDate={handleLocationStartDate}
                handleEndDate={handleLocationEndDate}
              />
            </div>
          ) : (
            <div className="d-flex gap-2 justify-content-end analytics-datepicker">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value="top"
                  style={{ margin: 0 }}
                  control={
                    <Switch
                      checked={showLocationPopTime}
                      height={20}
                      width={40}
                      onColor="#50C878"
                    />
                  }
                  label="Time"
                  labelPlacement="top"
                  onChange={handleLocationPOPToggleTime}
                />
              </FormGroup>
              <POPDatePicker
                time={showLocationPopTime}
                startDateReFormat={startDatePOPLocationReFormat}
                endDateReFormat={endDatePOPLocationReFormat}
                startDate1ReFormat={startDatePOPLocation1ReFormat}
                endDate1ReFormat={endDatePOPLocation1ReFormat}
                handleStartDate={handleLocationPOPStartDate}
                handleEndDate={handleLocationPOPEndDate}
                handlePOP1StartDate={handleLocationPOP1StartDate}
                handlePOP1EndDate={handleLocationPOP1EndDate}
              />
            </div>
          )}
        </div>
        {activeClass == "Material" ? (
          <>
            <div className="pt-2">
              <div className="pt-2 consumption-grid-card">
                <Grid container spacing={2}>
                  {active == "Consumer" ? (
                    <>
                      <Grid item xs={12} sm={12} md={3}>
                        <Box className="consumerData-box">
                          <Box className="consumer-text-disc">
                            <h6 className="Title">Title</h6>
                            <h6 className="Data-chart-title">Data Charts</h6>
                          </Box>
                          <h6 className="mb-0 mt-3">
                            <b>Unit</b>
                          </h6>
                          <TextField
                            variant="outlined"
                            className="search-textfield"
                            placeholder="Search Units"
                            value={searchUnits}
                            onChange={handleSearchChange}
                            InputProps={{
                              startAdornment: <SearchIcon />,
                              inputProps: {
                                style: { padding: "9px" },
                              },
                            }}
                          />
                          <div className="furnace-grid">
                            <Box className="Grid-text mt-2">
                              <Grid item xs={12}>
                                {getUnit
                                  ?.filter((e) =>
                                    e
                                      ?.toLowerCase()
                                      ?.includes(searchUnits.toLowerCase())
                                  )
                                  ?.map((e, index) => {
                                    return (
                                      <Grid
                                        container
                                        alignItems="center"
                                        style={{
                                          background: "#efeffd",
                                          width: "100%",
                                          marginBottom: "7px",
                                        }}
                                        key={index}
                                      >
                                        <Grid
                                          item
                                          xs={8}
                                          style={{
                                            padding: "7px 12px",
                                            borderLeft: `4px solid ${
                                              switchColors[
                                                index % switchColors?.length
                                              ]
                                            }`,
                                          }}
                                        >
                                          <h6 className="mt-2">{e}</h6>
                                        </Grid>

                                        <Grid
                                          item
                                          xs={4}
                                          textAlign="right"
                                          className="grid-switch-unit"
                                        >
                                          <Switch
                                            style={{ float: "right" }}
                                            onClick={() =>
                                              handleSelectUnit(index)
                                            }
                                            name={switchColors[index]}
                                            checked={unitsChecked === index}
                                            disabled={
                                              unitsChecked !== null &&
                                              unitsChecked !== index
                                            }
                                            sx={{
                                              "& .MuiSwitch-thumb": {
                                                bgcolor: "#828282",
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#D9D9E5 !important",
                                              },
                                              "& .Mui-checked": {
                                                "& .MuiSwitch-thumb": {
                                                  bgcolor:
                                                    switchColors[
                                                      index %
                                                        switchColors.length
                                                    ],
                                                },
                                                "& .MuiSwitch-track": {
                                                  bgcolor: "#E2D9F2 !important",
                                                },
                                              },
                                            }}
                                          />
                                        </Grid>
                                      </Grid>
                                    );
                                  })}
                              </Grid>
                            </Box>
                          </div>

                          <h6 className="mb-0 mt-3">
                            <b>Material</b>
                          </h6>
                          <TextField
                            variant="outlined"
                            className="search-textfield"
                            placeholder="Search Materials"
                            value={searchMaterials}
                            onChange={handleSearchMaterialChange}
                            InputProps={{
                              startAdornment: <SearchIcon />,
                              inputProps: {
                                style: { padding: "9px" },
                              },
                            }}
                          />

                          <div className="device-unit">
                            <Box className="Grid-text1 mt-2">
                              <Grid item xs={12}>
                                {getMaterialData
                                  ?.filter((e) =>
                                    e.material_description
                                      ?.toLowerCase()
                                      ?.includes(searchMaterials.toLowerCase())
                                  )
                                  ?.map((e, index) => {
                                    return (
                                      <Grid
                                        container
                                        alignItems="center"
                                        style={{
                                          background: "#efeffd",
                                          width: "100%",
                                          marginBottom: "7px",
                                        }}
                                      >
                                        <Grid
                                          item
                                          xs={8}
                                          style={{
                                            padding: "7px 12px",
                                            borderLeft: `4px solid ${switchColors[index]}`,
                                          }}
                                        >
                                          <h6 className="mt-2">
                                            {e.material_description}
                                          </h6>
                                        </Grid>

                                        <Grid
                                          item
                                          xs={4}
                                          textAlign="right"
                                          className="grid-switch-unit"
                                        >
                                          <Switch
                                            onClick={(event) =>
                                              handleSelectDeviceId(
                                                e.id,
                                                index,
                                                event,
                                                e,
                                                switchColors[index]
                                              )
                                            }
                                            name={switchColors[index]}
                                            checked={
                                              materialIdRedux.find(
                                                (f) => f.deviceID == e.id
                                              )
                                                ? true
                                                : false
                                            }
                                            sx={{
                                              "& .MuiSwitch-thumb": {
                                                bgcolor: "#828282",
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#D9D9E5 !important",
                                              },
                                              "& .Mui-checked": {
                                                "& .MuiSwitch-thumb": {
                                                  bgcolor:
                                                    switchColors[
                                                      index %
                                                        switchColors.length
                                                    ],
                                                },
                                                "& .MuiSwitch-track": {
                                                  bgcolor: "#E2D9F2 !important",
                                                },
                                              },
                                            }}
                                          />
                                        </Grid>
                                      </Grid>
                                    );
                                  })}
                              </Grid>
                            </Box>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>
                              <h6 className="mb-0 mt-3">
                                <b>Location</b>
                              </h6>
                            </div>
                            <div className="d-flex gap-2">
                              <h6 className="mb-0 mt-3">
                                <b>From</b>
                              </h6>
                              <Switch
                                className="mt-2"
                                onClick={() =>
                                  handleFromLocation(!fromLocation)
                                }
                                checked={fromLocation}
                              />
                              <h6 className="mb-0 mt-3">
                                <b>To</b>
                              </h6>
                              <Switch
                                className="mt-2"
                                onClick={() => handleToLocation(!toLocation)}
                                checked={toLocation}
                              />
                            </div>
                          </div>

                          <TextField
                            variant="outlined"
                            className="search-textfield"
                            placeholder="Search Locations"
                            value={searchLocation}
                            onChange={(e) => setSearchLocation(e.target.value)}
                            InputProps={{
                              startAdornment: <SearchIcon />,
                              inputProps: {
                                style: { padding: "9px" },
                              },
                            }}
                          />

                          <div className="location-grid">
                            <Box className="Grid-text1 mt-2">
                              <Grid item xs={12}>
                                {getLocationData
                                  ?.filter((e) =>
                                    e.location_name
                                      ?.toLowerCase()
                                      ?.includes(searchLocation.toLowerCase())
                                  )
                                  ?.map((e, index) => {
                                    return (
                                      <Grid
                                        container
                                        alignItems="center"
                                        style={{
                                          background: "#efeffd",
                                          width: "100%",
                                          marginBottom: "7px",
                                        }}
                                      >
                                        <Grid
                                          item
                                          xs={8}
                                          style={{
                                            padding: "7px 12px",
                                            borderLeft: `4px solid ${
                                              switchColors[
                                                index % switchColors.length
                                              ]
                                            }`,
                                          }}
                                        >
                                          <h6 className="mt-2">
                                            {e.location_name}
                                          </h6>
                                        </Grid>

                                        <Grid
                                          item
                                          xs={4}
                                          textAlign="right"
                                          className="grid-switch-unit"
                                        >
                                          <Switch
                                            onClick={() =>
                                              handleLocationClick(index, e)
                                            }
                                            name={switchColors[index]}
                                            checked={locationChecked === index}
                                            disabled={
                                              locationChecked !== null &&
                                              locationChecked !== index
                                            }
                                            sx={{
                                              "& .MuiSwitch-thumb": {
                                                bgcolor: "#828282",
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#D9D9E5 !important",
                                              },
                                              "& .Mui-checked": {
                                                "& .MuiSwitch-thumb": {
                                                  bgcolor:
                                                    switchColors[
                                                      index %
                                                        switchColors.length
                                                    ],
                                                },
                                                "& .MuiSwitch-track": {
                                                  bgcolor: "#E2D9F2 !important",
                                                },
                                              },
                                            }}
                                          />
                                        </Grid>
                                      </Grid>
                                    );
                                  })}
                              </Grid>
                            </Box>
                          </div>

                          <button className="export-data-btn mt-3" onClick={handleMaterialCOCExcel}>
                            EXPORT DATA
                          </button>
                        </Box>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12} sm={12} md={3}>
                        <Box className="consumerData-box">
                          <Box className="consumer-text-disc">
                            <h6 className="Title">Title</h6>
                            <h6 className="Data-chart-title">Data Charts</h6>
                          </Box>

                          <h6 className="mb-0 mt-3">
                            <b>Material</b>
                          </h6>
                          <TextField
                            variant="outlined"
                            className="search-textfield"
                            placeholder="Search Materials"
                            value={searchPOPMaterials}
                            onChange={(e) =>
                              setSearchPOPMaterials(e.target.value)
                            }
                            InputProps={{
                              startAdornment: <SearchIcon />,
                              inputProps: {
                                style: { padding: "9px" },
                              },
                            }}
                          />

                          <div className="material-grid-pop">
                            <Box className="Grid-text1 mt-2">
                              <Grid item xs={12}>
                                {getPOPMaterialData
                                  ?.filter((e) =>
                                    e.material_description
                                      ?.toLowerCase()
                                      ?.includes(
                                        searchPOPMaterials.toLowerCase()
                                      )
                                  )
                                  ?.map((e, index) => {
                                    return (
                                      <Grid
                                        container
                                        alignItems="center"
                                        style={{
                                          background: "#efeffd",
                                          width: "100%",
                                          marginBottom: "7px",
                                        }}
                                        key={e.id}
                                      >
                                        <Grid
                                          item
                                          xs={8}
                                          style={{
                                            padding: "7px 12px",
                                            borderLeft: `4px solid ${
                                              switchColors[
                                                index % switchColors.length
                                              ]
                                            }`,
                                          }}
                                        >
                                          <h6 className="mt-2">
                                            {e.material_description}
                                          </h6>
                                        </Grid>

                                        <Grid
                                          item
                                          xs={4}
                                          textAlign="right"
                                          className="grid-switch-unit"
                                        >
                                          <Switch
                                            style={{ float: "right" }}
                                            onClick={(event) =>
                                              handlePOPSelectDeviceId(
                                                e.id,
                                                index,
                                                event,
                                                e
                                              )
                                            }
                                            name={switchColors[index]}
                                            checked={
                                              materialIdPOPRedux?.length
                                                ? materialIdPOPRedux.find(
                                                    (f) => f.deviceID === e.id
                                                  )
                                                  ? true
                                                  : false
                                                : false
                                            }
                                            sx={{
                                              "& .MuiSwitch-thumb": {
                                                bgcolor: "#828282",
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#D9D9E5 !important",
                                              },
                                              "& .Mui-checked": {
                                                "& .MuiSwitch-thumb": {
                                                  bgcolor:
                                                    switchColors[
                                                      index %
                                                        switchColors.length
                                                    ],
                                                },
                                                "& .MuiSwitch-track": {
                                                  bgcolor: "#E2D9F2 !important",
                                                },
                                              },
                                            }}
                                          />
                                        </Grid>
                                      </Grid>
                                    );
                                  })}
                              </Grid>
                            </Box>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>
                              <h6 className="mb-0 mt-3">
                                <b>Location</b>
                              </h6>
                            </div>
                            <div className="d-flex gap-2">
                              <h6 className="mb-0 mt-3">
                                <b>From</b>
                              </h6>
                              <Switch
                                className="mt-2"
                                onClick={() =>
                                  handlePOPFromLocation(!popFromLocation)
                                }
                                checked={popFromLocation}
                              />
                              <h6 className="mb-0 mt-3">
                                <b>To</b>
                              </h6>
                              <Switch
                                className="mt-2"
                                onClick={() =>
                                  handlePOPToLocation(!popToLocation)
                                }
                                checked={popToLocation}
                              />
                            </div>
                          </div>

                          <TextField
                            variant="outlined"
                            className="search-textfield"
                            placeholder="Search Locations"
                            value={searchPOPLocations}
                            onChange={(e) =>
                              setSearchPOPLocations(e.target.value)
                            }
                            InputProps={{
                              startAdornment: <SearchIcon />,
                              inputProps: {
                                style: { padding: "9px" },
                              },
                            }}
                          />

                          <div className="material-grid-pop">
                            <Box className="Grid-text1 mt-2">
                              <Grid item xs={12}>
                                {getPOPLocationData
                                  ?.filter((e) =>
                                    e.location_name
                                      ?.toLowerCase()
                                      ?.includes(
                                        searchPOPLocations.toLowerCase()
                                      )
                                  )
                                  ?.map((e, index) => {
                                    return (
                                      <Grid
                                        container
                                        alignItems="center"
                                        style={{
                                          background: "#efeffd",
                                          width: "100%",
                                          marginBottom: "7px",
                                        }}
                                      >
                                        <Grid
                                          item
                                          xs={8}
                                          style={{
                                            padding: "7px 12px",
                                            borderLeft: `4px solid ${
                                              switchColors[
                                                index % switchColors.length
                                              ]
                                            }`,
                                          }}
                                        >
                                          <h6 className="mt-2">
                                            {e.location_name}
                                          </h6>
                                        </Grid>

                                        <Grid
                                          item
                                          xs={4}
                                          textAlign="right"
                                          className="grid-switch-unit"
                                        >
                                          <Switch
                                            onClick={() =>
                                              handlePOPLocationClick(index, e)
                                            }
                                            name={switchColors[index]}
                                            checked={
                                              locationPopChecked === index
                                            }
                                            disabled={
                                              locationPopChecked !== null &&
                                              locationPopChecked !== index
                                            }
                                            sx={{
                                              "& .MuiSwitch-thumb": {
                                                bgcolor: "#828282",
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#D9D9E5 !important",
                                              },
                                              "& .Mui-checked": {
                                                "& .MuiSwitch-thumb": {
                                                  bgcolor:
                                                    switchColors[
                                                      index %
                                                        switchColors.length
                                                    ],
                                                },
                                                "& .MuiSwitch-track": {
                                                  bgcolor: "#E2D9F2 !important",
                                                },
                                              },
                                            }}
                                          />
                                        </Grid>
                                      </Grid>
                                    );
                                  })}
                              </Grid>
                            </Box>
                          </div>

                          <button className="export-data-btn mt-3" onClick={handleMaterialPOPExcel}>
                            EXPORT DATA
                          </button>
                        </Box>
                      </Grid>
                    </>
                  )}

                  {active == "Consumer" ? (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={9}
                      className="consumption-table-box"
                    >
                      <Box className="consumerData-box">
                        <div style={{ minHeight: "322px" }}>
                          <div className="d-flex justify-content-between">
                            <h6>
                              <b>Pareto Chart - Consumer over Consumer</b>
                            </h6>
                          </div>
                          <div className="spinner-container" id={count}>
                            <MaterialCOCChart
                              count={count}
                              analyticsData={materialCocChartData}
                              materialColor={
                                materialCocChartData.length &&
                                materialColors.length
                                  ? materialCocChartData.map(
                                      (row) =>
                                        materialColors.find(
                                          (f) => f.deviceId == row.material.id
                                        )?.["color"]
                                    )
                                  : []
                              }
                            />
                          </div>
                        </div>
                        <div className="mt-2">
                          <h6>
                            <b>Pie Chart - Consumer over Consumer</b>
                          </h6>
                        </div>
                        <div className="spinner-container" id={count}>
                          <MaterialCOCPiechart
                            analyticsPieChartData={materialCocChartData}
                            materialColor={
                              materialCocChartData.length &&
                              materialColors.length
                                ? materialCocChartData.map(
                                    (row) =>
                                      materialColors.find(
                                        (f) => f.deviceId == row.material.id
                                      )?.["color"]
                                  )
                                : []
                            }
                            getUnitRedux={getUnitRedux}
                            totalsum={totalsum}
                          />
                        </div>
                      </Box>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={9}
                      className="consumption-table-box"
                    >
                      <Box className="consumerData-box">
                        <div style={{ minHeight: "356px" }}>
                          <div className="d-flex justify-content-between">
                            <h6>
                              <b>Bar Chart - Period over Period</b>
                            </h6>
                          </div>
                          <div>
                            <MaterialPOPGroupChart
                              count={count}
                              analyticsPOPData={materialPOPChartData}
                              analyticsPOP1Data={materialPOP1ChartData}
                            />
                          </div>
                        </div>
                        <div>
                          <h6>Pie Chart - Period over Period</h6>
                        </div>
                        <div>
                          <MaterialPOPPiechart
                            analyticsPOPData={materialPOPChartData}
                            analyticsPOP1Data={materialPOP1ChartData}
                            popTotalSum={popTotalSum}
                            pop1TotalSum={pop1TotalSum}
                          />
                        </div>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </div>
            </div>
          </>
        ) : (
          <div className="pt-2">
            <div className="pt-2 consumption-grid-card">
              <Grid container spacing={2}>
                {active == "Consumer" ? (
                  <>
                    <Grid item xs={12} sm={12} md={3}>
                      <Box className="consumerData-box">
                        <Box className="consumer-text-disc">
                          <h6 className="Title">Title</h6>
                          <h6 className="Data-chart-title">Data Charts</h6>
                        </Box>
                        <div className="d-flex justify-content-between">
                          <div>
                            <h6 className="mb-0 mt-3">
                              <b>Location</b>
                            </h6>
                          </div>
                          <div className="d-flex gap-2">
                            <h6 className="mb-0 mt-3">
                              <b>From</b>
                            </h6>
                            <Switch
                              className="mt-2"
                              onClick={() =>
                                handleLocationFromLocation(
                                  !locationfromlocation
                                )
                              }
                              checked={locationfromlocation}
                            />
                            <h6 className="mb-0 mt-3">
                              <b>To</b>
                            </h6>
                            <Switch
                              className="mt-2"
                              onClick={() =>
                                handleLocationToLocation(!locationToLocation)
                              }
                              checked={locationToLocation}
                            />
                          </div>
                        </div>

                        <TextField
                          variant="outlined"
                          className="search-textfield"
                          placeholder="Search Locations"
                          value={searchLocationFilter}
                          onChange={(e) =>
                            setSearchLocationFilter(e.target.value)
                          }
                          InputProps={{
                            startAdornment: <SearchIcon />,
                            inputProps: {
                              style: { padding: "9px" },
                            },
                          }}
                        />

                        <div className="material-grid-pop">
                          <Box className="Grid-text1 mt-2">
                            <Grid item xs={12}>
                              {locationCOClocationData
                                ?.filter((e) =>
                                  e.location_name
                                    ?.toLowerCase()
                                    ?.includes(
                                      searchLocationFilter.toLowerCase()
                                    )
                                )
                                ?.map((e, index) => {
                                  return (
                                    <Grid
                                      container
                                      alignItems="center"
                                      style={{
                                        background: "#efeffd",
                                        width: "100%",
                                        marginBottom: "7px",
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={8}
                                        style={{
                                          padding: "7px 12px",
                                          borderLeft: `4px solid ${
                                            switchColors[
                                              index % switchColors.length
                                            ]
                                          }`,
                                        }}
                                      >
                                        <h6 className="mt-2">
                                          {e.location_name}
                                        </h6>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={4}
                                        textAlign="right"
                                        className="grid-switch-unit"
                                      >
                                        <Switch
                                          style={{ float: "right" }}
                                          onClick={(event) =>
                                            handleLocationCOCLocationClick(
                                              e.id,
                                              index,
                                              event,
                                              e
                                            )
                                          }
                                          name={switchColors[index]}
                                          checked={
                                            lLocationId?.length
                                              ? lLocationId.find(
                                                  (f) => f.deviceID === e.id
                                                )
                                                ? true
                                                : false
                                              : false
                                          }
                                          sx={{
                                            "& .MuiSwitch-thumb": {
                                              bgcolor: "#828282",
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#D9D9E5 !important",
                                            },
                                            "& .Mui-checked": {
                                              "& .MuiSwitch-thumb": {
                                                bgcolor:
                                                  switchColors[
                                                    index % switchColors.length
                                                  ],
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#E2D9F2 !important",
                                              },
                                            },
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  );
                                })}
                            </Grid>
                          </Box>
                        </div>

                        <h6 className="mb-0 mt-3">
                          <b>Material</b>
                        </h6>
                        <TextField
                          variant="outlined"
                          className="search-textfield"
                          placeholder="Search Materials"
                          value={searchLocationMaterials}
                          onChange={(e) =>
                            setSearchLocationMaterials(e.target.value)
                          }
                          InputProps={{
                            startAdornment: <SearchIcon />,
                            inputProps: {
                              style: { padding: "9px" },
                            },
                          }}
                        />

                        <div className="material-grid-pop">
                          <Box className="Grid-text1 mt-2">
                            <Grid item xs={12}>
                              {materialLocationData
                                ?.filter((e) =>
                                  e.material_description
                                    ?.toLowerCase()
                                    ?.includes(
                                      searchLocationMaterials.toLowerCase()
                                    )
                                )
                                ?.map((e, index) => {
                                  return (
                                    <Grid
                                      container
                                      alignItems="center"
                                      style={{
                                        background: "#efeffd",
                                        width: "100%",
                                        marginBottom: "7px",
                                      }}
                                      key={e.id}
                                    >
                                      <Grid
                                        item
                                        xs={8}
                                        style={{
                                          padding: "7px 12px",
                                          borderLeft: `4px solid ${
                                            switchColors[
                                              index % switchColors.length
                                            ]
                                          }`,
                                        }}
                                      >
                                        <h6 className="mt-2">
                                          {e.material_description}
                                        </h6>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={4}
                                        textAlign="right"
                                        className="grid-switch-unit"
                                      >
                                        <Switch
                                          style={{ float: "right" }}
                                          // onClick={(event) =>
                                          //   handleLocationSelectDeviceId(
                                          //     e.id,
                                          //     index,
                                          //     event,
                                          //     e
                                          //   )
                                          // }
                                          // name={switchColors[index]}
                                          // checked={
                                          //   locationMaterialId?.length
                                          //     ? locationMaterialId.find(
                                          //         (f) => f.deviceID === e.id
                                          //       )
                                          //       ? true
                                          //       : false
                                          //     : false
                                          // }
                                          onClick={() =>
                                            handleLocationSelectDeviceId(
                                              index,
                                              e
                                            )
                                          }
                                          name={switchColors[index]}
                                          checked={lLocationChecked === index}
                                          disabled={
                                            lLocationChecked !== null &&
                                            lLocationChecked !== index
                                          }
                                          sx={{
                                            "& .MuiSwitch-thumb": {
                                              bgcolor: "#828282",
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#D9D9E5 !important",
                                            },
                                            "& .Mui-checked": {
                                              "& .MuiSwitch-thumb": {
                                                bgcolor:
                                                  switchColors[
                                                    index % switchColors.length
                                                  ],
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#E2D9F2 !important",
                                              },
                                            },
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  );
                                })}
                            </Grid>
                          </Box>
                        </div>

                        <button
                          className="export-data-btn mt-3"
                          onClick={handleLocationCOCExcel}
                        >
                          EXPORT DATA
                        </button>
                      </Box>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12} sm={12} md={3}>
                      <Box className="consumerData-box">
                        <Box className="consumer-text-disc">
                          <h6 className="Title">Title</h6>
                          <h6 className="Data-chart-title">Data Charts</h6>
                        </Box>
                        <div className="d-flex justify-content-between">
                          <div>
                            <h6 className="mb-0 mt-3">
                              <b>Location</b>
                            </h6>
                          </div>
                          <div className="d-flex gap-2">
                            <h6 className="mb-0 mt-3">
                              <b>From</b>
                            </h6>
                            <Switch
                              className="mt-2"
                              onClick={() =>
                                handleLocationPOPFromLocation(
                                  !locationPopFromLocation
                                )
                              }
                              checked={locationPopFromLocation}
                            />
                            <h6 className="mb-0 mt-3">
                              <b>To</b>
                            </h6>
                            <Switch
                              className="mt-2"
                              onClick={() =>
                                handleLocationPOPToLocation(
                                  !locationPopToLocation
                                )
                              }
                              checked={locationPopToLocation}
                            />
                          </div>
                        </div>

                        <TextField
                          variant="outlined"
                          className="search-textfield"
                          placeholder="Search Locations"
                          value={searchLocationPOPfilter}
                          onChange={(e) =>
                            setSearchLocationPOPfilter(e.target.value)
                          }
                          InputProps={{
                            startAdornment: <SearchIcon />,
                            inputProps: {
                              style: { padding: "9px" },
                            },
                          }}
                        />

                        <div className="material-grid-pop">
                          <Box className="Grid-text1 mt-2">
                            <Grid item xs={12}>
                              {locationPOPlocationData
                                ?.filter((e) =>
                                  e.location_name
                                    ?.toLowerCase()
                                    ?.includes(
                                      searchLocationPOPfilter.toLowerCase()
                                    )
                                )
                                ?.map((e, index) => {
                                  return (
                                    <Grid
                                      container
                                      alignItems="center"
                                      style={{
                                        background: "#efeffd",
                                        width: "100%",
                                        marginBottom: "7px",
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={8}
                                        style={{
                                          padding: "7px 12px",
                                          borderLeft: `4px solid ${
                                            switchColors[
                                              index % switchColors.length
                                            ]
                                          }`,
                                        }}
                                      >
                                        <h6 className="mt-2">
                                          {e.location_name}
                                        </h6>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={4}
                                        textAlign="right"
                                        className="grid-switch-unit"
                                      >
                                        <Switch
                                          onClick={() =>
                                            handleLocationPOPLocationClick(
                                              index,
                                              e
                                            )
                                          }
                                          name={switchColors[index]}
                                          checked={
                                            lLocationPOPChecked === index
                                          }
                                          disabled={
                                            lLocationPOPChecked !== null &&
                                            lLocationPOPChecked !== index
                                          }
                                          sx={{
                                            "& .MuiSwitch-thumb": {
                                              bgcolor: "#828282",
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#D9D9E5 !important",
                                            },
                                            "& .Mui-checked": {
                                              "& .MuiSwitch-thumb": {
                                                bgcolor:
                                                  switchColors[
                                                    index % switchColors.length
                                                  ],
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#E2D9F2 !important",
                                              },
                                            },
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  );
                                })}
                            </Grid>
                          </Box>
                        </div>

                        <h6 className="mb-0 mt-3">
                          <b>Material</b>
                        </h6>
                        <TextField
                          variant="outlined"
                          className="search-textfield"
                          placeholder="Search Materials"
                          InputProps={{
                            startAdornment: <SearchIcon />,
                            inputProps: {
                              style: { padding: "9px" },
                            },
                          }}
                          value={searchLocationPOPMaterial}
                          onChange={(e) =>
                            setSearchLocationPOPMaterial(e.target.value)
                          }
                        />

                        <div className="material-grid-pop">
                          <Box className="Grid-text1 mt-2">
                            <Grid item xs={12}>
                              {materialPOPLocationData
                                ?.filter((e) =>
                                  e.material_description
                                    ?.toLowerCase()
                                    ?.includes(
                                      searchLocationPOPMaterial.toLowerCase()
                                    )
                                )
                                ?.map((e, index) => {
                                  return (
                                    <Grid
                                      container
                                      alignItems="center"
                                      style={{
                                        background: "#efeffd",
                                        width: "100%",
                                        marginBottom: "7px",
                                      }}
                                      key={e.id}
                                    >
                                      <Grid
                                        item
                                        xs={8}
                                        style={{
                                          padding: "7px 12px",
                                          borderLeft: `4px solid ${
                                            switchColors[
                                              index % switchColors.length
                                            ]
                                          }`,
                                        }}
                                      >
                                        <h6 className="mt-2">
                                          {e.material_description}
                                        </h6>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={4}
                                        textAlign="right"
                                        className="grid-switch-unit"
                                      >
                                        <Switch
                                          style={{ float: "right" }}
                                          onClick={() =>
                                            handleLocationPOPSelectDeviceId(
                                              index,
                                              e
                                            )
                                          }
                                          name={switchColors[index]}
                                          checked={
                                            lMaterialPOPChecked === index
                                          }
                                          disabled={
                                            lMaterialPOPChecked !== null &&
                                            lMaterialPOPChecked !== index
                                          }
                                          sx={{
                                            "& .MuiSwitch-thumb": {
                                              bgcolor: "#828282",
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#D9D9E5 !important",
                                            },
                                            "& .Mui-checked": {
                                              "& .MuiSwitch-thumb": {
                                                bgcolor:
                                                  switchColors[
                                                    index % switchColors.length
                                                  ],
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#E2D9F2 !important",
                                              },
                                            },
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  );
                                })}
                            </Grid>
                          </Box>
                        </div>

                        <button
                          className="export-data-btn mt-3"
                          onClick={handleLocationPOPExcel}
                        >
                          EXPORT DATA
                        </button>
                      </Box>
                    </Grid>
                  </>
                )}

                {active == "Consumer" ? (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={9}
                    className="consumption-table-box"
                  >
                    <Box className="consumerData-box">
                      <div style={{ minHeight: "322px" }}>
                        <div className="d-flex justify-content-between">
                          <h6>
                            <b>Pareto Chart - Consumer over Consumer</b>
                          </h6>
                        </div>
                        <div id={count}>
                          <MaterialCOCChart
                            analyticsData={locationCOCData}
                            materialColor={
                              locationCOCData.length && locationColors.length
                                ? locationCOCData.map(
                                    (row) =>
                                      locationColors.find(
                                        (f) => f.deviceId == row.location.id
                                      )?.["color"]
                                  )
                                : []
                            }
                          />
                        </div>
                      </div>
                      <div className="mt-2">
                        <h6>
                          <b>Pie Chart - Consumer over Consumer</b>
                        </h6>
                        <div id={count}>
                          <MaterialCOCPiechart
                            analyticsPieChartData={locationCOCData}
                            materialColor={
                              locationCOCData.length && locationColors.length
                                ? locationCOCData.map(
                                    (row) =>
                                      locationColors.find(
                                        (f) => f.deviceId == row.location.id
                                      )?.["color"]
                                  )
                                : []
                            }
                            getUnitRedux={locationUnit}
                            totalsum={locationTotalSum}
                          />
                        </div>
                      </div>
                    </Box>
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={9}
                    className="consumption-table-box"
                  >
                    <Box className="consumerData-box">
                      <div style={{ minHeight: "356px" }}>
                        <div className="d-flex justify-content-between">
                          <h6>
                            <b>Bar Chart - Period over Period</b>
                          </h6>
                        </div>
                        <div>
                          <MaterialPOPGroupChart
                            count={count}
                            analyticsPOPData={locationPOPData}
                            analyticsPOP1Data={locationPOP1Data}
                          />
                        </div>
                      </div>
                      <div>
                        <h6>Pie Chart - Period over Period</h6>
                      </div>
                      <div>
                        <MaterialPOPPiechart
                          count={count}
                          analyticsPOPData={locationPOPData}
                          analyticsPOP1Data={locationPOP1Data}
                          popTotalSum={locationPOPTotalSum}
                          pop1TotalSum={locationPOP1TotalSum}
                        />
                      </div>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default AdvanceAnalytics;
