
import { useSelector } from "react-redux";
import "./App.css";
import AuthenticationRoutes from "./routes/AuthenticatationRoute";
import UnAuthenticationRoute from "./routes/UnAuthenticationRoute";
import { ToastContainer } from "react-toastify";


function App() {

  const userState = useSelector(state => state.userState);
  // if (true) {
  //   console.log = function no_console() { };
  // }
  return (
    <>
      {userState?.token ? <AuthenticationRoutes /> : <UnAuthenticationRoute />}
      <ToastContainer />
    </>
  );
}

export default App;
