import React from 'react';
import './TextShimmer.css';

const Textshimmer = ({ height = '1rem', width = '100%' ,itemCount}) => {
  // Apply the dynamic height to the style
  const style = { height, width };
  const ItemCount = itemCount ? itemCount : 1

  return (
    Array.from({length:ItemCount}).map((_, index)=>(<div key={index} className="text-shimmer" style={style}></div>))
    
  );
};

export default Textshimmer;
