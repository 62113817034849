import React, { useState, useRef, useEffect, useMemo } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import Loader from "../../../components/Loader/Loader";
import { InputField } from "../../../components/InputField/InputField";
import Button from "../../../components/Button/Button";
import "./SignIn.css";
import { signInApi } from "../services";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserDetails } from "../../../redux/Slice/userSlice";
import { pbkdf2 } from 'pbkdf2';

function SignIn({ onNextClick, setMobileNo, setMfaUserId }) {
  const recaptchaRef = useRef(null);

  // Initialize state variables
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [SignIncount, setSignIncount] = useState(0);
  const [Sitekey, setSitekey] = useState(
    "6LcvUFomAAAAAEjzQKoUaiz7gjxr6CVGjjxTUDMy"
  );
  const [isRecaptchaValid, setRecaptchaValid] = useState(false);
  const [isKeepmelogin, setisKeepmelogin] = useState(true);



  const navigate = useNavigate();
  const dispatch = useDispatch()

  useEffect(() => {
    localStorage.setItem("Keepmelogin", isKeepmelogin);
  }, [isKeepmelogin]);

  const notify = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION?.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  // const Tosterhandler = (message) => {
  //   toast.error(message, {
  //     position: "top-right",
  //     autoClose: 5000,
  //     hideProgressBar: false,
  //     closeOnClick: false,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //   });
  // };

  const handlekeepMeLogin = () => {
    setisKeepmelogin(!isKeepmelogin);
  };

  const handleSubmit = async () => {

    function encryptPassword(password, salt, iterations) {
      return new Promise((resolve, reject) => {
        pbkdf2(password, salt, iterations, 32, 'sha256', (err, derivedKey) => {
          if (err) {
            reject(err);
          } else {
            const hash = derivedKey.toString('base64');
            const formattedHash = `pbkdf2_sha256$${iterations}$${salt}$${hash}`;
            resolve(formattedHash);
          }
        });
      });
    }
    // // Example usage
    const salt = "wUSaq3zAOY9ddYZNFDYqIr";
    const iterations = 320000;

    const encryptedPassword = await encryptPassword(password, salt, iterations);
    console.log("encryptedPasswordencryptedPassword", encryptedPassword);
    

    const params = {
      email: email,
      password: encryptedPassword,
    };
    try {
      const response = await signInApi(params);
      setLoading(false);
      if (
        response.data.message ==
        "Your 2-Step verification in enable, Please verify for login"
      ) {
        setMobileNo(response.data.payload.mobile_number);
        onNextClick("verify2Step");
        return true;
      }

      if (
        response.data.message ==
        "Your MFA verification in enable, Please verify for login"
      ) {
        setEmail(response.data.payload.email);
        setMfaUserId(response.data.payload.mfa_user_id);
        onNextClick("verifyMFA");
        return true;
      }

      if (response.data.message == "Your account has been deleted.") {
        notify(response.data.message);
        return true;
      }

      if (response.data.message == "You login successfully") {

        dispatch(
          setUserDetails({
            firstName: response.data.payload.first_name,
            lastName: response.data.payload.last_name,
            email: response.data.payload.email,
            mobileNoRe: response.data.payload.mobile_number,
            token: response.data.payload.token,
            id: response.data.payload.id,
            MFA_UserId: response.data.payload.mfa_user_id,
            company_logo: response.data.payload.company_logo,
            companyId: response.data.payload.company,
            is_super_admin: response.data.payload.is_super_admin,
            is_admin: response.data.payload.is_admin,
            companyName: response.data.payload.company_name,
            companyDescription: response.data.payload.company_description,
            user_access:
              response.data.payload.user_access?.access_module_management || [],
            user_country: response.data.payload.user_country,
            user_country_name: response.data.payload.user_country_name,
            user_country_code_name:
              response.data.payload.user_country_code_name,
            user_country_code: response.data.payload.user_country_code,
            user_country_currency: response.data.payload.user_country_currency,
            user_country_currency_name:
              response.data.payload.user_country_currency_name,
            user_country_currency_symbol:
              response.data.payload.user_country_currency_symbol,
            is_created_by_super_admin_user_company:
              response.data.payload.is_created_by_super_admin_user_company,
          })
        );
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      if (error.response.data.message) {
        notify(error.response.data.message);
      } else {
        notify("Please Enter Valid Email Id.");
      }
    }
  };

  const SignInHandle = () => {
    // handleSubmit();
    setSignIncount(SignIncount + 1);
    setLoading(true);

    setTimeout(async () => {
      if (email) {
        if (password) {
          if (recaptchaRef.current.getValue()) {
            handleSubmit();
          } else {
            setLoading(false);
            notify("Recapcha is required");
          }
        } else {
          setLoading(false);
          notify("Password is required");
        }
      } else {
        setLoading(false);
        notify("Email is required");
      }
    }, 2);
  };

  const setloadinghandle = async () => {
    if (password && email) {
      setLoading(true);
    }
  };

  const Recaptchahandle = () => {
    if (recaptchaRef.current.getValue()) {
      setRecaptchaValid(true);
    }
  };

  const handleEmailChange = (event) => {
    const inputValue = event.target.value;
    setEmail(inputValue);
  };

  const emailRegex = /^[\w\.-]+@[\w\.-]+\.\w+$/;

  const isEmailValid = (value) => {
    const valid = emailRegex.test(value);
    return valid ? null : "isEmail";
  };

  return (
    <div>
      
      <h3 className="form-login-title">Welcome</h3>
      <ValidatorForm>
        {
          // Render the email input field
          <InputField
            id="standard-basic"
            label="Email"
            onChange={handleEmailChange}
            name="email"
            value={email}
            // validators={["required", isEmailValid()]}
            // errorMessages={["Email is required", "Email is not valid"]}
            variant="standard"
          />
        }
        {/* { !isEmailValid && (
        <span style={{ color: "red" ,fontSize:"14px"}}>Invalid email</span>
      )} */}
        <InputField
          type="password"
          id="standard-basic"
          label="Password"
          onChange={(event) => setPassword(event.target.value)}
          name="password"
          value={password}
          // validators={["required"]}
          // errorMessages={["password is required"]}
          variant="standard"
        />
        <div
          className={`form-footer ${SignIncount > 2 ? "custom-margin" : ""}`}
        >
          <div className="keep-login-wrap">
            <input
              type="checkbox"
              id="keepMeLogin"
              name="keepMeLogin"
              value={isKeepmelogin}
              checked={isKeepmelogin}
              onChange={handlekeepMeLogin}
            />
            <label
              for="keepMeLogin"
              className={`keep-login-label ${
                SignIncount > 2 ? "custom-margin" : ""
              }`}
            >
              {" "}
              Keep me login
            </label>
          </div>
          <div className="forgot-link" style={{ cursor: "pointer" }} onClick={() => onNextClick("forgotPassword")}>
            Forgot Password?
          </div>
        </div>

        {SignIncount >= 0 ? (
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={Sitekey}
            required
            className="ReCAPTCHA-R"
            onChange={Recaptchahandle}
          />
        ) : null}

        <Button
          type="submit"
          title="Login"
          className="common-button"
          onClick={async () => {
            // setloadinghandle();
            SignInHandle();
          }}
        />

        {isLoading && <Loader />}
        {/* <Loader /> */}
      </ValidatorForm>
    </div>
  );
}

export default SignIn;
