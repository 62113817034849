import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Typography from "@mui/material/Typography";
import PersonalTab from "./PersonalTab/PersonalTab";
import SecurityTab from "./SecurityTab/SecurityTab";
import CommunicationTab from "./CommunicationTab/CommunicationTab";
import { getUserProfileApi, getCountry, getCoutryListData } from "./service";
import { images } from "../../config/images";
import "./UserProfile.css";
import { useSelector, useDispatch } from "react-redux";
import { setUserDetails } from "../../redux/Slice/userSlice";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UserProfile = () => {
    const [value, setValue] = React.useState("1");
    const [userProfile, setUserProfile] = useState("");
    const [mobielNo, setMobileNo] = useState("");
    const [mobileCountryCode, setMobileCountryCode] = useState("");
    const [allModelHandle, setAllModelHandle] = useState(false)


    const userState = useSelector((state) => state.userState);
    const dispatch = useDispatch();
    console.log("userProfile", userProfile);

    const notifyError = (message) => toast.error(message, {
        theme: 'colored',
        position: toast.POSITION.TOP_RIGHT,
        toastClassName: 'custom-toast',
    });

    const handleChange = (event, newValue) => {
        if (!userProfile?.mobile_number) {
            notifyError("Please Verify Mobile No");
            return true;
        }

        if(!allModelHandle){
            setValue(newValue);
        }

    };



    const getUserProfile = async () => {
        const resp = await getUserProfileApi();
        if (resp.data.success == true) {
            console.log("userprofile", resp.data);
            setUserProfile(resp.data.payload);
            setMobileNo(resp.data.payload.mobile_number % 10000000000 ? resp.data.payload.mobile_number % 10000000000 : '')
            setMobileCountryCode(resp.data.payload.user_country ? resp.data.payload.user_country_code ? resp.data.payload.user_country_code : "+91" : "+91");
            dispatch(setUserDetails({
                ...userState, firstName: resp.data.payload.first_name, lastName: resp.data.payload.last_name, mobileNoRe: resp.data.payload.mobile_number, company_logo: resp.data.payload.company_logo, companyId: resp.data.payload.company, companyName: resp.data.payload.company_name, is_redirect: false, user_access: resp.data.payload.user_access?.access_module_management || [], is_created_by_super_admin_user_company:
                    resp.data.payload.is_created_by_super_admin_user_company,
            }))
        }
    }


    useEffect(() => {
        getUserProfile();
    }, [value]);


    return (
        <>
            <div className="containerWrap">
                <Typography
                    variant="h5"
                    gutterBottom
                    style={{
                        paddingTop: "30px",
                        paddingLeft: "65px",
                        color: "#0E8951",
                        fontWeight: "600",
                    }}
                >
                    USER PROFILE
                </Typography>
                {/* <SectionTitle title={"USER PROFILE"}/> */}
                <Grid container>
                    <Grid item xs={12} pt="20px" px="60px">
                        <Card className="userpofileCard">
                            <Box sx={{ width: "100%", typography: "body1" }}>
                                <TabContext value={value}>
                                    <Box sx={{ borderBottom: 1, border: "none" }}>
                                        <TabList
                                            onChange={handleChange}
                                            aria-label="lab API tabs example"
                                            sx={{
                                                "& .MuiTab-root": {
                                                    fontWeight: 600,
                                                    textTransform: "none",
                                                    borderRadius: "0",
                                                    color: "#AEBCD2",
                                                    "&.Mui-selected": {
                                                        color: "#001323",
                                                    },
                                                },
                                            }}
                                        >
                                            <Tab
                                                label={
                                                    <div
                                                        style={{ display: "flex", alignItems: "center" }}
                                                    >
                                                        {value == "1" ? (
                                                            <img
                                                                src={require("../../assets/images/user_dark.png")}
                                                                alt=""
                                                                style={{ marginRight: "8px" }}
                                                            />
                                                        ) : (
                                                            <img
                                                                src={require("../../assets/images/user_light.png")}
                                                                alt=""
                                                                style={{ marginRight: "8px" }}
                                                            />
                                                        )}
                                                        Personal
                                                    </div>
                                                }
                                                value="1"
                                                style={{ fontSize: "20px", fontWeight: "500" }}
                                            />
                                            <Tab
                                                label={
                                                    <div
                                                        style={{ display: "flex", alignItems: "center" }}
                                                    >
                                                        {value == "2" ? (
                                                            <img
                                                                src={require("../../assets/images/security_dark.png")}
                                                                alt=""
                                                                style={{ marginRight: "8px" }}
                                                            />
                                                        ) : (
                                                            <img
                                                                src={require("../../assets/images/security_light.png")}
                                                                alt=""
                                                                style={{ marginRight: "8px" }}
                                                            />
                                                        )}
                                                        Security
                                                    </div>
                                                }
                                                value="2"
                                                style={{ fontSize: "20px", fontWeight: "500" }}
                                            />
                                            <Tab
                                                label={
                                                    <div
                                                        style={{ display: "flex", alignItems: "center" }}
                                                    >
                                                        {value == "3" ? (
                                                            <img
                                                                src={images.communicationImg}
                                                                alt=""
                                                                style={{ marginRight: "8px" }}
                                                            />
                                                        ) : (
                                                            <img
                                                                src={require("../../assets/images/communication_light.png")}
                                                                alt=""
                                                                style={{ marginRight: "8px" }}
                                                            />
                                                        )}
                                                        Communication Settings
                                                    </div>
                                                }
                                                value="3"
                                                style={{ fontSize: "20px", fontWeight: "500" }}
                                            />
                                        </TabList>
                                    </Box>
                                    {/* =============== Personal =============== */}
                                    <PersonalTab
                                        userProfile={userProfile}
                                        setUserProfile={setUserProfile}
                                        value={value}
                                        getUserProfile={getUserProfile}
                                        setValue={setValue}
                                        mobielNo={mobielNo}
                                        mobileCountryCode={mobileCountryCode}
                                        setMobileNo={setMobileNo}
                                        setMobileCountryCode={setMobileCountryCode}
                                        setAllModelHandle={setAllModelHandle}
                                    />
                                    {/* =============== Security =============== */}
                                    {/* =============== Security =============== */}
                                    <SecurityTab
                                        userProfile={userProfile}
                                        setUserProfile={setUserProfile}
                                        getUserProfile={getUserProfile}
                                        setValue={setValue}
                                        mobielNo={mobielNo}
                                        setMobileNo={setMobileNo}
                                        setMobileCountryCode={setMobileCountryCode}
                                        mobileCountryCode={mobileCountryCode}
                                        setAllModelHandle={setAllModelHandle}
                                        allModelHandle={allModelHandle}
                                    />
                                    {/* =============== Communication Setting =============== */}
                                    <CommunicationTab
                                        userProfile={userProfile}
                                        setUserProfile={setUserProfile}
                                        getUserProfile={getUserProfile}
                                        setValue={setValue}
                                    />
                                </TabContext>
                            </Box>
                        </Card>
                        {/* <FooterText /> */}
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default UserProfile;
