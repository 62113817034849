import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts/highstock"; // Import Highstock module
import HighchartsReact from "highcharts-react-official";
import HC_more from "highcharts/highcharts-more";
import exporting from "highcharts/modules/exporting";
import boost from "highcharts/modules/boost";

HC_more(Highcharts); // Initialize the 'highcharts-more' module
exporting(Highcharts); // Initialize the 'exporting' module
boost(Highcharts);

export function MaterialPOPGroupChart({ analyticsPOPData, analyticsPOP1Data,count }) {
  const chartRef = useRef(null);

  const labels = [];

  if (
    analyticsPOPData[0]?.graph_data?.length ||
    analyticsPOP1Data[0]?.graph_data?.length
  ) {
    const popDataLength = analyticsPOPData[0]?.graph_data?.length || 0;
    const pop1DataLength = analyticsPOP1Data[0]?.graph_data?.length || 0;
    const maxLength = Math.max(popDataLength, pop1DataLength);

    for (let i = 0; i < maxLength; i++) {
      const popData = analyticsPOPData[0]?.graph_data[i] || {};
      const pop1Data = analyticsPOP1Data[0]?.graph_data[i] || {};
      const minKey = popData.created_at || "";
      const pop1MinKey = pop1Data.created_at || "";
      const labeledMinKey = `<span style="color: #C0255F;">${minKey}</span>`;
      const labeledPop1MinKey = `<span style="color: #E66595;">${pop1MinKey}</span>`;
      labels.push(`${labeledMinKey} ${labeledPop1MinKey}`);
    }
  }

  const data = [
    {
      name: analyticsPOPData.length
        ? analyticsPOPData[0].material.material_description || analyticsPOPData[0].location.location_name
        : "",
      data: analyticsPOPData.length
        ? analyticsPOPData[0] && analyticsPOPData[0].graph_data.length
          ? analyticsPOPData[0].graph_data.map((row) => parseFloat(row.count))
          : []
        : [],
      color: "#C0255F",
      stack: "Stack 0",
      barPercentage: 1,
      barThickness: 30,
    },

    {
      name: analyticsPOP1Data.length
        ? analyticsPOP1Data[0].material.material_description || analyticsPOP1Data[0].location.location_name
        : "",
      data: analyticsPOP1Data.length
        ? analyticsPOP1Data[0] && analyticsPOP1Data[0].graph_data.length
          ? analyticsPOP1Data[0].graph_data.map((row) => parseFloat(row.count))
          : []
        : [],
      color: "#E66595",
      stack: "Stack 1",
      barPercentage: 1,
      barThickness: 30,
    },
  ];
  const options = {
    chart: {
      type: "column", // Use 'column' type for vertical bar chart
      events: {
        load: function () {
          const xAxis = this.xAxis[0];
          const visibleCategories = labels.slice(-5); // Show the last 5 categories initially
          xAxis.setExtremes(
            labels.length - visibleCategories.length,
            labels.length - 1,
            false
          );
          this.redraw();
        },
      },
    },
    boost: {
      useGPUTranslations: true,
      usePreAllocated: true,
    },

    title: {
      text: null,
    },
   
    legend: {
      enabled: false, // Disable legend
    },
    xAxis: {
      categories: labels,
    },
    yAxis: {
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: "bold",
          color: "gray",
        },
      },
      title: {
        text: (analyticsPOPData[0]?.material?.unit || analyticsPOPData[0]?.unit) ,
      }
    },

    series: data,
    scrollbar: {
      enabled: true, // Enable scrollbar
    },
    exporting: {
      enabled: true, // Disable exporting options
    },
    credits: {
      enabled: false, // Disable credits
    },
  };

  useEffect(() => {
    const chart = chartRef.current?.chart;
    if (chart) {
      chart.options.xAxis.min = labels.length - 5; // Set the initial visible range for the x-axis
      chart.options.xAxis.max = labels.length - 1;
      chart.redraw();
    }
  }, [analyticsPOPData, analyticsPOP1Data]);

  return (
 
    <>
    <div id={count}>
    <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartRef}
      />
    </div>
    
    </>

  );
}

