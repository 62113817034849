import React, { useState } from "react";

import MaterialDataTabs from "./Tabs";

function EhsDatabase() {
  const [Material, setMaterial] = useState(true);
  const [Location, setLocation] = useState(true);
  const [Container, setContainer] = useState(true);

  return (
    <>
      <div>
        <MaterialDataTabs
          Material={Material}
          Location={Location}
          Container={Container}
        />
      </div>
    </>
  );
}
export default EhsDatabase;
