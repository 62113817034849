import React, { useEffect, useState } from "react";
import logo from "../../../assets/hikar.png";
import { Grid } from "@mui/material";
import Button from "../../../components/Button/Button";
import "../OTP/Otp.css";
import OTPInput, { ResendOTP } from "otp-input-react";
import { SliderAuth } from "../../../components/SliderAuth/SliderAuth";
import { loginMfaApi } from "../services";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { setUserDetails } from "../../../redux/Slice/userSlice";
import { images } from "../../../config/images";
import Loader from "../../../components/Loader/Loader";
import { useNavigate } from "react-router-dom";

export default function MfaVerification({ email, mfaUserId, onCancelClick }) {
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(60);
  const [OTP, setOTP] = useState("");
  const [visibleOTP, setvisibleOTP] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate()

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const notify = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });


  const submitMfaVerification = async () => {
    const param = {
      mfa_user_id: mfaUserId,
      email: email,
      mfa_token: OTP,
    };

    if (OTP.length != 6) {
      notify("Please Enter valid OTP.");
      return true;
    }

    try {
      setLoading(true);
      const response = await loginMfaApi(param);

      if (
        response.data.message ==
        "No user with Id: ba007454-e12d-4ba0-bece-ab304a1aa457 found"
      ) {
        notify(response.data.message);
        setLoading(false);
        return true;
      }

      if (
        response.data.message ==
        "No user with Id: ba007454-e12d-4ba0-bece-ab304a1aa457 found"
      ) {
        notify(response.data.message);
        setLoading(false);
        return true;
      }

      if (response.data.message == "OTP token is valid, Login successfully") {
        setLoading(false);


        dispatch(
          setUserDetails({
            firstName: response.data.payload.first_name,
            lastName: response.data.payload.last_name,
            mobileNoRe: response.data.payload.mobile_number,
            email: response.data.payload.email,
            token: response.data.payload.token,
            id: response.data.payload.id,
            MFA_UserId: response.data.payload.mfa_user_id,
            company_logo: response.data.payload.company_logo,
            companyId: response.data.payload.company,
            is_super_admin: response.data.payload.is_super_admin,
            is_admin: response.data.payload.is_admin,
            companyName: response.data.payload.company_name,
            user_access: response.data.payload.user_access?.access_module_management || []
          })
        );
      }
    } catch (error) {
      setLoading(false);
      notify(error.response.data.message);
    }
  };

  return (
    <>
      <div>
        <ToastContainer autoClose={8000}></ToastContainer>
      </div>

          <h1 className="forgotPassword text-center p-5 pb-3">
            MFA Verification
          </h1>
          <div className="forgotPassword-lines pb-4">
            <h5
              style={{
                color: "#001323",
                fontWeight: 400,
                letterSpacing: "0.48px",
              }}
            >
              Please Enter Google Authenticator Code
            </h5>
          </div>
          <div className="otp-input wrap">
            <OTPInput
              value={OTP}
              onChange={setOTP}
              autoFocus
              OTPLength={6}
              otpType="number"
              disabled={false}
              secure={!visibleOTP}
              className="OTP pb-3"
              inputStyle="xyz"
            />

            {visibleOTP ? (
              <img
                src={images.viewIcon}
                alt=""
                className="view icon OTP"
                onClick={() => setvisibleOTP(false)}
              />
            ) : (
              <img
                src={images.eyecloseIcon}
                alt=""
                className="view icon OTP"
                onClick={() => setvisibleOTP(true)}
              />
            )}
          </div>
      <div className="two-factor">
        <button className="cancle-two-factor-btn" onClick={() => onCancelClick()}>Cancel</button>
        <button className="submit-two-factor-btn" onClick={() => submitMfaVerification()}>Submit</button>
      </div>
      
      {isLoading && <Loader />}
    </>
  );
}
