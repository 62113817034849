import React from "react";
import Typography from "@mui/material/Typography";
import { useLocation } from "react-router-dom";
import "../Footer/Footer.css";

function FooterText() {
  const location = useLocation();

  return location.pathname == "/ehs-form" ? (
    <div className="d-flex justify-content-between footer-title">
      <div className="d-flex gap-2">
        <Typography variant="subtitle2">Legends :</Typography>
        <div className="d-flex gap-2">
            <div className="approved-legend"></div> <Typography variant="subtitle2">Approved</Typography>
            <div className="rejected-legend"></div> <Typography variant="subtitle2">Rejected</Typography>
            <div className="pending-legend"></div> <Typography variant="subtitle2">Pending</Typography>
        </div>
      </div>
      <div>
        <Typography variant="subtitle2" gutterBottom>
          Powered by{" "}
          <b style={{ fontWeight: "900" }}>Hikar®Technomation(p) Ltd</b> © All
          Rights Reserved
        </Typography>
      </div>
    </div>
  ) : (
    <Typography
      variant="subtitle2"
      gutterBottom
      style={{
        backgroundColor: "#F1F1FF",
        color: "#4D5963",
        textAlign: "end",
        margin: "0",
        position: "fixed",
        bottom: "0px",
        right: "0",
        padding: "1px",
        width: "100%",
        zIndex: "99",
        paddingRight: "23px",
      }}
    >
      Powered by <b style={{ fontWeight: "900" }}>Hikar®Technomation(p) Ltd</b>{" "}
      © All Rights Reserved
    </Typography>
  );
}

export default FooterText;
