import { useState, useEffect } from "react";
import { Card } from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import React from "react";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../Dashboard/Dashboard.css";
import { images } from "../../../config/images";
import { useSelector } from "react-redux";
import { UserAccessModule } from "../../../components/UserAccess/UserAccessmodule";
import { getDashboardCount } from "../EhsDatabase/services";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";

function Dashboard() {
  const [dashboard, setDashboard] = useState(true);
  const [dashboardCount, setDashboardCount] = useState({
    total_count: 0,
    approved_count: 0,
    pending_count: 0,
    rejected_count: 0,
  });

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null); // Initially set to last day of current month
  const [selectedYear, setSelectedYear] = useState(null);
  const [showPicker, setShowPicker] = useState(false);



  const dateFormat = "YYYY-MM-DD";

  const startdateDashboard = startDate ? dayjs(startDate).format(dateFormat) : null
  const endDateDashboard = endDate ? dayjs(endDate).format(dateFormat) : null

  const navigate = useNavigate();

  const { user_access, is_admin } = useSelector((state) => state.userState);

  const notify = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast?.POSITION?.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const adminAccessEHSForm = user_access?.length
    ? user_access.find(
        (user) => user.access_module?.access_module_name === "EHS Forms"
      )
    : null;

  useEffect(() => {
    handleDashboardCount();
  }, [startdateDashboard,endDateDashboard]);

  const handleDashboardCount = async () => {
    const params ={
      start_date : startdateDashboard,
      end_date : endDateDashboard
    }
    try {
      const resp = await getDashboardCount(params);
      if (resp.status == 200 || resp.status == 201) {
        setDashboardCount(resp.data.payload);
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };
  const handleYearChange = (date) => {
    setSelectedYear(date);
  };

  const handleMonthChange = (date) => {
    setStartDate(date);
    setEndDate(new Date(date.getFullYear(), date.getMonth() + 1, 0)); // Set end date to last day of selected month
    setShowPicker(false); // Close picker after month selection
  };

  const renderYearPicker = () => (
    <DatePicker
      selected={selectedYear}
      onChange={handleYearChange}
      showYearPicker
      dateFormat="yyyy"
      inline
    />
  );

  const renderMonthPicker = () => (
    <DatePicker
      selected={startDate}
      onChange={handleMonthChange}
      showMonthYearPicker
      dateFormat="MMMM yyyy"
      inline
      minDate={selectedYear ? new Date(selectedYear.getFullYear(), 0, 1) : null}
      maxDate={
        selectedYear ? new Date(selectedYear.getFullYear(), 11, 31) : null
      }
    />
  );
  return (
    <div className="dashboard-wrapper page-wraper">
      <div className={`${dashboard ? "d-block" : "d-none"}`}>
        <div style={{ marginBottom: "32px" }}>
          <Card className="production-card">
            <Box
              sx={{ minWidth: 200 }}
              style={{ float: "right", marginTop: "-23px" }}
            >
              <input
                type="text"
                value={
                  startDate
                    ? `${startDate.toLocaleDateString("en-US", {
                        month: "long",
                        year: "numeric",
                      })}`
                    : "Select Month"
                }
                onClick={() => setShowPicker(!showPicker)}
                className="input-ehs-month"
                readOnly
              />
              
            </Box>
            <div className="d-flex product mt-4">
              <div className="production-item">
                <div className="production-icon">
                  <img src={images.generated} alt="" />
                </div>
                <div className="production-name">
                  <p>GENERATED</p>
                  <h2>{dashboardCount.total_count}</h2>
                </div>
              </div>
              <div className="production-item">
                <div className="production-icon">
                  <img src={images.approved} alt="" />
                </div>
                <div className="production-name">
                  <p>APPROVED</p>
                  <h2>{dashboardCount.approved_count}</h2>
                </div>
              </div>
              <div className="production-item">
                <div className="production-icon">
                  <img src={images.rejected} alt="" />
                </div>
                <div className="production-name">
                  <p>REJECTED</p>
                  <h2>{dashboardCount.rejected_count}</h2>
                </div>
              </div>

              <div className="production-item">
                <div className="production-icon">
                  <img src={images.pending} alt="" />
                </div>

                <div className="production-name">
                  <p>PENDING</p>
                  <h2>{dashboardCount.pending_count}</h2>
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Card
                className="dashbord-card"
                onClick={() => {
                  if (!is_admin) {
                    if (adminAccessEHSForm?.is_viewer == false) {
                      notify("You don't have access");
                      return true;
                    }
                  }
                  navigate("/ehs-form");
                }}
              >
                <>
                  <div className="module-item">
                    <div className="module-icon">
                      <img src={images.ehsForm} alt="" />
                    </div>
                    <div className="module-name">
                      <h2>
                        EHS
                        <br />
                        Forms
                      </h2>
                    </div>
                  </div>
                </>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Card
                className="dashbord-card"
                onClick={() => {
                  if (!is_admin) {
                    const userAccess = UserAccessModule(
                      user_access,
                      "EHS Modules",
                      "EHS Database"
                    );

                    if (userAccess == null || userAccess.is_viewer === false) {
                      notify("You don't have access");
                      return true;
                    }
                  }
                  navigate("/ehs-database");
                }}
              >
                <div className="module-item">
                  <div className="module-icon">
                    <img src={images.ehsDatabase} alt="" />
                  </div>
                  <div className="module-name">
                    <h2>
                      EHS
                      <br />
                      Database
                    </h2>
                  </div>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Card
                className="dashbord-card"
                onClick={() => navigate("/advance-analytics")}
                // style={{ pointerEvents: "none" }}
              >
                <div className="module-item">
                  <div className="module-icon">
                    <img src={images.advanceAnalytics} alt="" />
                  </div>
                  <div className="module-name">
                    <h2>
                      Advanced <br />
                      Analytics
                    </h2>
                  </div>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Card
                className="dashbord-card"
                onClick={() => {
                  if (!is_admin) {
                    const userAccess = UserAccessModule(
                      user_access,
                      "EHS Modules",
                      "User Management"
                    );

                    if (userAccess == null || userAccess.is_viewer === false) {
                      notify("You don't have access");
                      return true;
                    }
                  }
                  navigate("/user-management");
                }}
              >
                <div className="module-item">
                  <div className="module-icon">
                    <img src={images.userManagement} alt="" />
                  </div>
                  <div className="module-name">
                    <h2>
                      User
                      <br />
                      Management
                    </h2>
                  </div>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Card
                className="dashbord-card"
                onClick={() => {
                  if (!is_admin) {
                    const userAccess = UserAccessModule(
                      user_access,
                      "EHS Modules",
                      "Role & Access Management"
                    );

                    if (userAccess == null || userAccess.is_viewer === false) {
                      notify("You don't have access");
                      return true;
                    }
                  }
                  navigate("/access-management");
                }}
              >
                <div className="module-item">
                  <div className="module-icon">
                    <img src={images.roleAccess} alt="" />
                  </div>
                  <div className="module-name">
                    <h2>Role & Access Management</h2>
                  </div>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Card
                className="dashbord-card"
                onClick={() => {
                  if (!is_admin) {
                    const userAccess = UserAccessModule(
                      user_access,
                      "EHS Modules",
                      "Report Management"
                    );

                    if (userAccess == null || userAccess.is_viewer === false) {
                      notify("You don't have access");
                      return true;
                    }
                  }
                  navigate("/report-management");
                }}
              >
                <div className="module-item">
                  <div className="module-icon">
                    <img src={images.reportManagement} alt="" />
                  </div>
                  <div className="module-name">
                    <h2>
                      Report
                      <br />
                      Management
                    </h2>
                  </div>
                </div>
              </Card>
            </Grid>
          </Grid>
          {showPicker && (
            <div className="ehs-datepicker-data">
              {!selectedYear ? renderYearPicker() : renderMonthPicker()}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
