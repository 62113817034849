import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
ChartJS.register(ArcElement, Tooltip, Legend);

export function MaterialPOPPiechart({
  count,
  analyticsPOPData,
  analyticsPOP1Data,
  popTotalSum,
  pop1TotalSum,
}) {

  const data = {
    datasets: [
      {
        label: analyticsPOPData.length
          ? analyticsPOPData[0].material.material_description ||
            analyticsPOPData[0].location.location_name
          : "",
        data: [
          ...(analyticsPOPData.length &&
          analyticsPOPData[0] &&
          analyticsPOPData[0].graph_data.length
            ? analyticsPOPData[0].graph_data.map((row) => parseFloat(row.count))
            : []),
          ...(analyticsPOP1Data.length &&
          analyticsPOP1Data[0] &&
          analyticsPOP1Data[0].graph_data.length
            ? analyticsPOP1Data[0].graph_data.map((row) =>
                parseFloat(row.count)
              )
            : []),
        ],
        backgroundColor: ["#C0255F", "#E66595"],
        borderColor: ["#C0255F", "#E66595"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
        labels: {
          display: false,
        },
      },
      datalabels: {
        color: "#fff",
        anchor: "center",
        align: "center",
        offset: 0,
        font: {
          weight: "bold",
          size: "14",
        },
      },
      formatter: function (value, context) {
        const dataset = data.datasets[context.datasetIndex];
        const total = dataset.data.reduce(
          (previousValue, currentValue) => previousValue + currentValue
        );
        const currentValue = dataset.data[context.dataIndex];
        const percentage = ((currentValue / total) * 100).toFixed(2) + "%";
        return `${value}\n(${percentage})`;
      },
    },
    cutout: "70%", // Adjust this value to set the width of the doughnut chart
  };
  return (
    <div
      style={{ width: "100%", height: "195px" }}
      className="doughnut-chart1"
      id={count}
    >
      <>
        <Doughnut
          data={data}
          options={options}
          style={{ margin: "auto" }}
          className="donut-chart-css"
        />
        <p className="doughnut-chartText">
          {typeof popTotalSum === "number" && typeof pop1TotalSum === "number"
            ? (popTotalSum + pop1TotalSum).toFixed(2)
            : ""}
        </p>
        <p className="doughnut-chartText-unit">
          {popTotalSum + pop1TotalSum
            ? analyticsPOPData.length
              ? analyticsPOPData[0].material.unit || analyticsPOPData[0].unit
              : ""
            : ""}
        </p>
      </>
    </div>
  );
}
