import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Textshimmer from "../../../components/Shimmer/TextShimmer/TextShimmer";

const UserManagementTableShimmer = ({itemCount}) => {
    const style = {
        backgroundColor:"white"
    }
  return (
    <>
      {Array.from(
        { length: itemCount }).map((_, index) => (
          <TableRow key={index} className="deviceManagementTable-data">
            <TableCell style={{ backgroundColor:"white"}} colSpan={2}>
              <Textshimmer height="16px" width="100%" />
            </TableCell>
            <TableCell style={style}>
              <Textshimmer height="16px" width="100%" />
            </TableCell>
            <TableCell style={style}>
              <Textshimmer height="16px" width="100%" />
            </TableCell >
            <TableCell style={style}>
              <Textshimmer height="16px" width="100%" />
            </TableCell>
            <TableCell style={style}>
             
                <Textshimmer height="16px" width="90%" />
              
            </TableCell>
            <TableCell style={style}>
            <Textshimmer height="16px" width="100%" />
            </TableCell>
            <TableCell style={style}>
            <Textshimmer height="16px" width="100%" />
            </TableCell>
          </TableRow>
        ))
      }
    </>
  );
};

export default UserManagementTableShimmer;
