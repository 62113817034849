import React from "react";
import {
  Grid,
} from "@mui/material";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import Checkbox from "@mui/material/Checkbox";
import {
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { useSelector } from "react-redux";
import { UserAccessModule } from "../../../../components/UserAccess/UserAccessmodule";
import { toast } from "react-toastify";

const ReportManagementReportList = ({
  selectEmailSchedule,
  setSelectEmailSchedule,
  selectDate,
  setSelectTime,
  selectReportType,
  setSelectReporttype,
  setActiveClass,
}) => {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const { user_access, is_admin } = useSelector((state) => state.userState);

  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      toastClassName: "custom-toast",
    });

  const handleCheckBoxClick = (value) => {
    if (!is_admin) {
      if (UserAccessModule(user_access, "EHS Modules", "Report Management")?.is_editor == false) {
        notifyError("You don't have access");
        return true;
      }
    }
    
    setSelectReporttype(value)
  };

  return (
    <>
      <Grid container style={{ paddingTop: "20px" }}>
        <Grid item xs={12}>
          <Card className="manageDepartment-card">
            <Grid
              container
              style={{
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingTop: "30px",
                paddingBottom: "30px",
              }}
            >
              {/* =============== Tag List Modal Start =============== */}

              {/* =============== Tag List Modal End =============== */}
              <Grid item sm={12} md={12} lg={4} xl={4}>
                <Typography variant="subtitle2" gutterBottom>
                  <b>Report Type</b>
                </Typography>
                <div className="reportManagement-schedule-card">
                    <div>
                      <div className="alarmManagementCard-Roleswrap">
                      <div className="d-flex">
                        <div>
                          <Checkbox
                            {...label}
                            style={{
                              color: "#0E8951",
                              marginTop:"-3px"
                            }}
                            
                            checked={selectReportType == "EHS Report Type"}
                            onClick={() => handleCheckBoxClick(selectReportType ? "" :"EHS Report Type")}
                          />
                        </div>
                        <div>
                          <Typography
                            variant="body2"
                            gutterBottom
                            className="mt-2 mb-0"
                          >
                            EHS Report Type
                          </Typography>
                        </div>
                        </div>
                      </div>
                    </div>
                </div>
              </Grid>

              <Grid item sm={12} md={12} lg={8} xl={8}>
                <Typography variant="subtitle2" gutterBottom>
                  <b>Schedule</b>
                </Typography>
                <div className="reportManagement-schedule-card">
                  <Typography
                    variant="body2"
                    gutterBottom
                    className="m-0 pt-4"
                    style={{ fontWeight: "500" }}
                  >
                    Email Schedule
                  </Typography>
                  <select
                    class="form-select form-select-lg reportManagement-schedule-dropdown"
                    aria-label=".form-select-lg example"
                    value={selectEmailSchedule}
                    onChange={(e) => {
                      if (!is_admin) {
                        if (UserAccessModule(user_access, "EHS Modules", "Report Management")?.is_editor == false) {
                          notifyError("You don't have access");
                          return true;
                        }
                      }
                      setSelectEmailSchedule(e.target.value)
                    }
                    }
                  >
                    <option value="none" selected disabled hidden>
                      Weekly
                    </option>
                    <option value="Daily">Daily</option>
                    <option value="Weekly">Weekly</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Years">Years</option>
                  </select>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className="m-0 pt-4"
                    style={{ fontWeight: "500" }}
                  >
                    Date Time
                  </Typography>

                  {selectEmailSchedule == "Daily" && (
                    <Box>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["TimePicker"]}>
                          <TimePicker
                            label="With Time Clock"
                            value={selectDate}
                            className="reportmanagement-box-datepicker"
                            onChange={(newValue) => setSelectTime(newValue)}
                            viewRenderers={{
                              hours: renderTimeViewClock,
                              minutes: renderTimeViewClock,
                              seconds: renderTimeViewClock,
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Box>
                  )}

                  {selectEmailSchedule == "Weekly" && (
                    <Box>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          label="select Date"
                          value={selectDate}
                          onChange={(newValue) => setSelectTime(newValue)}
                          className="reportmanagement-box-datepicker"
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                        />
                      </LocalizationProvider>
                    </Box>
                  )}

                  {selectEmailSchedule == "Monthly" && (
                    <Box>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          label="select Date"
                          value={selectDate}
                          onChange={(newValue) => setSelectTime(newValue)}
                          className="reportmanagement-box-datepicker"
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                        />
                      </LocalizationProvider>
                    </Box>
                  )}

                  {selectEmailSchedule == "Years" && (
                    <Box>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          label="select Date"
                          value={selectDate}
                          onChange={(newValue) => setSelectTime(newValue)}
                          className="reportmanagement-box-datepicker"
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                        />
                      </LocalizationProvider>
                    </Box>
                  )}
                  <Typography variant="body2" gutterBottom className="m-0 pt-4">
                    <b>Note:</b> To ensure timely execution, please schedule
                    reports at least 1 hour after the current time to avoid
                    delays in processing
                  </Typography>
                </div>
                <button
                  className="Plantmanagemet submit"
                  onClick={() => {
                    setActiveClass("User");
                  }}
                >
                  Next
                </button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid></Grid>
      </Grid>
    </>
  );
};
export default ReportManagementReportList;
