import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { images } from "../../config/images";
import { deleteNotification, getNotification } from "./services";
import dayjs from "dayjs";
import "../Notification/Notification.css";
import { getEHSFormDetails } from "../MainEshDashboard/EhsForm/services";
import { toast } from "react-toastify";
import { addDays } from "date-fns";
import { DateRangePicker } from "react-date-range";

const NotificationDashboard = () => {
  const [notificationData, setNotificationData] = useState([]);
  const [notificationCount, setNotificationCount] = useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [deleteId, setDeleteId] = useState();
  const [deleteNotificationPopup, setDeleteNotificationPopup] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [filterData, setFilterData] = useState({
    created_at__date: "",
    ehs_form__batch_number: "",
    ehs_form__material_code__material_description: "",
    assign_by__name: "",
    assign_to__name: "",
  });
  const inputRef = useRef(null);
  const [state, setState] = useState({
    selection: {
      startDate: "",
      endDate: "",
      key: "selection",
    },
  });

  const navigate = useNavigate();
  const dateFormat = "YYYY-MM-DD";

  const formatStartDate = state.selection.startDate
    ? dayjs(state.selection.startDate).format(dateFormat)
    : "";

  const formatEndDate = state.selection.endDate
    ? dayjs(state.selection.endDate).format(dateFormat)
    : "";

  const handleClickOutside = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setShowDatePicker(false);
    }
  };

  // Add event listener for clicks outside the date picker
  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const notify = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION?.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const notifySuccess = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION?.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  useEffect(() => {
    handleNotification();
  }, [filterData, page, rowsPerPage, formatStartDate, formatEndDate]);

  const handleNotification = async () => {
    const params = {
      page: page,
      rowsPerPage: rowsPerPage,
      start_datetime: formatStartDate ? `${formatStartDate} 00:00:00` : "",
      end_datetime: formatEndDate ? `${formatEndDate} 23:59:59` : "",
    };

    try {
      const resp = await getNotification(filterData, params);
      if (resp.status == 200 || resp.status == 201) {
        setNotificationData(resp.data.results);
        setNotificationCount(resp.data.count);
      }
    } catch (error) {
      setNotificationData([]);
      setNotificationCount(0);
      console.log("handleSubmit", error);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeSearch = (e) => {
    setFilterData({ ...filterData, [e.target.name]: e.target.value });
  };

  const handleDeleteNotification = async () => {
    try {
      const resp = await deleteNotification(deleteId);
      if (resp.status == 200 || resp.status == 201) {
        handleNotification();
        setDeleteNotificationPopup(false);
        notifySuccess(resp.data.message);
      }
    } catch (error) {
      setDeleteNotificationPopup(false);
      notify(error.response.data.message);
    }
  };

  const handleDeleteId = (id) => {
    setDeleteId(id);
    setDeleteNotificationPopup(true);
  };
  return (
    <>
      <div className="container-wraper">
        <div className="ehs-form-sec">
          <div
            className="Ehs-form-title"
            onClick={() => navigate("/dashboard")}
          >
            <KeyboardBackspaceIcon />
            <h6 className="mt-1">Notification</h6>
          </div>
          <div className="btn-sec">
            <div
              ref={inputRef}
              style={{ position: "relative", display: "inline-block" }}
            >
              <input
                type="text"
                readOnly
                value={`${formatStartDate || "Start Date"} - ${formatEndDate || "End Date"}`}
                onClick={() => setShowDatePicker(!showDatePicker)}
                placeholder="Select a date range"
                className="datepicker-input"
                style={{ cursor: "pointer" }} // Optional styling for better UX
              />
              {showDatePicker && (
                <div style={{ position: "absolute", zIndex: 1000 }}>
                  <DateRangePicker
                  className="notification-datepicker"
                    ranges={[state.selection]}
                    onChange={(item) =>
                      setState({ selection: { ...item.selection } })
                    }
                  />
                </div>
              )}
            </div>
            <button
              className="add-item-btn"
              onClick={() => navigate("/history")}
            >
              <img src={images.history} alt="" style={{ marginRight: "4px" }} />
              {/* <AddBoxIcon style={{ color: "#0e8951", marginRight: "2px" }} /> */}
              History
            </button>
          </div>
        </div>
        <div className="pt-4 table-responsive">
          <Table className="Ehs-form-table">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Batch Number</TableCell>
                <TableCell>Material Description</TableCell>
                <TableCell>Assigned By</TableCell>
                <TableCell>Assigned To</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Filter"
                    name="created_at__date"
                    value={filterData.created_at__date}
                    onChange={handleChangeSearch}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Filter"
                    name="created_at__date"
                    value={filterData.created_at__date}
                    onChange={handleChangeSearch}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Filter"
                    name="ehs_form__batch_number"
                    value={filterData.ehs_form__batch_number}
                    onChange={handleChangeSearch}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Filter"
                    name="ehs_form__material_code__material_description"
                    value={
                      filterData.ehs_form__material_code__material_description
                    }
                    onChange={handleChangeSearch}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Filter"
                    name="assign_by__name"
                    value={filterData.assign_by__name}
                    onChange={handleChangeSearch}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Filter"
                    name="assign_to__name"
                    value={filterData.assign_to__name}
                    onChange={handleChangeSearch}
                  />
                </TableCell>
                <TableCell className="search-table-row"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {notificationData?.map((e) => {
                return (
                  <TableRow>
                    <TableCell>
                      <Link
                        to={`/ehs-form/${e.ehs_form}`}
                        className="batch-link"
                      >
                        {dayjs(e.created_at).format("YYYY-MM-DD")}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        to={`/ehs-form/${e.ehs_form}`}
                        className="batch-link"
                      >
                        {dayjs(e.created_at).format("HH:mm:ss")}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        to={`/ehs-form/${e.ehs_form}`}
                        className="batch-link"
                      >
                        {e.batch_number}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        to={`/ehs-form/${e.ehs_form}`}
                        className="batch-link"
                      >
                        {e.material_description}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        to={`/ehs-form/${e.ehs_form}`}
                        className="batch-link"
                      >
                        {e.assign_by}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        to={`/ehs-form/${e.ehs_form}`}
                        className="batch-link"
                      >
                        {e.assign_to}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <img
                        src={images.deleteImage}
                        alt=""
                        style={{ cursor: "pointer" }}
                        onClick={() => handleDeleteId(e.id)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>{" "}
        <TablePagination
          className="table-pagination"
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={notificationCount ? notificationCount : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
      {deleteNotificationPopup && (
        <div className="box-container">
          <Card className="deleteConfirmation-Modal">
            <Typography
              variant="h6"
              gutterBottom
              className="deleteConfirmation-title"
            >
              Are You Sure<br></br> You Want To Delete?
            </Typography>
            <div className="pt-3 btn-div">
              <button className="submit-btn" onClick={handleDeleteNotification}>
                Yes
              </button>
              <button
                className="reset-btn"
                onClick={() => setDeleteNotificationPopup(false)}
              >
                No
              </button>
            </div>
          </Card>
        </div>
      )}
    </>
  );
};

export default NotificationDashboard;
