import { request } from "../../../api/api";

export const getMaterialCocUnits = () => {
  return request.get(`/advance_analytics/getUnits/`);
};

export const getCocMaterials = (params) => {
  return request.post(`/advance_analytics/getMaterials/`, params);
};
export const getCocLocation = (params) => {
    return request.post(`/advance_analytics/getLocations/`, params);
  };
  
export const createMaterialCocChart = (params) =>{
    return request.post(`/advance_analytics/createMaterialCOCChart/`, params);
}
export const getPOPMaterials =()=>{
    return request.get(`/advance_analytics/getPOPMaterials/`)
}

export const createMaterialPOPChart =(params) =>{
  return request.post(`/advance_analytics/createMaterialPOPChart/`, params);
}

export const getLocationInLocationGraph =(params) =>{
  return request.post(`/advance_analytics/getLocationInLocationGraph/`, params);
}

export const getMaterialInLocationGraph =(params) =>{
  return request.post(`/advance_analytics/getMaterialInLocationGraph/`, params);
}
export const createLocationCOCChart =(params) =>{
  return request.post(`/advance_analytics/createLocationCOCandPOPChart/`, params);
}
export const exportLocationCOCGraphExcel = (params)=>{
  return request.post(`/advance_analytics/exportLocationCOCGraphExcel/`,params)
}
export const exportLocationPOPGraphExcel = (params)=>{
  return request.post(`/advance_analytics/exportLocationPOPGraphExcel/`,params)
}
export const exportMaterialCOCGraphExcel = (params)=>{
  return request.post(`/advance_analytics/exportMaterialCOCGraphExcel/`,params)
}
export const exportMaterialPOPGraphExcel = (params)=>{
  return request.post(`/advance_analytics/exportMaterialPOPGraphExcel/`,params)
}