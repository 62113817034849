import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "../container/Login/SignIn/Login";
import RedirectMainSite from "../container/Login/RedirectCreatePassword/redirectMainSite";
import { UnknowRoutes } from "../components/UnknowRoutes";


const UnAuthenticationRoute = () => {


    return (
        <>
            <Router>
            <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/create-password/:uidb64/:token/" element={<RedirectMainSite />} />
                    <Route path="*" element={<UnknowRoutes />} />
                </Routes>
            </Router>

        </>
    );
};

export default UnAuthenticationRoute;
