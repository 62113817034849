import OTPInput, { ResendOTP } from "otp-input-react";
import Loader from "../../../components/Loader/Loader";
import { useEffect, useState } from "react";
import Button from "../../../components/Button/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../ForgotPassword/ForgotPassword.css";
import "./Otp.css";
import { images } from "../../../config/images";
import { forgotPasswordApi, otpVerifyApi } from "../services";


// Define the OtpEnter component
function OtpEnter({ email, OTP, setOTP, onNextClick, onCancelClick }) {
  const [isLoading, setLoading] = useState(false);
  const [visibleOTP, setvisibleOTP] = useState(false);
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(60);

  const notify = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION?.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

    const notifySuccess = (message) =>
      toast.success(message, {
        theme: "colored",
        position: toast.POSITION?.TOP_RIGHT,
        toastClassName: "custom-toast",
      });

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const handleResendOTp = async () => {
    if (email) {
      const params = {
        email: email,
      };
      try {
        setLoading(true);
        const resp = await forgotPasswordApi(params);
        if (resp.data.success == true) {
          setLoading(false);
          setSeconds(60);
          notifySuccess("OTP Resend");
        }

        if (resp.data.success == false) {
          notify(resp.data.message);
        }
      } catch (error) {
        setLoading(false);
        console.log("error", error);
      }
    }
  };

  // Handle the form submit
  const handleSubmit = async () => {
    setLoading(true);
    if(!OTP){
      notify("Please Enter OTP")
    }
    const params = {
      email: email,
      otp: OTP,
    };
    try {
      const resp = await otpVerifyApi(params);
      setLoading(false);
      if (resp.data.success == true) {
        onNextClick();
      } else {
        toast.error("Something was wrong, try again", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      setLoading(false);
      console.log("handleSubmit", error);
    }
  };

  // Render the OtpEnter component
  return (
    <>
      
      <div>
        <h3 className="form-login-title">Forgot Password?</h3>
        <p className="form-name">Please Enter OTP sent to {email}</p>
        {/* <OTPInput
          value={OTP}
          onChange={setOTP} // Fix the typo here
          autoFocus
          OTPLength={6}
          otpType="number"
          disabled={false}
          secure={!visibleOTP}
          className="OTP"
          inputStyle="xyz"
        />
        {visibleOTP ? (
          <img
            src={images.viewIcon}
            alt=""
            className="view icon OTP"
            onClick={() => setvisibleOTP(false)}
          />
        ) : (
          <img
            src={images.eyecloseIcon}
            alt=""
            className="view icon OTP"
            onClick={() => setvisibleOTP(true)}
          />
        )} */}
            <div className="otp-input wrap">
            <OTPInput
              value={OTP}
              onChange={setOTP} // Fix the typo here
              autoFocus
              OTPLength={6}
              otpType="number"
              disabled={false}
              secure={!visibleOTP}
              className="OTP"
              inputStyle="xyz"
            />
            {visibleOTP ? (
              <img
                src={images.viewIcon}
                alt=""
                className="view icon OTP"
                onClick={() => setvisibleOTP(false)}
              />
            ) : (
              <img
                src={images.eyecloseIcon}
                alt=""
                className="view icon OTP"
                onClick={() => setvisibleOTP(true)}
              />
            )}
          </div>
        {/* Display the Resend OTP button */}
        <div className="countdown-text d-flex justify-content-center pt-3">
          {seconds > 0 ? (
            <>
              <p className="d-flex" style={{ marginLeft: "50px" }}>
                Please wait for {seconds < 10 ? `0${seconds}` : seconds} seconds
              </p>
            </>
          ) : (
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              className="abc"
            >
              <div>Didn't receive code?</div>
              <div>
                <a
                  style={{
                    cursor: "pointer",
                    textDecorationLine: "underline",
                    fontWeight: 500,
                  }}
                  onClick={() => handleResendOTp()}
                >
                  Resend
                </a>
              </div>
            </div>
          )}
        </div>
        <div className="btn-group">
          <Button
            title="Cancel"
            className="bordered common-button"
            onClick={onCancelClick}
          />
          <Button
            type="submit"
            title="Submit"
            onClick={handleSubmit}
            className="common-button"
          />
        </div>
        {/* Display the loading spinner if isLoading is true */}
        {isLoading && <Loader />}
      </div>
      <div className="footer-content">
        <p>
          Powered by <br />
          <b>Hikar&#174;Technomation</b> Private Limited &#169; All Rights
          Reserved
        </p>
      </div>
    </>
  );
}
export default OtpEnter;
