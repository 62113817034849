import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { images } from "../../../config/images";
import Checkbox from "@mui/material/Checkbox";
import "./ReportManagement.css";
import { useSelector, useDispatch } from "react-redux";
import ReportGroupExpand from "./ReportGroupExpand";
import {
  getReportByPlant,
  addReportApi,
  updateReportApi,
  deleteReportApi,
} from "./service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../components/Loader/Loader";
import { ValidatorForm } from "react-material-ui-form-validator";
import ErrorIcon from "@mui/icons-material/Error";
import { ShimmerAddGroup } from "../../../components/Shimmer/ShimmerAlert/ShimmerAddGroup";
import { UserAccessModule } from "../../../components/UserAccess/UserAccessmodule";

const ReportManagement = () => {
  const [openReportModal, setOpenReportModal] = useState(false);
  const [oepnUsersmodal, setOpenUsersmodal] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [addReport, setAddReport] = useState({
    report_name: "",
    report_description: "",
  });
  const [editPlantData, setEditPlantData] = useState({
    plant_name: "",
    plant_description: "",
    company: "",
  });
  const [isLoading, setLoading] = useState(false);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [editReportModel, setEditReportModel] = useState(false);
  const [reportId, setReportId] = useState("");
  const [editReportData, setEditReportData] = useState({
    report_name: "",
    report_description: "",
  });
  const [deleteReportModel, setDeleteReportModel] = useState(false);
  const [userDetailList, setUserDetailList] = useState([]);
  const [isShimmerReport,setisShimmerReport] = useState(false);



  const { user_access, id, is_admin,companyId } = useSelector((state) => state.userState);

  const superAdminAccess = user_access?.length
    ? user_access.find(
        (user) => user.access_module?.access_module_name === "Admin Dashboard"
      )
    : null;

  const accessManagementUserReport = superAdminAccess ? superAdminAccess.sub_module.length
    ? superAdminAccess.sub_module.find(
      (f) => f.sub_module_name == "Report Management"
    )
    : null : null;

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const navigate = useNavigate();

  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      toastClassName: "custom-toast",
    });

    const notifySuccess = (message) =>
    toast.success(message, {
      theme: "colored",
      toastClassName: "custom-toast",
    });

  // handle Change
  const handleChangeReport = (e) => {
    setAddReport({ ...addReport, [e.target.name]: e.target.value });
  };

  // handleChange Plant
  const handleChangePlantData = (e) => {
    setEditPlantData({ ...editPlantData, [e.target.name]: e.target.value });
  };

  // handle Edit Report model
  const handleChangeEditReport = (e) => {
    setEditReportData({ ...editReportData, [e.target.name]: e.target.value });
  };

  // delete Model
  const handleConfirmationModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  //Add Report modal
  const handleOpenaddReportModal = () => {
    if (openReportModal || oepnUsersmodal || editReportModel) {
      return true;
    }

    if (!is_admin) {
      const userAccess = UserAccessModule(user_access, "EHS Modules", "Report Management")


      if (userAccess == null || userAccess.is_editor === false) {
        notifyError("You don't have access");
        return true;
      }
    }

    setOpenReportModal(!openReportModal);
    setOpenDeleteModal(false);
    setOpenUsersmodal(false);
    setEditReportModel(false);
    setDeleteReportModel(false);
    setAddReport({
      report_name: "",
      report_description: "",
    });
  };

  // report Edit model
  const handleEditReportModel = (row) => {
    if (openReportModal || oepnUsersmodal || editReportModel) {
      return true;
    }

    if (!is_admin) {
      const userAccess = UserAccessModule(user_access, "EHS Modules", "Report Management")


      if (userAccess == null || userAccess.is_editor === false) {
        notifyError("You don't have access");
        return true;
      }
    }

    setEditReportModel(!editReportModel);
    setOpenReportModal(false);
    setOpenDeleteModal(false);
    setOpenUsersmodal(false);
    setDeleteReportModel(false);

    setReportId(row.id);
    setEditReportData({
      report_name: row.report_name,
      report_description: row.report_description,
    });
  };

  // close edit Report model
  const handleEditReportCloseModel = () => {
    setEditReportModel(!editReportModel);
    setReportId("");
    setEditReportData({
      report_name: "",
      report_description: "",
    });
  };

  //Users modal
  const handleOpenUsersModal = (user, id) => {
    if (openReportModal || oepnUsersmodal || editReportModel) {
      return true;
    }
    console.log("useruser", user);

    if (!is_admin) {
      const userAccess = UserAccessModule(user_access, "EHS Modules", "Report Management")


      if (userAccess == null || userAccess.is_editor === false) {
        notifyError("You don't have access");
        return true;
      }
    }

    if (user.length) {
      setOpenUsersmodal(!oepnUsersmodal);
      setUserDetailList(user);
      setReportId(id);
    }
  };

  const handleCloseUsersModal = () => {
    setOpenUsersmodal(!oepnUsersmodal);
    setUserDetailList([]);
    setReportId("");
  };

  // delete Report model
  const handleDeleteReportModel = (row) => {
    if (openReportModal || oepnUsersmodal || editReportModel) {
      return true;
    }

    if (!is_admin) {
      const userAccess = UserAccessModule(user_access, "EHS Modules", "Report Management")


      if (userAccess == null || userAccess.is_editor === false) {
        notifyError("You don't have access");
        return true;
      }
    }

    setDeleteReportModel(!deleteReportModel);
    setEditReportModel(false);
    setOpenReportModal(false);
    setOpenDeleteModal(false);
    setOpenUsersmodal(false);
    setReportId(row.id);
  };

  // delete Report close model
  const handleDeleteCloseReportModel = (row) => {
    setDeleteReportModel(!deleteReportModel);
    setReportId("");
  };


  // get Report in plant

  useEffect(() => {
      handleGetPlantByReport();
  }, []);

  const handleGetPlantByReport = async () => {
    try{
      setisShimmerReport(true)
      const paramReq = {
        company: companyId
      }
      const resp = await getReportByPlant(paramReq);
  
      if (
        resp.status == 200 ||
        (resp.status == 201 && resp.data.payload.length)
      ) {
        setTimeout(()=>{ setisShimmerReport(false)},1000)

        
        setReportData(resp.data.payload);
        setOpenUsersmodal(false);
      }
    }catch(e){
      setisShimmerReport(false)
      console.log("Error from handleGetPlantByReport",e)
    }
    
  };

  // Add Report Management
  const submitAddReport = async () => {
    if (!addReport.report_name) {
      notifyError("Please Enter Report Name.");
      return true;
    }

    if (!addReport.report_description) {
      notifyError("Please Enter Report Description.");
      return true;
    }

    const paramReq = {
      // company: id,
      report_name: addReport.report_name,
      report_description: addReport.report_description,
    };
    setLoading(true);
    try {
      const resp = await addReportApi(paramReq);
      if (resp.status == 200 || resp.status == 201) {
        notifySuccess(resp.data.message)
        handleOpenaddReportModal();
        handleGetPlantByReport();
        setOpenReportModal(false);
        setAddReport({
          report_name: "",
          report_description: "",
        });
        setInterval(() => {
          setLoading(false);
        }, 2000);
      }
    } catch (error) {
      notifyError(error.response.data.message);
      setLoading(false);
      // setInterval(() => {
      //   setLoading(false);
      // }, 2000);
    }
  };


  // handel Report Edit API
  const handleUpdateReport = async () => {
    if (!editReportData.report_name) {
      notifyError("Please Enter Report Name.");
      return true;
    }

    if (!editReportData.report_description) {
      notifyError("Please Enter Report Description.");
      return true;
    }

    const paramReq = {
      report_name: editReportData.report_name,
      report_description: editReportData.report_description,
    };
    setLoading(true);
    try {
      const resp = await updateReportApi(reportId, paramReq);
      if (resp.status == 200 || resp.status == 201) {
        notifySuccess(resp.data.message)
        handleEditReportCloseModel();
        handleGetPlantByReport();
        setInterval(() => {
          setLoading(false);
        }, 3000);
      }
    } catch (error) {
      console.log("Error", error);
      setInterval(() => {
        setLoading(false);
      }, 3000);
      notifyError(error.response.data.message);
    }
  };

  // delete Report data

  const handleDeleteReport = async () => {
    try {
      setLoading(true);
      const resp = await deleteReportApi(reportId);
      if (resp.status == 200 || resp.status == 201) {
        notifySuccess(resp.data.message)
        handleDeleteCloseReportModel();
        handleGetPlantByReport();
        setInterval(() => {
          setLoading(false);
        }, 3000);
      }
    } catch (error) {
      notifyError(error.response.data.message)
      console.log("error", error);
      setInterval(() => {
        setLoading(false);
      }, 3000);
    }
  };

  const handleClose = () => {
    setOpenReportModal(false);
  };

  const handleTostMsg = () => {
    notifyError("You don't have access.");
  };
  return (
    <>
      <div>
        {/*  */}
      </div>
      <div className="containerWrap">
        <Container maxWidth="xxl">
          <div className="deviceManagementHeader-wrap">
            <div className="deviceManagementTitle-wrap">
              <KeyboardBackspaceIcon
                className="backArrow" onClick={() => navigate("/")}
              />{" "}
              <Typography
                variant="h5"
                gutterBottom
                style={{
                  margin: "0",
                  paddingLeft: "10px",
                  color: "#222D39",
                  fontSize: "16px",
                  fontWeight: "500",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/")}
              >
                Report Management
              </Typography>
            </div>
            <div className="addDevice-buttonWrap" onClick={() => handleOpenaddReportModal()}>
              <button
                className="add-report-btn"
              >
                Add Report
              </button>
            </div>
          </div>
 

          {/* =============== User Modal End =============== */}
          
                    <div className="plantmanagementDepartment-card pt-3">
                      {!isShimmerReport ? reportData.length
                        ? reportData.map((row, index) => {
                            return (
                              <React.Fragment key={index}>
                                <ReportGroupExpand
                                  row={row}
                                  handleEditReportModel={handleEditReportModel}
                                  handleDeleteReportModel={
                                    handleDeleteReportModel
                                  }
                                  selectCompany={""}
                                  plantId={""}
                                  handleOpenUsersModal={handleOpenUsersModal}
                                  openReportModal={openReportModal}
                                  oepnUsersmodal={oepnUsersmodal}
                                  access={accessManagementUserReport}
                                  handleTostMsg ={handleTostMsg}
                                  editReportModel={editReportModel}
                                />
                                {index !== reportData.length - 1 && (
                                  <div style={{ marginBottom: "10px" }} />
                                )}{" "}
                                {/* Add a margin bottom of 10px between rows */}
                              </React.Fragment>
                            );
                          })
                          : (
                            <div className="configured-error-div">
                              <ErrorIcon className="configured-error-icon" />
                              <h5 className="mt-2">No report found.</h5>
                            </div>
                          )
                         : (
                          <>
                            {[...Array(4)].map((_, index) => (
                              <ShimmerAddGroup />
                            ))}
                          </>
                        )}
                    </div>
              

        </Container>
        <ValidatorForm onSubmit={handleUpdateReport}>
          {editReportModel && (
            <Card className="addPlant-cardModal">
              <Box className="addPlant-titleWrap">
                <Typography
                  variant="h5"
                  gutterBottom
                  className="addPlant-title"
                >
                  Edit group
                </Typography>
                <img
                  src={images.closeIcon}
                  alt=""
                  style={{
                    width: "30px",
                    cursor: "pointer",
                    backgroundColor: "white",
                  }}
                  className="closeImg"
                  onClick={handleEditReportCloseModel}
                />
              </Box>
              <Box className="addplantMdal-innerWrap">
                <Stack spacing={2} pt="10px">
                  <TextField
                    id="filled-basic"
                    name="report_name"
                    label="report name"
                    variant="filled"
                    className="addplantInputfield"
                    value={editReportData.report_name}
                    onChange={(e) => handleChangeEditReport(e)}
                    InputProps={{}}
                  />
                  <TextField
                    id="filled-basic"
                    name="report_description"
                    label="report Description"
                    variant="filled"
                    className="addplantInputfield"
                    value={editReportData.report_description}
                    onChange={(e) => handleChangeEditReport(e)}
                    InputProps={{}}
                  />
                </Stack>
                <Box className="addPlant-cardButtons">
                  <button
                    className="addPlantBtn"
                    onClick={() => handleUpdateReport()}
                  >
                    Update
                  </button>
                </Box>
              </Box>
            </Card>
          )}
        </ValidatorForm>

        {/* =============== Add Group Modal Start =============== */}
        <ValidatorForm onSubmit={submitAddReport}>
          {openReportModal && (
            <Card className="addPlant-cardModal">
              <Box className="addPlant-titleWrap">
                <Typography
                  variant="h5"
                  gutterBottom
                  className="addPlant-title"
                >
                  Report
                </Typography>
                <img
                  src={images.closeIcon}
                  alt=""
                  style={{
                    width: "30px",
                    cursor: "pointer",
                    backgroundColor: "white",
                  }}
                  className="closeImg"
                  onClick={handleClose}
                />
              </Box>
              <Box className="addplantMdal-innerWrap">
                <Stack spacing={2} pt="10px">
                  <TextField
                    id="filled-basic"
                    name="report_name"
                    label="Report Name"
                    variant="filled"
                    value={addReport.report_name}
                    onChange={handleChangeReport}
                    className="addplantInputfield"
                    InputProps={{}}
                  />
                  <TextField
                    id="filled-basic"
                    name="report_description"
                    label="Description"
                    variant="filled"
                    value={addReport.report_description}
                    onChange={handleChangeReport}
                    className="addplantInputfield"
                    InputProps={{}}
                  />
                </Stack>
                <Box className="addPlant-cardButtons">
                  <button className="addPlantBtn">Submit</button>
                </Box>
              </Box>
            </Card>
          )}
        </ValidatorForm>

        {oepnUsersmodal && (
          <Card className="alarmManagement userCardModal">
            <Box className="addPlant-titleWrap">
              <Typography
                variant="h5"
                gutterBottom
                className="addPlant-title"
              >
                Users
              </Typography>
              <img
                src={images.closeIcon}
                alt=""
                style={{
                  width: "30px",
                  cursor: "pointer",
                  backgroundColor: "white",
                }}
                className="closeImg"
                onClick={handleCloseUsersModal}
              />
            </Box>
            <Box className="alarmMangemnt userCardInnerWrap">
              <img
                src={images.settingImage}
                alt=""
                className="alarmManagement settingIcon icon-pointer"
                onClick={() =>
                   navigate(
                    `/reportmanagement/report/${reportId}/configuration/setting`
                    )
                }
              />
              {userDetailList.length
                ? userDetailList.map((row) => {
                  return (
                    <div className="reportDetailsWrap">
                      <Checkbox
                        {...label}
                        style={{ color: "#0E8951" }}
                        checked={true}
                      />
                      <Typography
                        variant="body2"
                        gutterBottom
                        className="userDetails name"
                      >
                        {row.first_name} {row.last_name}
                      </Typography>
                    </div>
                  );
                })
                : []}
            </Box>
          </Card>
        )}

        {deleteReportModel && (
          <Card className="deleteConfirmation-Modal">
            <Typography
              variant="h6"
              gutterBottom
              className="deleteConfirmation-title"
            >
              Are You Sure<br></br> You Want To Delete?
            </Typography>
            <Box className="deleteConfirmation-btnWrap">
              <button
                className="deleteConfirmation-Yesbtn"
                onClick={handleDeleteReport}
              >
                Yes
              </button>
              <button
                className="deleteConfirmation-Nobtn"
                onClick={handleDeleteCloseReportModel}
              >
                No
              </button>
            </Box>
          </Card>
        )}
      </div>
      {isLoading && <Loader />}
    </>
  );
};

export default ReportManagement;
