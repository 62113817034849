import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getUnitRedux : "",
    unitsChecked : null,
    materialCheck : false,
    materialDesc :"",
    checkedMaterials: {}, // Add this line
    materialCocStartDate :null,
    materialCocEndDate :null,
    materialPOPStartDate :null,
    materialPOPEndDate :null,
    materialPOP1StartDate :null,
    materialPOP1EndDate :null,
    deviceUnitRedux : [],
    materialIdRedux :[],
    materialIdPOPRedux :[],
    materialColors :[],
    fromLocation : false,
    toLocation : false,
    locationChecked : null,
    locationId : null,
    materialPOPColors : [],
    popFromLocation: false,
    popToLocation : false,
    locationPOPId : null,
    locationPopChecked :null,
    lLocationChecked :null,
    lLocationPOPChecked : null,
    lMaterialPOPChecked : null,
    locationMaterialId : null,
    locationPOPMaterialId : null,
    lLocationId : [],
    lLocationPOPId : null,
    locationfromlocation :false,
    locationToLocation : false,
    locationPopFromLocation : false,
    locationPopToLocation : false,
    locationColors : [],
    locationPOPColors : [],
    locationStartDate : null,
    locationEndDate : null,
    locationPOPStartDate : null,
    locationPOPEndDate : null,
    locationPOP1StartDate : null,
    locationPOP1EndDate : null,
    showTime : false,
    POPshowTime : false,
    showLocationTime : false,
    showLocationPopTime:false
}

const analyticsSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setAdvanceAnalyticsDetail(state, { payload }) {
            return { ...state, ...payload };
        },
        clearanalyticsDetails(state) {
            return initialState;
        },
        
    },
});

export const {
    setAdvanceAnalyticsDetail,
    clearanalyticsDetails,
} = analyticsSlice.actions;

export default analyticsSlice.reducer;


