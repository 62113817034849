
import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts/highstock'; // Import Highstock module
import HighchartsReact from 'highcharts-react-official';
import HC_more from 'highcharts/highcharts-more';
import exporting from 'highcharts/modules/exporting';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';


HC_more(Highcharts); // Initialize the 'highcharts-more' module
exporting(Highcharts); // Initialize the 'exporting' module

export function MaterialCOCChart({ analyticsData,materialColor,count}) {

  const {getUnitRedux} = useSelector((state) =>state.analyticsState)

  console.log("analyticsDataanalyticsData",analyticsData)

 
  const chartRef = useRef(null);
  const param = useParams();

  const labels = analyticsData.length
  ? analyticsData.flatMap((e) => e.graph_data.map((e1) => e1.created_at))
    : [];
  

 

  const data = analyticsData.length
    ? analyticsData.map((row, index) => {
    
        return {
          name: row.material.material_description || row.location.location_name,
          data: row.graph_data.length ? row.graph_data.map((row2) => parseFloat(row2.count)) : [],
          color:materialColor[index]
        };
        
      })
    : [];


  const options = {
    chart: {
      type: 'column', // Use 'column' type for vertical bar chart
      events: {
        load: function () {
          const xAxis = this.xAxis[0];
          const visibleCategories = labels.slice(-5); // Show the last 5 categories initially
          xAxis.setExtremes(labels.length - visibleCategories.length, labels.length - 1, false);
          this.redraw();
        },
      },
    }, 
    title: {
      text: null,
    },
    legend: {
      enabled: false, // Disable legend
    },
    xAxis: {
      categories: labels,
    },
    yAxis: {
      stackLabels: {
        enabled: false, // Set to false to remove the stack labels
        style: {
          fontWeight: 'bold',
          color: 'gray',
        },
      },
      title: {
        text: getUnitRedux,
      }
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        dataLabels: {
          enabled: false, // Set to false to remove the data labels
          format: '{point.y}',
        },
      },
    },
    series: data,
    scrollbar: {
      enabled: true, // Enable scrollbar
    },
    exporting: {
      enabled: true, // Disable exporting options
    },
    credits: {
      enabled: false, // Disable credits
    },
  };

  useEffect(() => {
    const chart = chartRef.current?.chart;
    if (chart) {
      chart.options.xAxis.min = labels.length - 5; // Set the initial visible range for the x-axis
      chart.options.xAxis.max = labels.length - 1;
      chart.redraw();
    }
  }, [analyticsData]);

  return (

    <>
    <div id={count}>
    <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
    </div>
     
    </>

  );
}
