import { request , multipartRequest } from "../../api/api"
import axios from "axios";

const url = "https://restcountries.com/v3.1/all";

// userProfile
export const getUserProfileApi = () => {
    return request.get('/user/getUserProfileData/')
}

// userprofile Update
export const updateUserProfile = (id, data) => {
    return multipartRequest.put(`/user/updateUserProfile/${id}/`, data)
}

export const updateUserProfileCommunication = (id, data) => {
    return request.put(`/user/updateUserProfile/${id}/`, data)
}
// cahnge password
export const changePasswordApi = (id, data) => {
    return request.put(`/user/changePassword/${id}/`, data)
}

// get Country

export const getCountry = () => {
    return axios.get(url)
}

// generateMFA
export const generateMFA = (data) => {
    return request.post('/user/generateMFA/', data)
}

//verifyMFA
export const verifyMFA = (data) => {
    return request.post('/user/verifyMFA/', data)
}

// getMobileOTP
export const getMobileOTP = (data) => {
    return request.post('/user/getMobileOTP/', data)
}

/// verifyMobileOTP
// mfa
export const verifyMobileOTP = (data) => {
    return request.post('/user/verifyMobileOTP/', data)
}

// useMobileVerifacation
export const verifyUserMobileOTP = (data) => {
    return request.post('/user/verifyMobileNumber/', data)
}


//get2stepMobileOtp
export const get2stepMobileOTP = (data) => {
    return request.post('/user/get2stepMobileOTP/', data)
}

//get Country
export const getCountryList = (data) => {
    return request.get('/user/getCountryList/')
}


// edit access management data
export const editAccessManagement = (id, data) => {
    return request.put(`/access_management/updateAccessManagement/${id}/`, data)
}