import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import ReportManagementReportList from "./ReportManagementReportList";
import ReportManagementUserList from "./ReportManagementUserList";
import dayjs from "dayjs";
import "./ReportMangementConfiguration.css";
import { getReportConfigure } from "../../services";
import { useSelector } from "react-redux";

const ReportManagementConfiguration = () => {
  const navigate = useNavigate();
  const param = useParams();

  const [activeClass, setActiveClass] = useState("Report");
  const [selectEmailSchedule, setSelectEmailSchedule] = useState("Daily");
  const [selectDate, setSelectTime] = useState(null);
  const [selectReportType, setSelectReporttype] = useState("");
  const [selectUserId, setSelectUserId] = useState([]);
  const [departmentId, setDepartmentId] = useState("");
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectUserListObj, setSelectUserListObj] = useState([]);
  const [isConfigure, setConfigure] = useState(false);
  const [reportName, setReportName] = useState("");

  // date format
  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm:ss";
  const selectDateFormat =
    selectDate != null ? dayjs(selectDate).format(dateFormat) : "";
  const selectTimeFormat = selectDate
    ? dayjs(selectDate).format(timeFormat)
    : "";

  const { user_access, id, companyId } = useSelector((state) => state.userState);

  console.log("selectUserListObj", selectUserListObj);

  useEffect(() => {
    handleConfigureReportApi();
  }, []);

  function convertToISOFormat(date, time) {
    const [year, month, day] = date.split("-");
    const [hours, minutes, seconds] = time.split(":");

    const isoDateTime = new Date(
      year,
      month - 1,
      day,
      hours,
      minutes,
      seconds
    ).toISOString();

    return isoDateTime;
  }

  const handleConfigureReportApi = async () => {
    const paramReq = {
      report: param.reportId,
    };
    const resp = await getReportConfigure(paramReq);
    if (resp.status == 200 || (resp.status == 201 && resp.data.payload)) {
      console.log("resprespresprespresp", resp.data.payload);
      setSelectEmailSchedule(
        resp.data.payload.email_schedule
          ? resp.data.payload.email_schedule
          : "Daily"
      );
      const convertedDateTime =
        resp.data.payload.configuration_date &&
        resp.data.payload.configuration_time
          ? convertToISOFormat(
              resp.data.payload.configuration_date,
              resp.data.payload.configuration_time
            )
          : null;
      const DateReFormat = convertedDateTime ? dayjs(convertedDateTime) : null;
      setSelectTime(DateReFormat);
      setSelectUserListObj(
        resp.data.payload.user.length
          ? [
              {
                departmentId: resp.data.payload.department
                  ? resp.data.payload.department
                  : "",
                role: resp.data.payload.role ? resp.data.payload.role : "",
                user: resp.data.payload.user.length
                  ? resp.data.payload.user.map((row) => row.id)
                  : [],
              },
            ]
          : []
      );
      setDepartmentId(
        resp.data.payload.department ? resp.data.payload.department : ""
      );
      setConfigure(resp.data.payload.user.length ? true : false);
      setReportName(resp.data.payload.report_name);
      setSelectReporttype(resp.data.payload.report_type);
    }
  };

  return (
    <>
      <div className="containerWrap">
        <Container maxWidth="xxl">
          <div className="www">
            <div className="deviceManagementHeader-wrap">
              <div className="deviceManagementTitle-wrap">
                <KeyboardBackspaceIcon
                  onClick={() => navigate("/reportmanagement")}
                  className="backArrow"
                />{" "}
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{
                    margin: "0",
                    paddingLeft: "10px",
                    color: "#222D39",
                    fontSize: "16px",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/reportmanagement")}
                >
                  {reportName} Configuration
                </Typography>
              </div>
            </div>
            <div className="d-flex pt-4">
              <button
                className={`${
                  activeClass == "Report" ? "device-tab" : "user-tab"
                }`}
                onClick={() => setActiveClass("Report")}
              >
                Report
              </button>
              <button
                className={`${
                  activeClass == "User" ? "device-tab" : "user-tab"
                }`}
                onClick={() => {
                  setActiveClass("User");
                }}
              >
                User
              </button>
            </div>
          </div>
          {activeClass == "Report" ? (
            <>
              <ReportManagementReportList
                selectEmailSchedule={selectEmailSchedule}
                setSelectEmailSchedule={setSelectEmailSchedule}
                selectDate={selectDate}
                setSelectTime={setSelectTime}
                selectReportType={selectReportType}
                setSelectReporttype={setSelectReporttype}
                setActiveClass={setActiveClass}
              />
            </>
          ) : (
            <>
              <ReportManagementUserList
                activeClass={activeClass}
                selectEmailSchedule={selectEmailSchedule}
                selectDateFormat={selectDateFormat}
                selectTimeFormat={selectTimeFormat}
                selectUserId={selectUserId}
                setSelectUserId={setSelectUserId}
                departmentId={departmentId}
                setDepartmentId={setDepartmentId}
                selectedRoles={selectedRoles}
                setSelectedRoles={setSelectedRoles}
                selectReportType={selectReportType}
                selectUserListObj={selectUserListObj}
                setSelectUserListObj={setSelectUserListObj}
                isConfigure={isConfigure}
                  companyId={companyId}
              />
            </>
          )}
        </Container>
      </div>
      {/* =============================================================== */}
    </>
  );
};

export default ReportManagementConfiguration;
