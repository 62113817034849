import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Link, useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import AddBoxIcon from "@mui/icons-material/AddBox";
import "../EhsDatabase/EhsDatabase.css";
import Material from "./Material";
import Location from "./Location";
import Container from "./Container";
import { images } from "../../../config/images";
import CloseIcon from "@mui/icons-material/Close";
import {
  addContainer,
  addLocation,
  addMaterial,
  exportContainerCSV,
  exportLocationCSV,
  exportMaterialCsv,
  getContainerDataAPI,
  getLocationDataAPI,
  getMaterialDataAPI,
  importCSVConfiguration,
  importCSVContainerConfiguration,
  importCSVLocationConfiguration,
} from "./services";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getContainerDATA,
  getLocationDATA,
  getMaterialDATA,
} from "../EhsForm/services";
import { UserAccessModule } from "../../../components/UserAccess/UserAccessmodule";
import { useSelector } from "react-redux";
import { Card } from "@mui/material";
import { MuiFileInput } from "mui-file-input";

// Define the TabPanel component and its props
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  // Render the tab panel
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function MaterialDataTabs(props) {
  const [value, setValue] = React.useState(0);
  const [addMaterialPopup, setMaterialPopup] = useState(false);
  const [addLocationPopup, setLocationPopup] = useState(false);
  const [addContainerPopup, setContainerPopup] = useState(false);
  const [materialCode, setMaterialCode] = useState("");
  const [materialdesc, setMaterialDesc] = useState("");
  const [unit, setUnit] = useState("");
  const [categoryNo, setCategoryNo] = useState("");
  const [locationCode, setLocationCode] = useState("");
  const [locationName, setLocationName] = useState("");
  const [containerCode, setContainerCode] = useState("");
  const [containerName, setContainerName] = useState("");
  const [count, setCount] = useState(0);
  const [getMaterialData, setGetMaterialData] = useState([]);
  const [getLocationData, setGetLocationData] = useState([]);
  const [getContainerData, setGetContainerData] = useState([]);
  const [materialIDs, setMaterialIDs] = useState([]);
  const [locationIDs, setLocationIDs] = useState([]);
  const [containerIDs, setContainerIDs] = useState([]);
  const [fileURL, setFileURL] = useState();
  const [openImportCSVModal, setOpenImportCSVModal] = useState("");
  const [openImportLocationCSVModal, setOpenImportLocationCSVModal] =
    useState("");
  const [openImportContainerCSVModal, setOpenImportContainerCSVModal] =
    useState("");
  const [file, setFile] = React.useState(null);

  const { companyId } = useSelector((state) => state.userState);

  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeFile = (newFile) => {
    setFile(newFile);
  };

  const handleImportCSV = () => {
    setOpenImportCSVModal(!openImportCSVModal);
    setFile(null);
  };

  const handleImportLocationCSV = () => {
    setOpenImportLocationCSVModal(!openImportLocationCSVModal);
    setFile(null);
  };
  const handleImportContainerCSV = () => {
    setOpenImportContainerCSVModal(!openImportContainerCSVModal);
    setFile(null);
  };

  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      toastClassName: "custom-toast",
    });

  const handleImportCSVConfiguration = async () => {
    if (file == null) {
      notifyError("Please Add CSV file");
      return true;
    }

    // // check CSV file or Not
    // // isCSVFile(file.name);

    if (!file.name.endsWith(".csv")) {
      notifyError("Please Add CSV file");
      return true;
    }
    console.log("file", file);

    try {
      const formData = new FormData();
      formData.append("excel_file", file);
      formData.append("company ", companyId);
      const resp = await importCSVConfiguration(formData);

      if (resp.status == 200 || resp.status == 201) {
        notifySuccess(resp.data.message);
        // handleGetMaterial();
        setCount((prev) => prev + 1);
        handleImportCSV();
        setFile(null);
      }
    } catch (error) {
      console.log("error", error);

      notifyError(error.response.data.message);
    }
  };

  const handleImportContainerCSVConfiguration = async () => {
    if (file == null) {
      notifyError("Please Add CSV file");
      return true;
    }

    // // check CSV file or Not
    // // isCSVFile(file.name);

    if (!file.name.endsWith(".csv")) {
      notifyError("Please Add CSV file");
      return true;
    }
    console.log("file", file);

    try {
      const formData = new FormData();
      formData.append("excel_file", file);
      formData.append("company ", companyId);
      const resp = await importCSVContainerConfiguration(formData);

      if (resp.status == 200 || resp.status == 201) {
        notifySuccess(resp.data.message);
        // handleGetMaterial();
        setCount((prev) => prev + 1);
        handleImportContainerCSV();
        setFile(null);
      }
    } catch (error) {
      console.log("error", error);

      notifyError(error.response.data.message);
    }
  };
  const handleImportLocationCSVConfiguration = async () => {
    if (file == null) {
      notifyError("Please Add CSV file");
      return true;
    }

    // // check CSV file or Not
    // // isCSVFile(file.name);

    if (!file.name.endsWith(".csv")) {
      notifyError("Please Add CSV file");
      return true;
    }
    console.log("file", file);

    try {
      const formData = new FormData();
      formData.append("excel_file", file);
      formData.append("company ", companyId);
      const resp = await importCSVLocationConfiguration(formData);

      if (resp.status == 200 || resp.status == 201) {
        notifySuccess(resp.data.message);
        // handleGetMaterial();
        setOpenImportLocationCSVModal(false);
        setCount((prev) => prev + 1);
        handleImportLocationCSV();
        setFile(null);
      }
    } catch (error) {
      console.log("error", error);
      setOpenImportLocationCSVModal(false);
      notifyError(error.response.data.message);
    }
  };
  const { user_access, is_admin } = useSelector((state) => state.userState);

  const handleMaterialClick = () => {
    if (!is_admin) {
      const userAccess = UserAccessModule(
        user_access,
        "EHS Modules",
        "EHS Database"
      );

      if (userAccess == null || userAccess.is_editor === false) {
        notify("You don't have access");
        return true;
      }
    }
    setMaterialPopup(true);
  };
  const handleLocationClick = () => {
    if (!is_admin) {
      const userAccess = UserAccessModule(
        user_access,
        "EHS Modules",
        "EHS Database"
      );

      if (userAccess == null || userAccess.is_editor === false) {
        notify("You don't have access");
        return true;
      }
    }
    setLocationPopup(true);
  };
  const handleContainerClick = () => {
    if (!is_admin) {
      const userAccess = UserAccessModule(
        user_access,
        "EHS Modules",
        "EHS Database"
      );

      if (userAccess == null || userAccess.is_editor === false) {
        notify("You don't have access");
        return true;
      }
    }
    setContainerPopup(true);
  };

  const notify = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION?.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const notifySuccess = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION?.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const handleAddMaterial = async () => {
    if (!unit) {
      notify("Please enter unit");
      return;
    }
    if (!materialCode) {
      notify("Please enter material code");
      return;
    }
    if (!categoryNo) {
      notify("Please enter hwm rules category no.");
      return;
    }
    if (!materialdesc) {
      notify("Please enter material description");
      return;
    }

    const params = {
      material_code: materialCode,
      material_description: materialdesc,
      unit: unit,
      hwm_rules_category_no: categoryNo,
    };
    try {
      const resp = await addMaterial(params);
      if (resp.status == 200 || resp.status == 201) {
        setMaterialPopup(false);
        setMaterialCode("");
        setCategoryNo("");
        setUnit("");
        setMaterialDesc("");
        notifySuccess(resp.data.message);
        setCount((prev) => prev + 1);
      }
    } catch (error) {
      setMaterialPopup(false);
      notify(error.response.data.message);
    }
  };

  const handleAddLocation = async () => {
    if (!locationName) {
      notify("Please enter location name");
      return;
    }
    if (!locationCode) {
      notify("Please enter location code");
      return;
    }

    const params = {
      location_code: locationCode,
      location_name: locationName,
    };
    try {
      const resp = await addLocation(params);
      if (resp.status == 200 || resp.status == 201) {
        setLocationCode("");
        setLocationName("");
        setCount((prev) => prev + 1);
        setLocationPopup(false);
        notifySuccess(resp.data.message);
      }
    } catch (error) {
      setLocationPopup(false);
      notify(error.response.data.message);
    }
  };

  const handleAddContainer = async () => {
    if (!containerName) {
      notify("Please enter container name");
      return;
    }
    if (!containerCode) {
      notify("Please enter container code");
      return;
    }

    const params = {
      container_code: containerCode,
      container_name: containerName,
    };
    try {
      const resp = await addContainer(params);
      if (resp.status == 200 || resp.status == 201) {
        setContainerCode("");
        setContainerName("");
        setContainerPopup(false);
        setCount((prev) => prev + 1);
        notifySuccess(resp.data.message);
      }
    } catch (error) {
      setContainerPopup(false);
      notify(error.response.data.message);
    }
  };

  useEffect(() => {
    handleGetMaterial();
  }, [count]);

  const handleGetMaterial = async () => {
    try {
      const resp = await getMaterialDataAPI();
      if (resp.status == 200 || resp.status == 201) {
        setGetMaterialData(resp.data.payload.results);
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  useEffect(() => {
    handleGetLocation();
  }, [count]);

  const handleGetLocation = async () => {
    try {
      const resp = await getLocationDataAPI();
      if (resp.status == 200 || resp.status == 201) {
        setGetLocationData(resp.data.payload.results);
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  useEffect(() => {
    handleGetContainer();
  }, [count]);

  const handleGetContainer = async () => {
    try {
      const resp = await getContainerDataAPI();
      if (resp.status == 200 || resp.status == 201) {
        setGetContainerData(resp.data.payload.results);
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };


  const handleMaterialCSV = async () => {
    try {
      const resp = await exportMaterialCsv();
      if (resp.status == 200 || resp.status == 201) {
        const url = resp.data.payload.file_url;
        const filename = url.split("/").pop();

        const link = document.createElement("a");
        link.href = url;
        link.download = filename;
        link.style.display = "none";

        document.body.appendChild(link);
        link.click();
        notifySuccess(resp.data.message);
      }
    } catch (error) {
      notify(error.response.data.message);
    }
  };

  const handleLocationCSV = async () => {
    try {
      const resp = await exportLocationCSV();
      if (resp.status == 200 || resp.status == 201) {
        const url = resp.data.payload.file_url;
        const filename = url.split("/").pop();

        const link = document.createElement("a");
        link.href = url;
        link.download = filename;
        link.style.display = "none";

        document.body.appendChild(link);
        link.click();
        notifySuccess(resp.data.message);
      }
    } catch (error) {
      notify(error.response.data.message);
    }
  };


  const handleContainerCSV = async () => {
    try {
      const resp = await exportContainerCSV();
      if (resp.status == 200 || resp.status == 201) {
        const url = resp.data.payload.file_url;
        const filename = url.split("/").pop();

        const link = document.createElement("a");
        link.href = url;
        link.download = filename;
        link.style.display = "none";

        document.body.appendChild(link);
        link.click();
        notifySuccess(resp.data.message);
      }
    } catch (error) {
      notify(error.response.data.message);
    }
  };

  return (
    <>
      <div className="container-wraper">
        <>
          <div className="ehs-form-sec">
            <div
              className="Ehs-form-title"
              onClick={() => navigate("/dashboard")}
            >
              <KeyboardBackspaceIcon />
              <h6 className="mt-1">EHS Database</h6>
            </div>
            <div className="ehs-part">
              <div className="btn-sec">
                {value === 0 ? (
                  <>
                    <button
                      className="add-item-btn"
                      onClick={handleMaterialClick}
                    >
                      <AddBoxIcon
                        style={{ color: "#0e8951", marginRight: "2px" }}
                      />
                      Add Item
                    </button>
                    <img
                      src={images.exportImage}
                      className="export-image"
                      alt=""
                      onClick={handleMaterialCSV}
                    />
                    <img
                      src={images.importImage}
                      className="export-image"
                      alt=""
                      onClick={handleImportCSV}
                    />
                  </>
                ) : value === 1 ? (
                  <>
                    <button
                      className="add-item-btn"
                      onClick={handleLocationClick}
                    >
                      <AddBoxIcon
                        style={{ color: "#0e8951", marginRight: "2px" }}
                      />
                      Add Item
                    </button>
                    <img
                      src={images.exportImage}
                      className="export-image"
                      alt=""
                      onClick={handleLocationCSV}
                    />
                    <img
                      src={images.importImage}
                      className="export-image"
                      alt=""
                      onClick={handleImportLocationCSV}
                    />
                  </>
                ) : value === 2 ? (
                  <>
                    <button
                      className="add-item-btn"
                      onClick={handleContainerClick}
                    >
                      <AddBoxIcon
                        style={{ color: "#0e8951", marginRight: "2px" }}
                      />
                      Add Item
                    </button>
                    <img
                      src={images.exportImage}
                      className="export-image"
                      alt=""
                      onClick={handleContainerCSV}
                    />
                    <img
                      src={images.importImage}
                      className="export-image"
                      alt=""
                      onClick={handleImportContainerCSV}
                    />
                  </>
                ) : null}
              </div>
            </div>
          </div>
          <Box sx={{ width: "100%" }}>
            <Box>
              {props.Material && props.Location && props.Container && (
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  className="box-tabs-table"
                >
                  <Tab label="Material" className="material-tabs" />
                  <Tab label="Location" className="location-tabs" />
                  <Tab label="Container" className="container-tabs" />
                </Tabs>
              )}
            </Box>
            <TabPanel value={value} index={0}>
              <Material Material={props.Material} count={count} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Location Location={props.Location} count={count} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Container Container={props.Container} count={count} />
            </TabPanel>
          </Box>
          {addMaterialPopup && (
            <div className="box-container">
              <Box className="add-material-box">
                <div className="p-2">
                  <div className="add-material-div">
                    <h6>Add New Material</h6>
                    <CloseIcon
                      style={{
                        cursor: "pointer",
                        fontSize: "31px",
                        marginTop: "-5px",
                      }}
                      onClick={() => setMaterialPopup(false)}
                    />
                  </div>
                  <div>
                    <div className="dropdown-area pt-2">
                      <h6>Unit</h6>
                      <input
                        className="materialcode-dropdown"
                        placeholder="Enter Unit"
                        name="unit"
                        value={unit}
                        onChange={(e) => setUnit(e.target.value)}
                      ></input>
                    </div>
                    <div className="dropdown-area pt-2">
                      <h6>Material Code</h6>
                      <input
                        className="materialcode-dropdown"
                        placeholder="Enter Material Code"
                        value={materialCode}
                        onChange={(e) => setMaterialCode(e.target.value)}
                      ></input>
                    </div>
                    <div className="dropdown-area pt-2">
                      <h6>HWM RULES Category No</h6>
                      <input
                        className="materialcode-dropdown"
                        placeholder="Enter HWM RULES Category No"
                        value={categoryNo}
                        onChange={(e) => setCategoryNo(e.target.value)}
                      ></input>
                    </div>
                    <div className="dropdown-area pt-2">
                      <h6>Material Description</h6>
                      <textarea
                        rows={3}
                        placeholder="Enter Material Description"
                        className="approve-textarea materialcode-dropdown"
                        value={materialdesc}
                        onChange={(e) => setMaterialDesc(e.target.value)}
                      ></textarea>
                    </div>

                    <div className="pt-2 btn-div">
                      <button
                        className="reset-btn"
                        onClick={() => setMaterialPopup(false)}
                      >
                        Reset
                      </button>
                      <button
                        className="submit-btn"
                        onClick={handleAddMaterial}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </Box>
            </div>
          )}
          {addLocationPopup && (
            <div className="box-container">
              <Box className="add-material-box">
                <div className="p-2">
                  <div className="add-material-div">
                    <h6>Add New Location</h6>
                    <CloseIcon
                      style={{
                        cursor: "pointer",
                        fontSize: "31px",
                        marginTop: "-5px",
                      }}
                      onClick={() => setLocationPopup(false)}
                    />
                  </div>
                  <div>
                    <div className="dropdown-area pt-2">
                      <h6>Location Name</h6>
                      <input
                        className="materialcode-dropdown"
                        placeholder="Enter Location Name"
                        value={locationName}
                        onChange={(e) => setLocationName(e.target.value)}
                      ></input>
                    </div>
                    <div className="dropdown-area pt-2">
                      <h6>Location Code</h6>
                      <input
                        className="materialcode-dropdown"
                        placeholder="Enter Location Code"
                        value={locationCode}
                        onChange={(e) => setLocationCode(e.target.value)}
                      ></input>
                    </div>

                    <div className="pt-3 btn-div">
                      <button
                        className="reset-btn"
                        onClick={() => setLocationPopup(false)}
                      >
                        Reset
                      </button>
                      <button
                        className="submit-btn"
                        onClick={handleAddLocation}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </Box>
            </div>
          )}
          {addContainerPopup && (
            <div className="box-container">
              <Box className="add-material-box">
                <div className="p-2">
                  <div className="add-material-div">
                    <h6>Add New Container</h6>
                    <CloseIcon
                      style={{
                        cursor: "pointer",
                        fontSize: "31px",
                        marginTop: "-5px",
                      }}
                      onClick={() => setContainerPopup(false)}
                    />
                  </div>
                  <div>
                    <div className="dropdown-area pt-2">
                      <h6>Container Name</h6>
                      <input
                        className="materialcode-dropdown"
                        placeholder="Enter Container Name"
                        value={containerName}
                        onChange={(e) => setContainerName(e.target.value)}
                      ></input>
                    </div>
                    <div className="dropdown-area pt-2">
                      <h6>Container Code</h6>
                      <input
                        className="materialcode-dropdown"
                        placeholder="Enter Container Code"
                        value={containerCode}
                        onChange={(e) => setContainerCode(e.target.value)}
                      ></input>
                    </div>

                    <div className="pt-3 btn-div">
                      <button
                        className="reset-btn"
                        onClick={() => setContainerPopup(false)}
                      >
                        Reset
                      </button>
                      <button
                        className="submit-btn"
                        onClick={handleAddContainer}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </Box>
            </div>
          )}
          {openImportCSVModal && (
            <div className="box-container">
              <Card className="importCSVModal">
                <Typography
                  variant="h6"
                  gutterBottom
                  className="importCSVModal-text"
                >
                  Import CSV
                </Typography>
                <Box className="ImportCSV Input">
                  <MuiFileInput
                    value={file}
                    onChange={handleChangeFile}
                    placeholder="Insert a file"
                  />
                </Box>
                {/* <input class="form-control" type="file" id="formFile" style={{marginBottom:'20px'}}/> */}
                <Box className="ImportCSV-BtnWrap">
                  <button
                    className="ImportCSV-AddBtn"
                    onClick={handleImportCSVConfiguration}
                  >
                    Add
                  </button>
                  <button
                    className="ImportCSV-CancelBtn"
                    onClick={handleImportCSV}
                  >
                    Cancel
                  </button>
                </Box>
              </Card>
            </div>
          )}
          {openImportLocationCSVModal && (
            <div className="box-container">
              <Card className="importCSVModal">
                <Typography
                  variant="h6"
                  gutterBottom
                  className="importCSVModal-text"
                >
                  Import CSV
                </Typography>
                <Box className="ImportCSV Input">
                  <MuiFileInput
                    value={file}
                    onChange={handleChangeFile}
                    placeholder="Insert a file"
                  />
                </Box>
                {/* <input class="form-control" type="file" id="formFile" style={{marginBottom:'20px'}}/> */}
                <Box className="ImportCSV-BtnWrap">
                  <button
                    className="ImportCSV-AddBtn"
                    onClick={handleImportLocationCSVConfiguration}
                  >
                    Add
                  </button>
                  <button
                    className="ImportCSV-CancelBtn"
                    onClick={handleImportLocationCSV}
                  >
                    Cancel
                  </button>
                </Box>
              </Card>
            </div>
          )}
          {openImportContainerCSVModal && (
            <div className="box-container">
              <Card className="importCSVModal">
                <Typography
                  variant="h6"
                  gutterBottom
                  className="importCSVModal-text"
                >
                  Import CSV
                </Typography>
                <Box className="ImportCSV Input">
                  <MuiFileInput
                    value={file}
                    onChange={handleChangeFile}
                    placeholder="Insert a file"
                  />
                </Box>
                {/* <input class="form-control" type="file" id="formFile" style={{marginBottom:'20px'}}/> */}
                <Box className="ImportCSV-BtnWrap">
                  <button
                    className="ImportCSV-AddBtn"
                    onClick={handleImportContainerCSVConfiguration}
                  >
                    Add
                  </button>
                  <button
                    className="ImportCSV-CancelBtn"
                    onClick={handleImportContainerCSV}
                  >
                    Cancel
                  </button>
                </Box>
              </Card>
            </div>
          )}
        </>
      </div>
    </>
  );
}
