import axios from "axios";

export const urls = {
    apiBaseUrl: process.env.REACT_APP_API_URL
}

const username = "QR_USER";
const password = "pass@123";
export const sapApi = axios.create({
   headers : {
    "Content-Type": "application/json",
    Authorization: 'Basic ' + btoa(username + ':' + password)
  },
  baseURL: "https://apidev.asianpaints.com"
});