import React, { useEffect, useRef, useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "../EhsForm/EhsForm.css";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { images } from "../../../config/images";
import dayjs from "dayjs";
import {
  Box,
  Card,
  MenuItem,
  Select,
  TableBody,
  TablePagination,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { Table, TableCell, TableHead, TableRow } from "@material-ui/core";
import { useNavigate, useParams } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { getMaterial } from "../EhsDatabase/services";
import {
  SI_Waste_posting_Api,
  SI_BULK_MOVEMENT_KHAN_Api,
  addEHSForm,
  approveEHSForm,
  getAuthorityAPI,
  getEHSApprovalHistory,
  getEHSFormDataAPI,
  getEHSFormDetails,
  getMaterialDATA,
  redirectNotiToEHSForm,
  exportEHSFormExcel,
  ehsFormHistoryPDF,
  getMaterialList,
  getLocationList,
  getContainerList,
} from "./services";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserAccessModule } from "../../../components/UserAccess/UserAccessmodule";
import { useSelector } from "react-redux";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

const EhsForm = () => {
  const [addItemModal, setAddItemModal] = useState(false);
  const [wasteDisposalModal, setWasteDisposalModal] = useState(false);
  const [approvedPopup, setApprovedPopup] = useState(false);
  const [rejectedPopup, setRejectedPopup] = useState(false);
  const [materialCodeData, setMaterialcodeData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [containerData, setContainerData] = useState([]);
  const [getEHSFormData, setGetEHSFormData] = useState([]);
  const [formCount, setFormCount] = useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [approveID, setApproveID] = useState();
  const [getApprovalHistoryData, setGetApprovalHistoryData] = useState({});
  const [getAuthority, setGetAuthority] = useState([]);
  const [statusCount, setStatusCount] = useState("");
  const [toLocation, setToLocation] = useState("");
  const [ehsData, setEhsData] = useState();
  const [historyEhsData, setHistoryEhsData] = useState();
  const [ehsCount, setEhsCount] = useState();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [deleteExcelPopup, setDeleteExcelPopup] = useState(false);
  const [deleteHistoryExcelPopup, setDeleteHistoryExcelPopup] = useState(false);
  const inputRef = useRef(null);
  const [state, setState] = useState({
    selection: {
      startDate: "",
      endDate: "",
      key: "selection",
    },
  });
  const [approveFormData, setApproveFormData] = useState({
    authority: "",
    remark: "",
  });
  const [rejectRemark, setRejectRemark] = useState("");

  const [addEHSFormData, setEHSFormData] = useState({
    batch_number: "",
    material_code: "",
    quantity: "",
    plant: "",
    reason_for_generation: "",
    from_location: "",
    container_type: "",
  });
  console.log("ehsData", ehsData);
  const [filterData, setFilterData] = useState({
    status: "",
    search: "",
    created_at: "",
    batch_number: "",
    material_code__material_code: "",
    material_code__material_description: "",
    quantity: "",
    material_code__unit: "",
    material_code__hwm_rules_category_no: "",
    from_location__location_name: "",
    to_location__location_name: "",
    reason_for_generation: "",
    assign_user__role__role_name: "",
    assign_user__first_name: "",
  });

  const { is_admin, user_access } = useSelector((state) => state.userState);

  const params = useParams();
  const dateFormat = "YYYY-MM-DD";

  const formatStartDate = state.selection.startDate
    ? dayjs(state.selection.startDate).format(dateFormat)
    : "";

  const formatEndDate = state.selection.endDate
    ? dayjs(state.selection.endDate).format(dateFormat)
    : "";

  const handleClickOutside = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setShowDatePicker(false);
    }
  };

  // Add event listener for clicks outside the date picker
  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const notify = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION?.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const notifySuccess = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION?.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const handleAddItem = () => {
    if (!is_admin) {
      const userAccess = UserAccessModule(
        user_access,
        "EHS Forms",
        "Batch Addition"
      );

      if (userAccess == null || userAccess.is_editor === false) {
        notify("You don't have access");
        return true;
      }
    }
    setAddItemModal(true);
  };

  const navigate = useNavigate();

  const handleClick = async () => {
    setApprovedPopup(true);
    // setWasteDisposalModal(false);
  };
  const handleRejectClick = () => {
    setRejectedPopup(true);
    // setWasteDisposalModal(false);
  };

  const handleCancelClick = () => {
    setApprovedPopup(false);
    setApproveFormData("");
  };

  const handleChangeEHS = (e) => {
    setEHSFormData({
      ...addEHSFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeEHSQuantity = (e) => {
    const { name, value } = e.target;
    const intValue = parseInt(value, 10);

    if (!isNaN(intValue) && intValue === Number(value)) {
      setEHSFormData({
        ...addEHSFormData,
        [name]: intValue,
      });
    } else if (value === "") {
      setEHSFormData({
        ...addEHSFormData,
        [name]: "",
      });
    }
  };

  const handleChangeApprove = (e) => {
    setApproveFormData({
      ...approveFormData,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (addItemModal) {
      handleGetMaterialCode();
      handleGetLocationCode();
      handleGetContainerCode();
    }
  }, [addItemModal]);

  const handleGetMaterialCode = async () => {
    const resp = await getMaterialList();
    if (resp.status == 200 || resp.status == 201) {
      setMaterialcodeData(resp.data.payload);
    }
  };


  const handleGetLocationCode = async () => {
    const resp = await getLocationList();
    if (resp.status == 200 || resp.status == 201) {
      setLocationData(resp.data.payload);
    }
  };

  const handleRejectCancelClick = () => {
    setRejectedPopup(false);
    setRejectRemark("");
  };

  const handleGetContainerCode = async () => {
    const resp = await getContainerList();
    if (resp.status == 200 || resp.status == 201) {
      setContainerData(resp.data.payload);
    }
  };

  useEffect(() => {
    handleGetEHSFormData();
  }, [filterData, page, rowsPerPage, formatStartDate, formatEndDate]);

  const handleGetEHSFormData = async () => {
    const params = {
      page: page,
      rowsPerPage: rowsPerPage,
      start_datetime: formatStartDate ? `${formatStartDate} 00:00:00` : "",
      end_datetime: formatEndDate ? `${formatEndDate} 23:59:59` : " ",
    };

    console.log("paramsparams", params);
    const queryString = Object.entries(filterData)
      .filter(([key, value]) => value !== "")
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");
    try {
      const resp = await getEHSFormDetails(queryString, params);
      if (resp.status == 200 || resp.status == 201) {
        setGetEHSFormData(resp.data.results);
        setStatusCount(resp.data.status_counts);
        setFormCount(resp.data);
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };

  const handleBatch = async () => {
    if (!addEHSFormData.material_code) {
      notify("Please Enter Material Code");
      return;
    }
    if (!addEHSFormData.quantity) {
      notify("Please Enter Quantity");
      return;
    }
    if (!addEHSFormData.plant) {
      notify("Please Enter Plant");
      return;
    }
    if (!addEHSFormData.reason_for_generation) {
      notify("Please Enter Reason For Generation");
      return;
    }
    if (!addEHSFormData.from_location) {
      notify("Please Enter Location Code");
      return;
    }
    if (!addEHSFormData.container_type) {
      notify("Please Enter Container Type");
      return;
    }
    const sapParam = {
      MT_NEW_WASTE_POSTING_KHAN_REQUEST: {
        ZENV_NEW_WASTE_POSTING_KHAN: {
          P_CONT: addEHSFormData.container_type,

          P_LGORT: addEHSFormData.from_location,

          P_MATNR: addEHSFormData.material_code,

          P_QTY: addEHSFormData.quantity,

          P_TEXT: addEHSFormData.reason_for_generation,

          P_WERKS: "1109",
        },
      },
    };
    try {
      const resp = await SI_Waste_posting_Api(sapParam);
      console.log("resp", resp);
      if (resp.status == 200 || resp.status == 201) {
        handleSubmit(
          resp.data["ZENV_NEW_WASTE_POSTING_KHAN.Response"].E_BATCH_NO
        );
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  const handleSubmit = async (batchNo) => {
    const params = {
      material_code: addEHSFormData.material_code,
      quantity: addEHSFormData.quantity,
      plant: addEHSFormData.plant,
      reason_for_generation: addEHSFormData.reason_for_generation,
      from_location: addEHSFormData.from_location,
      container_type: addEHSFormData.container_type,
    };

    if (batchNo) {
      params["batch_number"] = batchNo;
    }

    try {
      const resp = await addEHSForm(params);
      if (resp.status == 200 || resp.status == 201) {
        setAddItemModal(false);
        handleGetEHSFormData();
        notifySuccess(resp.data.message);
      }
    } catch (error) {
      setAddItemModal(false);
      notify(error.response.data.message);
    }
  };

  const handleApprove = (id) => {
    setApproveID(id);
    setWasteDisposalModal(true);
  };
  useEffect(() => {
    if (approveID) {
      handleGetEHSApprovalHistory();
    }
  }, [approveID]);

  const handleGetEHSApprovalHistory = async () => {
    const params = {
      form_id: approveID,
    };
    try {
      const resp = await getEHSApprovalHistory(params);
      if (resp.status == 200 || resp.status == 201) {
        setGetApprovalHistoryData(resp.data.payload);
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };
  const handleChangeSearch = (e) => {
    setFilterData({ ...filterData, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    if (approvedPopup) {
      handlegetAuthority();
    }
  }, [approvedPopup]);

  const handlegetAuthority = async () => {
    try {
      const resp = await getAuthorityAPI();
      if (resp.status === 200 || resp.status === 201) {
        setGetAuthority(resp.data.payload);
      } else {
        // Optionally handle unexpected status codes
        console.error(`Unexpected status code: ${resp.status}`);
      }
    } catch (error) {
      console.error("An error occurred while fetching authority:", error);
      // Optionally set an error state or perform other error handling actions
    }
  };

  const handleClickApprove = async () => {
    if (
      getApprovalHistoryData?.assign_user?.ehs_form_role != "Final EHS Manager"
    ) {
      if (!approveFormData.authority) {
        notify("Please select authority");
        return;
      }
    }

    if (!approveFormData.remark) {
      notify("Please enter remark");
      return;
    }
    const params = {
      remark: approveFormData.remark,
      ehs_form: approveID,
      status: 1,
    };

    if (
      getApprovalHistoryData?.assign_user?.ehs_form_role != "Final EHS Manager"
    ) {
      params["authority"] = approveFormData.authority;
    }

    if (
      getApprovalHistoryData?.assign_user?.ehs_form_role == "Final EHS Manager"
    ) {
      params["to_location"] = toLocation;
    }

    if (
      getApprovalHistoryData?.assign_user?.ehs_form_role == "Final EHS Manager"
    ) {
      const sapParam = {
        MT_BULK_MOVEMENT_REQUEST: {
          ZENV_BULK_MOVEMENT_KHAN: {
            P_CHARG: getApprovalHistoryData?.batch_number,

            P_FLGORT: getApprovalHistoryData?.from_location,

            P_MATNR: getApprovalHistoryData?.material_code?.material_code,

            P_QTY: getApprovalHistoryData?.quantity,

            P_TLGORT: toLocation,

            P_UOM: getApprovalHistoryData?.material_code?.unit,

            P_WERKS: "1109",
          },
        },
      };
      try {
        const resp = await SI_BULK_MOVEMENT_KHAN_Api(sapParam);
        if (resp.status == 200) {
          notifySuccess("successfull send SAP data");
        }
        console.log("resp", resp);
      } catch (error) {
        if (error.response && error.response.status == 500) {
          notify("error");
        }
      }
    }

    try {
      const resp = await approveEHSForm(params);

      if (resp.data.success == true) {
        setApprovedPopup(false);
        setApproveFormData("");
        handleGetEHSApprovalHistory();
        handleGetEHSFormData();
        notifySuccess(resp.data.message);
      }
    } catch (error) {
      console.log("represp", error);
      notify(error.response?.data?.message);
      setApprovedPopup(false);
      setApproveFormData("");
      // notify(error.reponse.data.message);
    }
  };

  const handleClickReject = async () => {
    if (!rejectRemark) {
      notify("Please enter remark");
      return;
    }
    const params = {
      remark: rejectRemark,
      ehs_form: approveID,
      status: 2,
    };
    try {
      const resp = await approveEHSForm(params);
      if (resp.data.success == true) {
        setRejectedPopup(false);
        handleGetEHSApprovalHistory();
        setRejectRemark("");
        handleGetEHSFormData();
        notifySuccess(resp.data.message);
      }
    } catch (error) {
      notify(error.response?.data?.message);
      setRejectedPopup(false);
      setRejectRemark("");
      // notify(error.reponse.data.message);
    }
  };

  useEffect(() => {
    if (params.id) {
      handleGetEHSForm();
    }
  }, [params.id]);

  const handleGetEHSForm = async () => {
    const param = {
      form_id: params.id,
    };
    try {
      const resp = await redirectNotiToEHSForm(param);
      console.log("resp", resp);
      if (resp.status == 200 || resp.status == 201) {
        setEhsData(resp.data.payload);
        setEhsCount(resp.data.status_counts);
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };
  useEffect(() => {
    if (params.historyId) {
      handleGetEHSFormHistory();
    }
  }, [params.historyId]);

  const handleGetEHSFormHistory = async () => {
    const param = {
      form_id: params.historyId,
    };
    try {
      const resp = await redirectNotiToEHSForm(param);
      console.log("resp", resp);
      if (resp.status == 200 || resp.status == 201) {
        setHistoryEhsData(resp.data.payload);
        setEhsCount(resp.data.status_counts);
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  const handleReset = () => {
    // setAddItemModal(false)
    setEHSFormData({
      batch_number: "",
      material_code: "",
      quantity: "",
      plant: "",
      reason_for_generation: "",
      from_location: "",
      container_type: "",
    });
  };

  const handleExcelEHSForm = async () => {
    setDeleteExcelPopup(true);
  };

  const handleSubmitExcel = async () => {
    if (formatStartDate && formatEndDate) {
      const params = {
        start_date: `${formatStartDate} 00:00:00`,
        end_date: `${formatEndDate} 23:59:59`,
      };
      try {
        const resp = await exportEHSFormExcel(params);
        if (resp.data.success == true) {
          setDeleteExcelPopup(false);
          notifySuccess(resp.data.message);
        }
      } catch (error) {
        setDeleteExcelPopup(false);
        notify(error.response.data.message);
      }
    } else {
      const params = {};
      try {
        const resp = await exportEHSFormExcel(params);
        if (resp.data.success == true) {
          setDeleteExcelPopup(false);
          notifySuccess(resp.data.message);
        }
      } catch (error) {
        setDeleteExcelPopup(false);
        notify(error.response.data.message);
      }
    }
  };
  const handleEHSPdf = async () => {
    setDeleteHistoryExcelPopup(true);
  };

  const handleSubmitHistoryExcel = async () => {
    const params = {
      form_id: approveID,
    };
    try {
      const resp = await ehsFormHistoryPDF(params);
      if (resp.status == 200 || resp.status == 201) {
        setDeleteHistoryExcelPopup(false);
        notifySuccess(resp.data.message);
      }
    } catch (error) {
      setDeleteHistoryExcelPopup(false);
      notify(error.response.data.message);
    }
  };
  return (
    <>
      <div className="container-wraper">
        <div className="ehs-form-sec">
          <div className="Ehs-form-title" onClick={() => navigate(-1)}>
            <KeyboardBackspaceIcon />
            <h6 className="mt-1">EHS Form</h6>
          </div>
          <div className="ehs-part">
            <div className="form-count">
              <h6>
                Approved{" "}
                <span className="app-count">
                  {params.id || params.historyId
                    ? ehsCount?.approved
                    : statusCount?.approved}
                </span>
              </h6>
              <h6 className="approved-border"></h6>
              <h6>
                Pending{" "}
                <span className="pen-count">
                  {params.id || params.historyId
                    ? ehsCount?.pending
                    : statusCount?.pending}
                </span>
              </h6>
              <h6 className="approved-border"></h6>
              <h6>
                Rejected{" "}
                <span className="rej-count">
                  {params.id || params.historyId
                    ? ehsCount?.rejected
                    : statusCount?.rejected}
                </span>
              </h6>
            </div>
            <div className="btn-sec">
              <div
                ref={inputRef}
                style={{ position: "relative", display: "inline-block" }}
              >
                <input
                  type="text"
                  readOnly
                  value={`${formatStartDate || "Start Date"} - ${
                    formatEndDate || "End Date"
                  }`}
                  onClick={() => setShowDatePicker(!showDatePicker)}
                  placeholder="Select a date range"
                  className="datepicker-input"
                  style={{ cursor: "pointer" }} // Optional styling for better UX
                />
                {showDatePicker && (
                  <div style={{ position: "absolute", zIndex: 1000 }}>
                    <DateRangePicker
                      ranges={[state.selection]}
                      onChange={(item) =>
                        setState({ selection: { ...item.selection } })
                      }
                    />
                  </div>
                )}
              </div>
              <button className="add-item-btn" onClick={handleAddItem}>
                <AddBoxIcon style={{ color: "#0e8951", marginRight: "2px" }} />
                Add Item
              </button>
              <img
                src={images.excelIcon}
                alt=""
                style={{ width: "52px", cursor: "pointer" }}
                onClick={handleExcelEHSForm}
              />
            </div>
          </div>
        </div>
        <div className="pt-4 table-responsive">
          <Table className="Ehs-form-table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Batch Number</TableCell>
                <TableCell>Batch Creation Date & Time</TableCell>
                <TableCell>Batch Created By</TableCell>
                <TableCell>Waste Material Code</TableCell>
                <TableCell>Waste Material Description</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Unit</TableCell>
                <TableCell>HWM RULES Category No</TableCell>
                <TableCell>From Location</TableCell>
                <TableCell>To Location</TableCell>
                <TableCell>Reason For Generation</TableCell>
                <TableCell>User Role</TableCell>
                <TableCell>Action Required</TableCell>
                <TableCell>View</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="search-table-row">
                  <Select
                    className="selectDropdown"
                    style={{ width: "90%", border: "none", outline: "none" }}
                    value={filterData.status}
                    variant="standard"
                    name="status"
                    onChange={handleChangeSearch}
                  >
                    <MenuItem value="">
                      <div>All</div>
                    </MenuItem>
                    <MenuItem value="2">
                      <div className="approved-status"></div>
                    </MenuItem>
                    <MenuItem value="3">
                      <div className="rejected-status"></div>
                    </MenuItem>
                    <MenuItem value="1">
                      <div className="pending-status"></div>
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Filter"
                    name="batch_number"
                    value={filterData.batch_number}
                    onChange={handleChangeSearch}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Filter"
                    name="created_at"
                    value={filterData.created_at}
                    onChange={handleChangeSearch}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Filter"
                    name="search"
                    value={filterData.search}
                    onChange={handleChangeSearch}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Filter"
                    name="material_code__material_code"
                    value={filterData.material_code__material_code}
                    onChange={handleChangeSearch}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Filter"
                    name="material_code__material_description"
                    value={filterData.material_code__material_description}
                    onChange={handleChangeSearch}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Filter"
                    name="quantity"
                    value={filterData.quantity}
                    onChange={handleChangeSearch}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Filter"
                    name="material_code__unit"
                    value={filterData.material_code__unit}
                    onChange={handleChangeSearch}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Filter"
                    name="material_code__hwm_rules_category_no"
                    value={filterData.material_code__hwm_rules_category_no}
                    onChange={handleChangeSearch}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Filter"
                    name="from_location__location_name"
                    value={filterData.from_location__location_name}
                    onChange={handleChangeSearch}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Filter"
                    name="to_location__location_name"
                    value={filterData?.to_location__location_name}
                    onChange={handleChangeSearch}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Filter"
                    name="reason_for_generation"
                    value={filterData?.reason_for_generation}
                    onChange={handleChangeSearch}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Filter"
                    name="assign_user__role__role_name"
                    value={filterData?.assign_user__role__role_name}
                    onChange={handleChangeSearch}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Filter"
                    name="assign_user__first_name"
                    value={filterData?.assign_user__first_name}
                    onChange={handleChangeSearch}
                  />
                </TableCell>
                <TableCell className="search-table-row"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {params.id ? (
                <TableRow>
                  <TableCell>
                    <div
                      className={`${
                        ehsData?.status === 1
                          ? "pending-status"
                          : ehsData?.status === 2
                          ? "approved-status"
                          : "rejected-status"
                      }`}
                    />
                  </TableCell>
                  <TableCell>{ehsData?.batch_number}</TableCell>
                  <TableCell>
                    {" "}
                    {dayjs(ehsData?.created_at).format("YYYY-MM-DD")}{" "}
                  </TableCell>
                  <TableCell>
                    {ehsData?.created_by?.first_name}{" "}
                    {ehsData?.created_by?.last_name}
                  </TableCell>
                  <TableCell>{ehsData?.material_code?.material_code}</TableCell>
                  <TableCell>
                    {ehsData?.material_code?.material_description}
                  </TableCell>
                  <TableCell>{ehsData?.quantity}</TableCell>
                  <TableCell>{ehsData?.material_code?.unit}</TableCell>
                  <TableCell>
                    {ehsData?.material_code?.hwm_rules_category_no}
                  </TableCell>
                  <TableCell>{ehsData?.from_location}</TableCell>
                  <TableCell>{ehsData?.to_location}</TableCell>
                  <TableCell>{ehsData?.reason_for_generation}</TableCell>
                  <TableCell>{ehsData?.assign_user?.ehs_form_role}</TableCell>
                  <TableCell>
                    {ehsData?.assign_user?.first_name}{" "}
                    {ehsData?.assign_user?.last_name}
                  </TableCell>

                  <TableCell>
                    <RemoveRedEyeIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => handleApprove(ehsData.id)}
                    />
                  </TableCell>
                </TableRow>
              ) : params.historyId ? (
                <TableRow>
                  <TableCell>
                    <div
                      className={`${
                        historyEhsData?.status === 1
                          ? "pending-status"
                          : historyEhsData?.status === 2
                          ? "approved-status"
                          : "rejected-status"
                      }`}
                    />
                  </TableCell>
                  <TableCell>{historyEhsData?.batch_number}</TableCell>
                  <TableCell>
                    {" "}
                    {dayjs(historyEhsData?.created_at).format(
                      "YYYY-MM-DD"
                    )}{" "}
                  </TableCell>
                  <TableCell>
                    {historyEhsData?.created_by?.first_name}{" "}
                    {historyEhsData?.created_by?.last_name}
                  </TableCell>
                  <TableCell>
                    {historyEhsData?.material_code?.material_code}
                  </TableCell>
                  <TableCell>
                    {historyEhsData?.material_code.material_description}
                  </TableCell>
                  <TableCell>{historyEhsData?.quantity}</TableCell>
                  <TableCell>{historyEhsData?.material_code?.unit}</TableCell>
                  <TableCell>
                    {historyEhsData?.material_code?.hwm_rules_category_no}
                  </TableCell>
                  <TableCell>{historyEhsData?.from_location}</TableCell>
                  <TableCell>{historyEhsData?.to_location}</TableCell>
                  <TableCell>{historyEhsData?.reason_for_generation}</TableCell>
                  <TableCell>
                    {historyEhsData?.assign_user?.ehs_form_role}
                  </TableCell>
                  <TableCell>
                    {historyEhsData?.assign_user?.first_name}
                  </TableCell>
                  <TableCell>
                    <RemoveRedEyeIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => handleApprove(historyEhsData.id)}
                    />
                  </TableCell>
                </TableRow>
              ) : (
                getEHSFormData?.map((e) => {
                  return (
                    <TableRow>
                      <TableCell>
                        <div
                          className={`${
                            e.status === 1
                              ? "pending-status"
                              : e.status === 2
                              ? "approved-status"
                              : "rejected-status"
                          }`}
                        />
                      </TableCell>
                      <TableCell>{e.batch_number}</TableCell>
                      <TableCell>
                        {" "}
                        {dayjs(e.created_at).format("YYYY-MM-DD")}{" "}
                      </TableCell>
                      <TableCell>
                        {e.created_by.first_name} {e.created_by.last_name}
                      </TableCell>
                      <TableCell>{e.material_code.material_code}</TableCell>
                      <TableCell>
                        {e.material_code.material_description}
                      </TableCell>
                      <TableCell>{e.quantity}</TableCell>
                      <TableCell>{e.material_code.unit}</TableCell>
                      <TableCell>
                        {e.material_code.hwm_rules_category_no}
                      </TableCell>
                      <TableCell>{e.from_location}</TableCell>
                      <TableCell>{e.to_location}</TableCell>
                      <TableCell>{e.reason_for_generation}</TableCell>
                      <TableCell>{e?.assign_user?.ehs_form_role}</TableCell>
                      <TableCell>
                        {e?.assign_user?.first_name} {e?.assign_user?.last_name}
                      </TableCell>

                      <TableCell>
                        <RemoveRedEyeIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => handleApprove(e.id)}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </div>
        {!params.id && !params.historyId ? (
          <TablePagination
            className="table-pagination"
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={formCount ? formCount.count : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : (
          ""
        )}

        {addItemModal && (
          <div className="box-container">
            <Box className="add-material-box">
              <div className="p-2">
                <div className="add-material-div">
                  <h6>New Waste Material Posting</h6>
                  <CloseIcon
                    style={{
                      cursor: "pointer",
                      fontSize: "31px",
                      marginTop: "-5px",
                    }}
                    onClick={() => setAddItemModal(false)}
                  />
                </div>
                <div>
                  {/* <div className="dropdown-area pt-2">
                    <h6>
                      Material Code <span style={{ color: "#E31E24" }}>*</span>
                    </h6>
                    <select
                      className="materialcode-dropdown"
                      value={addEHSFormData.material_code}
                      name="material_code"
                      onChange={handleChangeEHS}
                    >
                      <option value="none" selected disabled hidden>
                        Select Material Code
                      </option>
                      {materialCodeData?.map((e) => {
                        return <option value={e.id}>{e.material_code}</option>;
                      })}
                    </select>
                  </div> */}
                  <div className="dropdown-area pt-2">
                    <h6>
                      Material Code <span style={{ color: "#E31E24" }}>*</span>
                    </h6>
                    <select
                      className="materialcode-dropdown"
                      value={addEHSFormData?.material_code}
                      name="material_code"
                      onChange={handleChangeEHS}
                    >
                      <option value="none" selected hidden>
                        Select Material Code
                      </option>

                      {materialCodeData?.map((e) => {
                        return <option value={e.id}>{e.material_code}</option>;
                      })}
                    </select>
                  </div>
                  <div className="dropdown-area pt-2">
                    <h6>
                      Quantity <span style={{ color: "#E31E24" }}>*</span>
                    </h6>
                    <input
                      type="number"
                      className="materialcode-dropdown"
                      value={addEHSFormData?.quantity}
                      name="quantity"
                      onChange={handleChangeEHSQuantity}
                      step="1"
                      pattern="\d+"
                    ></input>
                  </div>
                  <div className="dropdown-area pt-2">
                    <h6>
                      Plant <span style={{ color: "#E31E24" }}>*</span>
                    </h6>
                    <input
                      className="materialcode-dropdown"
                      value={addEHSFormData?.plant}
                      name="plant"
                      onChange={handleChangeEHS}
                    ></input>
                  </div>
                  <div className="dropdown-area pt-2">
                    <h6>
                      Reason For Generation{" "}
                      <span style={{ color: "#E31E24" }}>*</span>
                    </h6>
                    <input
                      className="materialcode-dropdown"
                      value={addEHSFormData?.reason_for_generation}
                      name="reason_for_generation"
                      onChange={handleChangeEHS}
                    ></input>
                  </div>
                  <div className="dropdown-area pt-2">
                    <h6>
                      From Location <span style={{ color: "#E31E24" }}>*</span>
                    </h6>
                    <select
                      className="materialcode-dropdown"
                      value={addEHSFormData?.from_location}
                      name="from_location"
                      onChange={handleChangeEHS}
                    >
                      <option value="none" selected hidden>
                        Select Location Code
                      </option>
                      {locationData?.map((e) => {
                        return <option value={e.id}>{e.location_code}</option>;
                      })}
                    </select>
                  </div>
                  <div className="dropdown-area pt-2">
                    <h6>
                      Container Type <span style={{ color: "#E31E24" }}>*</span>
                    </h6>
                    <select
                      className="materialcode-dropdown"
                      value={addEHSFormData?.container_type}
                      name="container_type"
                      onChange={handleChangeEHS}
                    >
                      <option value="none" selected hidden>
                        Select Container Type
                      </option>
                      {containerData?.map((e) => {
                        return <option value={e.id}>{e.container_code}</option>;
                      })}
                    </select>
                  </div>
                </div>
                <div className="pt-3 btn-div">
                  <button className="reset-btn" onClick={handleReset}>
                    Reset
                  </button>
                  <button className="submit-btn" onClick={handleBatch}>
                    Submit
                  </button>
                </div>
              </div>
            </Box>
          </div>
        )}
        {wasteDisposalModal && (
          <div className="box-container">
            <Box className="disposalBox">
              <div className="disposalTitle">
                <div className="title-width">
                  <h5>FOR EHS: WASTE DISPOSAL MEMO</h5>
                </div>
                <div className="closeIcon-width">
                  <img
                    src={images.downloadpdf}
                    onClick={handleEHSPdf}
                    style={{ cursor: "pointer" }}
                  />
                  <CloseIcon
                    style={{
                      fontSize: "30px",
                      marginTop: "2px",
                      cursor: "pointer",
                    }}
                    onClick={() => setWasteDisposalModal(false)}
                  />
                </div>
              </div>
              <div className="pt-4">
                <h6 className="disposal-desc">
                  [ER] [EMS] [17] - APPROVED WASTE DISPOSAL MEMO
                </h6>
              </div>
              <div className="pt-4">
                <div className="disposal-detail">
                  <div>
                    <h6>
                      Request Time:{" "}
                      {dayjs(getApprovalHistoryData.created_at).format(
                        "HH:mm:ss"
                      )}
                    </h6>
                    <h6>Requested By: {getApprovalHistoryData.requested_by}</h6>
                    <h6>Plant Code: {getApprovalHistoryData.plant_code}</h6>
                  </div>
                  <div>
                    <h6>
                      Request Date:{" "}
                      {dayjs(getApprovalHistoryData.created_at).format(
                        "YYYY-MM-DD"
                      )}{" "}
                    </h6>
                    <h6>WDM Number: {getApprovalHistoryData.wdm_number}</h6>
                    <h6>Plant: {getApprovalHistoryData.plant}</h6>
                  </div>
                </div>
              </div>
              <div className="pt-4 table-responsive">
                <Table
                  className="Ehs-form-table"
                  style={{ borderRadius: "4px 4px 0px 0px" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>S.No</TableCell>
                      <TableCell>Batch Number</TableCell>
                      <TableCell>Waste Material</TableCell>
                      <TableCell>Waste Material Descriptions</TableCell>
                      <TableCell>HWM Rules Category No</TableCell>
                      <TableCell>Quantity of Waste</TableCell>
                      <TableCell>UCM</TableCell>
                      <TableCell>From Location</TableCell>
                      <TableCell>To Location</TableCell>
                      <TableCell>Reason For Generation</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow style={{ background: "#F4F7FE" }}>
                      <TableCell>1</TableCell>
                      <TableCell>
                        {getApprovalHistoryData.batch_number
                          ? getApprovalHistoryData.batch_number
                          : "-"}
                      </TableCell>
                      <TableCell>
                        {getApprovalHistoryData?.material_code?.material_code}
                      </TableCell>
                      <TableCell>
                        {
                          getApprovalHistoryData?.material_code
                            ?.material_description
                        }
                      </TableCell>
                      <TableCell>
                        {
                          getApprovalHistoryData?.material_code
                            ?.hwm_rules_category_no
                        }
                      </TableCell>
                      <TableCell>
                        {getApprovalHistoryData.quantity
                          ? getApprovalHistoryData.quantity
                          : "-"}
                      </TableCell>
                      <TableCell>
                        {getApprovalHistoryData?.material_code?.unit}
                      </TableCell>
                      <TableCell>
                        {getApprovalHistoryData.from_location
                          ? getApprovalHistoryData.from_location
                          : "-"}
                      </TableCell>
                      <TableCell>
                        {getApprovalHistoryData.to_location
                          ? getApprovalHistoryData.to_location
                          : "-"}
                      </TableCell>
                      <TableCell>
                        {getApprovalHistoryData.reason_for_generation
                          ? getApprovalHistoryData.reason_for_generation
                          : "-"}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
              <div className="pt-5">
                <h6 className="approved-title">APPROVED</h6>
                <div
                  className="pt-2 table-responsive"
                  style={{ height: "170px" }}
                >
                  <Table
                    className="Ehs-form-table"
                    style={{ borderRadius: "4px 4px 0px 0px" }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Designation</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Date and Time</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Remarks</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {getApprovalHistoryData.approval_history &&
                      getApprovalHistoryData.approval_history.length > 0
                        ? getApprovalHistoryData.approval_history.map(
                            (history, index) => {
                              return (
                                <TableRow>
                                  <TableCell>{history.designation}</TableCell>
                                  <TableCell>{history.created_by}</TableCell>
                                  <TableCell>
                                    {" "}
                                    {dayjs(history.created_at).format(
                                      "YYYY-MM-DD"
                                    )}{" "}
                                  </TableCell>
                                  <TableCell>{history.status}</TableCell>
                                  <TableCell>{history.remark}</TableCell>
                                </TableRow>
                              );
                            }
                          )
                        : ""}
                    </TableBody>
                  </Table>
                </div>
              </div>
              <div className="pt-3 pb-3 btn-div">
                <button
                  // className="reset-btn reset-close-btn"
                  className={
                    getApprovalHistoryData.is_form_submitted == true ||
                    getApprovalHistoryData.status == 3 ||
                    (getApprovalHistoryData.is_form_submitted == true &&
                      getApprovalHistoryData.status == 3) ||
                    getApprovalHistoryData.is_form_submitted == true ||
                    getApprovalHistoryData.status == 2 ||
                    (getApprovalHistoryData.is_form_submitted == true &&
                      getApprovalHistoryData.status == 2)
                      ? "reset-btn reset-close-btn-disable"
                      : "reset-btn reset-close-btn"
                  }
                  onClick={handleRejectClick}
                >
                  Reject
                </button>
                <button
                  className={
                    getApprovalHistoryData.is_form_submitted == true ||
                    getApprovalHistoryData.status == 3 ||
                    (getApprovalHistoryData.is_form_submitted == true &&
                      getApprovalHistoryData.status == 3) ||
                    getApprovalHistoryData.is_form_submitted == true ||
                    getApprovalHistoryData.status == 2 ||
                    (getApprovalHistoryData.is_form_submitted == true &&
                      getApprovalHistoryData.status == 2)
                      ? "submit-btn-disable"
                      : "submit-btn"
                  }
                  onClick={handleClick}
                >
                  Approve
                </button>
              </div>
            </Box>
          </div>
        )}
        {approvedPopup && (
          <div className="box-container">
            <Box className="approvedpopup">
              <div className="p-2">
                <div className="add-material-div">
                  <h6>Approve Form?</h6>
                  <CloseIcon
                    style={{
                      cursor: "pointer",
                      fontSize: "31px",
                      marginTop: "-5px",
                    }}
                    onClick={() => setApprovedPopup(false)}
                  />
                </div>
                <div className="pt-3 approval-desc">
                  <h6>
                    Are you sure for Approve this form? Select Next Authority
                    for Approval
                  </h6>
                </div>

                {getApprovalHistoryData?.assign_user?.ehs_form_role ==
                "Final EHS Manager" ? (
                  <div className="dropdown-area pt-2">
                    <h6>
                      To Location <span style={{ color: "#E31E24" }}>*</span>
                    </h6>
                    <select
                      className="materialcode-dropdown"
                      value={toLocation}
                      name="toLocation"
                      onChange={(e) => setToLocation(e.target.value)}
                    >
                      <option value="none" selected hidden>
                        Select Location Code
                      </option>
                      {locationData?.map((e) => {
                        return <option value={e.id}>{e.location_code}</option>;
                      })}
                    </select>
                  </div>
                ) : (
                  <div className="dropdown-area pt-3">
                    <h6>
                      Select Authority{" "}
                      <span style={{ color: "#E31E24" }}>*</span>
                    </h6>
                    <select
                      className="materialcode-dropdown"
                      value={approveFormData.authority}
                      name="authority"
                      onChange={handleChangeApprove}
                    >
                      <option value="none" selected hidden>
                        Select Authority
                      </option>
                      {getAuthority?.map((e) => {
                        return (
                          <option value={e.id}>
                            {e.first_name} {e.last_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                )}
                <div className="dropdown-area pt-2">
                  <h6>Remarks for Approval</h6>
                  <textarea
                    rows={5}
                    className="approve-textarea"
                    value={approveFormData.remark}
                    name="remark"
                    onChange={handleChangeApprove}
                  ></textarea>
                </div>
                <div className="pt-3 btn-div">
                  <button className="reset-btn" onClick={handleCancelClick}>
                    Cancel
                  </button>
                  <button className="submit-btn" onClick={handleClickApprove}>
                    Approve
                  </button>
                </div>
              </div>
            </Box>
          </div>
        )}
        {rejectedPopup && (
          <div className="box-container">
            <Box className="approvedpopup">
              <div className="p-2">
                <div className="add-material-div">
                  <h6>Reject Form?</h6>
                  <CloseIcon
                    style={{
                      cursor: "pointer",
                      fontSize: "31px",
                      marginTop: "-5px",
                    }}
                    onClick={() => setRejectedPopup(false)}
                  />
                </div>
                <div className="pt-3 approval-desc">
                  <h6>Are you sure to Reject this Form? Provide the remarks</h6>
                </div>

                <div className="dropdown-area pt-3">
                  <h6>Remarks for rejection</h6>
                  <textarea
                    rows={8}
                    className="approve-textarea"
                    value={rejectRemark}
                    onChange={(e) => setRejectRemark(e.target.value)}
                  ></textarea>
                </div>
                <div className="pt-3 btn-div">
                  <button
                    className="reset-btn reset-close-btn"
                    onClick={handleRejectCancelClick}
                  >
                    Cancel
                  </button>
                  <button
                    className="submit-btn reject-btn"
                    onClick={handleClickReject}
                  >
                    Reject
                  </button>
                </div>
              </div>
            </Box>
          </div>
        )}
        {deleteExcelPopup && (
          <div className="box-container">
            <Card className="deleteConfirmation-Modal">
              <Typography
                variant="h6"
                gutterBottom
                className="deleteConfirmation-title"
              >
                The requested file will be delivered to your email box.
              </Typography>
              <div className="pt-3 btn-div">
                <button className="submit-btn" onClick={handleSubmitExcel}>
                  Ok
                </button>
                <button
                  className="reset-btn"
                  onClick={() => setDeleteExcelPopup(false)}
                >
                  Cancel
                </button>
              </div>
            </Card>
          </div>
        )}
        {deleteHistoryExcelPopup && (
          <div className="box-container">
            <Card className="deleteConfirmation-Modal">
              <Typography
                variant="h6"
                gutterBottom
                className="deleteConfirmation-title"
              >
                The requested file will be delivered to your email box.
              </Typography>
              <div className="pt-3 btn-div">
                <button
                  className="submit-btn"
                  onClick={handleSubmitHistoryExcel}
                >
                  Ok
                </button>
                <button
                  className="reset-btn"
                  onClick={() => setDeleteHistoryExcelPopup(false)}
                >
                  Cancel
                </button>
              </div>
            </Card>
          </div>
        )}
      </div>
    </>
  );
};
export default EhsForm;
