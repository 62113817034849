import React, { useEffect } from 'react';
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { images } from "../../../../config/images";
import { useNavigate, useParams } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import { useState } from 'react';
import { toast } from "react-toastify";
import { createReportConfigure, updateReportConfigure, getDepartmentForManagement, getDepartmentRole } from '../../services';
import { useSelector } from 'react-redux';
import { UserAccessModule } from '../../../../components/UserAccess/UserAccessmodule';

const ReportManagementUserList = (
    {
        activeClass,
        selectEmailSchedule,
        selectDateFormat,
        selectTimeFormat,
        selectUserId,
        setSelectUserId,
        departmentId,
        setDepartmentId,
        selectedRoles,
        setSelectedRoles,
        selectReportType,
        selectUserListObj,
        setSelectUserListObj,
        isConfigure,
        companyId
    }) => {

    const [departmentList, setDepartmentList] = useState([]);
    const [useDetailData, setUserDetailData] = useState([]);

    const navigate = useNavigate();
    const param = useParams();

    const { user_access, is_admin } = useSelector((state) => state.userState);

    const notifyError = (message) =>
        toast.error(message, {
            theme: "colored",
            toastClassName: "custom-toast",
        });


    console.log("selectUserListObjselectUserListObj", selectUserListObj, isConfigure);

    const notify = (message) =>
        toast.error(message, {
            theme: "colored",
            position: toast.POSITION?.TOP_RIGHT,
            toastClassName: "custom-toast",
        });
    
    const label = { inputProps: { "aria-label": "Checkbox demo" } };

    const handelSelectUserList = (id) => {
        console.log("kpkpkp", id);
        setSelectUserListObj((prevState) => {
            const existingObjIndex = prevState.findIndex(obj => obj.role === id);

            if (existingObjIndex !== -1) {
                // If the role already exists, remove it from the state
                const newState = [...prevState];
                newState.splice(existingObjIndex, 1);
                return newState;
            } else {
                // Check if an object with the same departmentId already exists
                const departmentIndex = prevState.findIndex(obj => obj.departmentId === departmentId);

                const newState = [...prevState];
                if (departmentIndex !== -1) {
                    // Remove the existing object with the same departmentId
                    newState.splice(departmentIndex, 1);
                }

                // Create a new object and add it to the state
                const newObj = {
                    departmentId: departmentId,
                    role: id,
                    user: []
                };

                return [...newState, newObj];
            }
        });
    };


  

    // handle User List 
const handleSelectUserListId = (id) => {
  let updatedUserListObj = [...selectUserListObj];
  const index = updatedUserListObj.findIndex((item) => item.departmentId === departmentId);

  if (index !== -1) {
    const item = updatedUserListObj[index];
    const userExists = item.user.some((userObj) => userObj === id);

    if (userExists) {
      const updatedUserArray = item.user.filter((userObj) => userObj !== id);
      updatedUserListObj[index] = { ...item, user: updatedUserArray };
    } else {
      const newUser = id;
      const updatedUserArray = [...item.user, newUser];
      updatedUserListObj[index] = { ...item, user: updatedUserArray };
        updatedUserListObj = updatedUserListObj.filter((f) => f.departmentId == departmentId)
    }
  }

  setSelectUserListObj(updatedUserListObj);
};


    // handle change CheckBox
    const handleCheckboxChange = (roleId) => {
        if (selectedRoles === roleId) {
            // Role ID already selected, deselect it
            setSelectedRoles("");
            setUserDetailData([]);
        } else {
            // Role ID not selected, select it
            setSelectedRoles(roleId);
            setSelectUserId([]);
            setUserDetailData([]);
        }
    };

    // handleChange Department
    const handleChangeDepartment = (id) => {
        setDepartmentId(id);
        setUserDetailData([])
    }

    useEffect(() => {
        getDepartmentList();
    }, []);

    //Get department list
    const getDepartmentList = async () => {
        const paramReq = {
            company: companyId
        }
        try {
            const response = await getDepartmentForManagement(paramReq);
            console.log("departmentList", response);
            if (response.status == 200 || response.status == 201) {
                setDepartmentList(response.data.payload);
            }
        } catch (error) {
            notify(error.response.data.message);
        }
    };

    // user Detail
    useEffect(() => {
        console.log("kp", selectUserListObj.find((f) => f.departmentId == departmentId)?.role);
        if (selectUserListObj.find((f) => f.departmentId == departmentId)?.role) {
            handleUserDetailData();
        }
    }, [selectUserListObj, departmentId, activeClass]);

    const handleUserDetailData = async () => {
        const params = {
            department: departmentId,
            role: selectUserListObj.find((f) => f.departmentId == departmentId)?.role,
        };
        try {
            const resp = await getDepartmentRole(params);
            if (resp.status == 200 || resp.status == 201) {
                setUserDetailData(resp.data.payload);
            }
        } catch (error) {
            console.log("handlesubmit", error);
        }
    };

    // create User Configure
    
    const submitUserConfigure = async () => {

        if (!is_admin) {
            if (UserAccessModule(user_access, "EHS Modules", "Report Management")?.is_editor == false) {
                notifyError("You don't have access");
                return true;
            }
        }


        const findUsers = selectUserListObj.filter(obj => obj.user.length > 0);
        try {
            const paramReq = {
                report: param.reportId,
                report_type: selectReportType,
                department: findUsers[0].departmentId,
                role: findUsers[0].role,
                email_schedule: selectEmailSchedule,
                configuration_date: selectDateFormat,
                configuration_time: selectTimeFormat,
                user: findUsers[0].user
            };

            const resp = await createReportConfigure(paramReq);
            if (resp.status == 200 || resp.status == 201) {
                navigate("/report-management")
            }
            // Handle the response here
        } catch (error) {
            // Handle the error here
            console.error('An error occurred:', error);
        }
    };

    const updateReportConfigureApi = async () => {

        if (!is_admin) {
            if (UserAccessModule(user_access, "EHS Modules", "Report Management")?.is_editor == false) {
                notifyError("You don't have access");
                return true;
            }
        }

        
        const findUsers = selectUserListObj.filter(obj => obj.user.length > 0);
        try {
            const paramReq = {
                report: param.reportId,
                report_type: selectReportType,
                department: findUsers[0].departmentId,
                role: findUsers[0].role,
                email_schedule: selectEmailSchedule,
                configuration_date: selectDateFormat,
                configuration_time: selectTimeFormat,
                user: findUsers[0].user
            };
            const resp = await updateReportConfigure(param.reportId ,paramReq);
            if (resp.status == 200 || resp.status == 201) {
                navigate("/report-management")
            }
            // Handle the response here
        } catch (error) {
            // Handle the error here
            console.error('An error occurred:', error);
        }
    }


    return (
        <>
            {/*  */}
            <Grid container style={{ paddingTop: "20px" }}>
                <Grid item xs={12}>
                    <Card className="manageDepartment-card">
                        <Grid
                            container
                            style={{
                                paddingLeft: "30px",
                                paddingRight: "30px",
                                paddingTop: "30px",
                                paddingBottom: "30px",
                            }}
                        >
                            <Grid item sm={12} md={12} lg={4} xl={4}>
                                <Typography variant="subtitle2" gutterBottom>
                                    <b>Select Department</b>
                                </Typography>
                                <div className="manageDepartment-wrap">
                                    {departmentList.length ? departmentList.map((e, i) => {
                                        return (
                                            <div className="qqq">
                                                <div
                                                    className="manageDepartment-wrap mb-2"
                                                    onClick={() => handleChangeDepartment(e.id)}
                                                >
                                                    <div className={`manageDepartment-fieldWrap ${selectUserListObj.find((f)=> f.departmentId == e.id)?.user.length ?  "alarm-department-highlight" : ""}`}>
                                                        {departmentId == e.id && (
                                                            <img
                                                                src={images.activeDeviceimg}
                                                                alt=""
                                                                className="activeDepartmentimg"
                                                            />
                                                        )}
                                                        <div className="manageDepartment-field">
                                                            <Typography
                                                                variant="body2"
                                                                gutterBottom
                                                                style={{ margin: "0" }}
                                                            >
                                                                 {e.department_name}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }) : []}
                                </div>
                            </Grid>
                            <Grid item sm={12} md={12} lg={4} xl={4}>
                                <Typography variant="subtitle2" gutterBottom>
                                    <b>Roles</b>
                                </Typography>
                                <div className="alarmMangementCard-useListCard1">
                                    {departmentList.length
                                        ? departmentList.map((e, i) => {
                                            if (e.id == departmentId) {
                                                return e.company_roles.map((e1) => {
                                                    return (
                                                        <div className="alarmManagementCard-Roleswrap">
                                                            <div>
                                                                <Checkbox
                                                                    {...label}
                                                                    defaultChecked={false}
                                                                    style={{
                                                                        color: "#0E8951",
                                                                    }}
                                                                    checked={selectUserListObj.find((f) => f.role == e1.id) ? true : false}
                                                                    onClick={() => handelSelectUserList(e1.id)}
                                                                />
                                                            </div>
                                                            <div>
                                                                <Typography
                                                                    variant="body2"
                                                                    gutterBottom
                                                                    className="m-0"
                                                                >
                                                                    {e1.role_name}
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                    );
                                                });
                                            }
                                        })
                                        : []}
                                </div>
                            </Grid>
                            <Grid item sm={12} md={12} lg={4} xl={4}>
                                <Typography variant="subtitle2" gutterBottom>
                                    <b>User Details</b>
                                </Typography>
                                <div className="alarmMangementCard-useListCard1">
                                    <Typography variant="body2" gutterBottom className='m-0' style={{padding:'14px'}}>
                                        User Name
                                    </Typography>
                                    {useDetailData.length
                                        ? useDetailData.map((row, index) => {
                                            return (
                                                <div className='alarmManagementCard-Roleswrap'>
                                                    <div>
                                                        <Checkbox
                                                            {...label}
                                                            style={{
                                                                color: "#0E8951",
                                                            }}
                                                            checked={selectUserListObj.find((f) => f.departmentId == departmentId)?.user.includes(row.id)}
                                                            onClick={() => handleSelectUserListId(row.id)}
                                                        />
                                                    </div>
                                                    <div>
                                                        <Typography variant="body2" gutterBottom className='m-0'>
                                                            {row.first_name} {row.last_name}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            )
                                        }) : []}
                                </div>
                                {
                                    isConfigure ?
                                        <button
                                            className={selectUserListObj.filter(obj => obj.user.length > 0)[0]?.user.length ? "Plantmanagemet submit" : "alarmManagement-submit-disable"}
                                            onClick={() => updateReportConfigureApi()}
                                        >
                                            Update
                                        </button>
                                        :
                                        <button
                                            className={selectUserListObj.filter(obj => obj.user.length > 0)[0]?.user.length ? "Plantmanagemet submit" : "alarmManagement-submit-disable"}
                                            onClick={() => submitUserConfigure()}
                                        >
                                            Submit
                                        </button>
                                }

                                
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid></Grid>
            </Grid>
        </>
    )
}
export default ReportManagementUserList;