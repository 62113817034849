import { request } from "../../api/api";

export const getNotification = (filterData,params)=>{
    return request.get(`/ehs_form/getNotificationDetails/?created_at__date=${filterData.created_at__date}&ehs_form__batch_number=${filterData.ehs_form__batch_number}&ehs_form__material_code__material_description=${filterData.ehs_form__material_code__material_description}&assign_by__name=${filterData.assign_by__name}&assign_to__name=${filterData.assign_to__name}&page=${params.page + 1}&page_size=${params.rowsPerPage}&start_datetime=${params.start_datetime}&end_datetime=${params.end_datetime}`)
}

export const getHistory =(filterData,params)=>{
 return request.get(`/ehs_form/getNotificationHistoryDetails/?created_at__date=${filterData.created_at__date}&search=${filterData.search}&ehs_form__batch_number=${filterData.ehs_form__batch_number}&ehs_form__material_code__material_description=${filterData.ehs_form__material_code__material_description}&modified_at__date=${filterData.modified_at__date}&page=${params.page + 1}&page_size=${params.rowsPerPage}&start_datetime=${params.start_datetime}&end_datetime=${params.end_datetime}`)
}

export const deleteNotification = (id)=>{
  return request.put(`/ehs_form/deleteNotification/${id}/`)
}

export const getNotificationHeader = () =>{
  return request.get(`/ehs_form/getNotificationDetails/`)
}