import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Grid, TextField } from "@mui/material";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { images } from "../../../../config/images";
import {
  getDepartment,
  addDepartment,
  deleteDepartment,
  editDepartManage,
  addRoleAPI,
  deleteRole,
  editRoleManage,
  exportDepartmentExcel,
  getCompanyByDetails,
} from "../../services";
import "./ManageDepartment.css";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../../components/Loader/Loader";
import { ValidatorForm } from "react-material-ui-form-validator";
import Textshimmer from "../../../../components/Shimmer/TextShimmer/TextShimmer";
import { UserAccessModule } from "../../../../components/UserAccess/UserAccessmodule";

function ManageDepartment() {
  const navigate = useNavigate();
  const [openAddDepartmentModal, setOpenAddDepartmentModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [departmentList, setdepartmentList] = useState([]);
  const [addDepartMent, setAddDepartMent] = useState({ department_name: "" });
  const [departmentId, setDepartmentId] = useState("");
  const [openEditDepartMent, setOpenEditDepartMent] = useState(false);
  const [openEditRoles, setOpenEditRoles] = useState(false);
  const [editDepartment, setEditDepartment] = useState({ department_name: "" });
  const [editRoles, setEditRoles] = useState({ role_name: "" });
  const [addRole, setAddRole] = useState({ role_name: "" });
  const [deleteRoleModal, setDeleteRoleModal] = useState(false);
  const [roleId, setRoleId] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [manageDepartmentId, setManageDepartmentId] = useState("");
  const [isEmpatyDevice, setEmpatyDevice] = useState(false);
  const [companyIdName, setCompanyName] = useState("");
  const [isShimmerDepartment, setisShimmerDepartment] = useState(false);
  const [excelPopup, setExcelPopup] = useState(false);

  const { companyName, companyId, user_access, is_admin } = useSelector(
    (state) => state.userState
  );

  const notify = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION?.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const notifySuccess = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION?.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const handleOpenaddDepartmentModal = () => {
    if (
      openAddDepartmentModal ||
      openDeleteModal ||
      openEditDepartMent ||
      openEditRoles ||
      deleteRoleModal
    ) {
      return true;
    }

    if (!is_admin) {
      const userAccess = UserAccessModule(
        user_access,
        "EHS Modules",
        "Role & Access Management"
      );

      if (userAccess == null || userAccess.is_editor === false) {
        notify("You don't have access");
        return true;
      }
    }

    setOpenAddDepartmentModal(!openAddDepartmentModal);
    setOpenDeleteModal(false);
    setOpenEditDepartMent(false);
    setOpenEditRoles(false);
    setDeleteRoleModal(false);
  };

  const handleOpenaddDepartmentCloseModal = () => {
    setOpenAddDepartmentModal(!openAddDepartmentModal);
  };

  const param = useParams();
  const location = useLocation();
  console.log("location", location);
  console.log("param", param);

  useEffect(() => {
    // Find the specific plant that matches the plantId
    const matchedPlant = departmentList.find((e) => e.id === departmentId);

    if (matchedPlant) {
      // If the matchedPlant exists, check if its device array is empty
      setEmpatyDevice(matchedPlant.company_roles.length === 0);
    }

    if (!departmentList.length) {
      setEmpatyDevice(true);
    }
  }, [departmentList, departmentId]);

  // Delete Confirmation Modal
  const handleConfirmationModal = async (i) => {
    if (
      openAddDepartmentModal ||
      openDeleteModal ||
      openEditDepartMent ||
      openEditRoles ||
      deleteRoleModal
    ) {
      return true;
    }

    if (!is_admin) {
      const userAccess = UserAccessModule(
        user_access,
        "EHS Modules",
        "Role & Access Management"
      );

      if (userAccess == null || userAccess.is_editor === false) {
        notify("You don't have access");
        return true;
      }
    }

    setOpenAddDepartmentModal(false);
    setOpenEditDepartMent(false);
    setOpenDeleteModal(!openDeleteModal);
    setDepartmentId(departmentList[i].id);
  };

  const handleConfirmationCloseModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  const handleConfirmModal = async (e) => {
    if (
      openAddDepartmentModal ||
      openDeleteModal ||
      openEditDepartMent ||
      openEditRoles ||
      deleteRoleModal
    ) {
      return true;
    }

    if (!is_admin) {
      const userAccess = UserAccessModule(
        user_access,
        "EHS Modules",
        "Role & Access Management"
      );

      if (userAccess == null || userAccess.is_editor === false) {
        notify("You don't have access");
        return true;
      }
    }
    setOpenEditRoles(false);
    setDeleteRoleModal(!deleteRoleModal);
    setRoleId(e.id);
  };

  const handleConfirmModalClose = async () => {
    setDeleteRoleModal(!deleteRoleModal);
    // setRoleId(e.id);
  };

  const handleSubmit = async (i) => {
    const resp = await deleteDepartment(departmentId);
    if (resp.data.success == true) {
      getDepartmentList();
      setOpenDeleteModal(false);
      console.log("successfully data");
    }
  };

  const handleDeleteModal = async (i) => {
    const resp = await deleteRole(roleId);
    if (resp.data.success == true) {
      getDepartmentList();
      setDeleteRoleModal(false);
      console.log("successfully data");
    }
  };

  // // company deatils
  // const getCompanyByDetailsApi = async () => {
  //   const paramReq = {
  //     company_id: param.companyId,
  //   };
  //   const resp = await getCompanyByDetails(paramReq);
  //   console.log("resprespresp", resp);
  //   if (resp.status == 200) {
  //     setCompanyName(resp.data.payload.company_name);
  //   }
  // };

  // useEffect(() => {
  //   if (param.companyId) {
  //     getCompanyByDetailsApi();
  //   }
  // }, []);

  //Get department list
  const getDepartmentList = async () => {
    setisShimmerDepartment(true);
    const paramReq = {
      company: companyId,
    };
    try {
      const response = await getDepartment(paramReq);

      if (response.status == 200 || response.status == 201) {
        setTimeout(() => {
          setisShimmerDepartment(false);
        }, 1000);
        setdepartmentList(response.data.payload);
        setManageDepartmentId(response.data.payload.map((e) => e.id));
        if (!departmentId) {
          setDepartmentId(response.data.payload[0].id);
        }
      }
    } catch (error) {
      setisShimmerDepartment(false);
      console.log("Error from getDepartmentList", error);
      // notify(error.response.data.message);
    }
  };

  //Add Department
  const handleChangeDepartment = (e) => {
    setAddDepartMent({ ...addDepartMent, [e.target.name]: e.target.value });
    // console.log("addDepartment",addDepartment)
  };

  const handleChangeRole = (e) => {
    if (
      openAddDepartmentModal ||
      openDeleteModal ||
      openEditDepartMent ||
      openEditRoles ||
      deleteRoleModal
    ) {
      return true;
    }
    setAddRole({ ...addRole, [e.target.name]: e.target.value });
  };
  const handleChangeEditDepartment = (e) => {
    setEditDepartment({ ...editDepartment, [e.target.name]: e.target.value });
  };

  const handleChangeEditRole = (e) => {
    setEditRoles({ ...editRoles, [e.target.name]: e.target.value });
  };
  const submitAddDepartment = async () => {
    setLoading(true);
    const params = {
      company: companyId,
      department_name: addDepartMent.department_name,
    };
    try {
      const response = await addDepartment(params);
      if (response.data.success == true) {
        setAddDepartMent({ department_name: "" });
        setLoading(false);
        getDepartmentList();
        setOpenAddDepartmentModal(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Please Enter Department", {
        theme: "colored",
        position: toast.POSITION.TOP_RIGHT,
        toastClassName: "custom-toast",
      });
    }
    // catch (error) {
    //   if (error.response && error.response.data && error.response.data.message) {
    //     notify(error.response.data.message);
    //     console.log('This field may not be blank', error.response.data);
    //   } else {
    //     notify('This field may not be blank');
    //     console.log('This field may not be blank');
    //   }
    // }
  };

  const handleEditModal = (e) => {
    if (
      openAddDepartmentModal ||
      openDeleteModal ||
      openEditDepartMent ||
      openEditRoles ||
      deleteRoleModal
    ) {
      return true;
    }

    if (!is_admin) {
      const userAccess = UserAccessModule(
        user_access,
        "EHS Modules",
        "Role & Access Management"
      );

      if (userAccess == null || userAccess.is_editor === false) {
        notify("You don't have access");
        return true;
      }
    }
    setOpenAddDepartmentModal(false);
    setOpenDeleteModal(false);
    setOpenEditDepartMent(true);
    setEditDepartment({ department_name: e.department_name });
    setDepartmentId(e.id);
  };

  const handleCloseClick = () => {
    setOpenEditDepartMent(false);
    // setEditDepartment({ department_name: e.department_name });
    // setDepartmentId(e.id);
  };

  const handleCancleClick = (e) => {
    setOpenEditRoles(false);
    setEditRoles({ role_name: e.role_name });
    setRoleId(e.id);
  };
  const handleEditClick = async () => {
    if (editDepartment.department_name === "") {
      notify("Please Enter Department Name");
      return;
    }

    const params = {
      company: companyId,
      department_name: editDepartment.department_name,
    };
    try {
      setLoading(true);
      const response = await editDepartManage(departmentId, params);
      if (response.status == 200 || response.status == 201) {
        notifySuccess(response.data.message);
        setLoading(false);
        getDepartmentList();
        setOpenEditDepartMent(false);
      }
    } catch (error) {
      setLoading(false);
      notify(error.response.data.message);
    }
  };

  const handleEditRoleClick = async () => {
    if (!editRoles.role_name) {
      notify("Please Enter Role");
      return;
    }

    const params = {
      department: departmentId,
      role_name: editRoles.role_name,
    };
    try {
      setLoading(true);
      const response = await editRoleManage(roleId, params);
      if (response.status == 200 || response.status == 201) {
        setLoading(false);
        getDepartmentList();
        setOpenEditRoles(false);
      }
    } catch (error) {
      setLoading(false);
      notify(error.response.data.message);
    }
  };

  const handleEditRoles = async (e) => {
    if (
      openAddDepartmentModal ||
      openDeleteModal ||
      openEditDepartMent ||
      openEditRoles ||
      deleteRoleModal
    ) {
      return true;
    }

    if (!is_admin) {
      const userAccess = UserAccessModule(
        user_access,
        "EHS Modules",
        "Role & Access Management"
      );

      if (userAccess == null || userAccess.is_editor === false) {
        notify("You don't have access");
        return true;
      }
    }
    setOpenAddDepartmentModal(false);
    setDeleteRoleModal(false);
    setOpenEditRoles(true);
    setEditRoles({ role_name: e.role_name });
    setRoleId(e.id);
  };

  const handleClickAdd = async (e) => {
    e.preventDefault();

    if (!addRole.role_name) {
      notify("Please Enter Role");
      return true;
    }
    const params = {
      department: departmentId,
      role_name: addRole.role_name,
    };
    try {
      setLoading(true);
      const resp = await addRoleAPI(params);
      if (resp.status == 200 || resp.status == 201) {
        setLoading(false);
        getDepartmentList();
        setAddRole({ role_name: "" });
      }
    } catch (error) {
      setLoading(false);
      notify(error.response.data.message);
    }
  };
  useEffect(() => {
    getDepartmentList();
  }, []);

  const handleDepartmentExcel = async () => {
    setExcelPopup(true);
  };

  const handleSubmitExcelPopup = async () => {
    const params = {
      id: manageDepartmentId,
    };
    try {
      const resp = await exportDepartmentExcel(params);
      if (resp.status == 200 || resp.status == 201) {
        setExcelPopup(false);
        notifySuccess(resp.data.message);
      } else {
        setExcelPopup(false);
      }
    } catch (error) {
      setExcelPopup(false);
      notify(error.response.data.message);
    }
  };

  const superAdminAccess = user_access.length
    ? user_access.find(
        (user) => user.access_module?.access_module_name === "Admin Dashboard"
      )
    : null;
  const accessManagementAccess = superAdminAccess
    ? superAdminAccess.sub_module.length
      ? superAdminAccess.sub_module.find(
          (f) => f.sub_module_name == "Access Management"
        )
      : null
    : null;

  const handleTostMsg = () => {
    notify("You don't have access");
  };
  return (
    <>
      {/* <div>
        <ToastContainer autoClose={4000}></ToastContainer>
      </div> */}
      {/* <div>ManagementDepartment</div> */}
      <div className="containerWrap">
        <Container maxWidth="xxl">
          <div className="deviceManagementHeader-wrap">
            <div className="deviceManagementTitle-wrap">
              <KeyboardBackspaceIcon
                onClick={() => {
                  if (
                    openAddDepartmentModal ||
                    openDeleteModal ||
                    openEditDepartMent ||
                    openEditRoles ||
                    deleteRoleModal
                  ) {
                    return true;
                  }

                  navigate("/access-management");
                }}
                className="backArrow"
              />{" "}
              <Typography
                variant="h5"
                className="deviceManagementTitle"
                gutterBottom
                style={{ margin: "0" }}
                onClick={() => {
                  if (
                    openAddDepartmentModal ||
                    openDeleteModal ||
                    openEditDepartMent ||
                    openEditRoles ||
                    deleteRoleModal
                  ) {
                    return true;
                  }

                  navigate("/access-management");
                }}
              >
                {/* {param.companyId
                  ? `${companyIdName} - Manage Department`
                  : `${companyName} - Manage Department`} */}
                {companyName} - Manage Department
              </Typography>
            </div>
            <div className="addDevice-buttonWrap">
              <img
                src={images.deleteIcon}
                alt=""
                className="cloudActiveImg"
                // onClick={handleConfirmationModal}
              />
              <img
                src={images.excelLogo}
                alt=""
                className="cloudActiveImg"
                onClick={handleDepartmentExcel}
              />
            </div>
          </div>
          {/* =============== Add Department Modal Start =============== */}

          {openAddDepartmentModal && (
            <div className="box-container">
              <Card className="addDepartment-card">
                <Box>
                  {/* <Stack direction="row" spacing={{ sm: 14, md: 20, lg: 25 }}>
                        </Stack> */}
                  <Box>
                    <Stack direction="row" spacing={{ sm: 42, md: 42, lg: 38 }}>
                      <Typography gutterBottom style={{ display: "none" }}>
                        ..
                      </Typography>
                      <img
                        src={images.closeIcon}
                        alt=""
                        style={{ width: "30px", cursor: "pointer" }}
                        onClick={handleOpenaddDepartmentCloseModal}
                      />
                    </Stack>
                    <Typography
                      gutterBottom
                      style={{
                        color: "#0E8951",
                        fontWeight: "600",
                        fontSize: "19px",
                        textAlign: "center",
                      }}
                    >
                      Add Department
                    </Typography>
                  </Box>
                  <ValidatorForm onSubmit={submitAddDepartment}>
                    <div className="departmentWrap">
                      <label for="exampleInputEmail1" class="form-label">
                        Department <span>*</span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Enter Department"
                        name="department_name"
                        onChange={handleChangeDepartment}
                      ></input>
                    </div>
                    <button
                      className="addDepartmentButton"
                      // onClick={submitAddDepartment}
                    >
                      Add Department
                    </button>
                  </ValidatorForm>
                </Box>
              </Card>
            </div>
          )}
          {/* =============== Add Department Modal End =============== */}
          {/* =============== Delete Confirmation Modal Start =============== */}
          {openDeleteModal && (
            <div className="box-container">
              <Card className="deleteConfirmation-Modal">
                <Typography
                  variant="h6"
                  gutterBottom
                  className="deleteConfirmation-title"
                >
                  Are You Sure<br></br> You Want To Delete?
                </Typography>
                <div className="pt-3 btn-div">
                  <button className="submit-btn" onClick={handleSubmit}>
                    Yes
                  </button>
                  <button
                    className="reset-btn"
                    onClick={handleConfirmationCloseModal}
                  >
                    No
                  </button>
                </div>
              </Card>
            </div>
          )}
          {deleteRoleModal && (
            <div className="box-container">
              <Card className="deleteConfirmation-Modal">
                <Typography
                  variant="h6"
                  gutterBottom
                  className="deleteConfirmation-title"
                >
                  Are You Sure<br></br> You Want To Delete?
                </Typography>
                <div className="pt-3 btn-div">
                  <button className="submit-btn" onClick={handleDeleteModal}>
                    Yes
                  </button>
                  <button
                    className="reset-btn"
                    onClick={handleConfirmModalClose}
                  >
                    No
                  </button>
                </div>
              </Card>
            </div>
          )}

          <ValidatorForm onSubmit={handleEditClick}>
            {openEditDepartMent && (
              <div className="box-container">
                <Card className="addPlant-cardModal">
                  <Box className="addPlant-titleWrap">
                    <Typography
                      variant="h5"
                      gutterBottom
                      className="addPlant-title"
                    >
                      Edit Department
                    </Typography>
                    <img
                      src={images.closeIcon}
                      alt=""
                      style={{
                        width: "30px",
                        cursor: "pointer",
                        backgroundColor: "white",
                      }}
                      className="closeImg"
                      onClick={handleCloseClick}
                    />
                  </Box>
                  <Box className="addplantMdal-innerWrap">
                    <Stack spacing={2} pt="10px">
                      <TextField
                        id="filled-basic"
                        name="department_name"
                        label="Department Name"
                        variant="filled"
                        className="addplantInputfield"
                        value={editDepartment.department_name}
                        InputProps={{}}
                        onChange={handleChangeEditDepartment}
                      />
                    </Stack>

                    <Box className="addPlant-cardButtons">
                      <button className="addPlantBtn">Update</button>
                    </Box>
                  </Box>
                </Card>
              </div>
            )}
          </ValidatorForm>

          {openEditRoles && (
            <div className="box-container">
              <Card className="addPlant-cardModal">
                <Box className="addPlant-titleWrap">
                  <Typography
                    variant="h5"
                    gutterBottom
                    className="addPlant-title"
                  >
                    Edit Role
                  </Typography>
                  <img
                    src={images.closeIcon}
                    alt=""
                    style={{
                      width: "30px",
                      cursor: "pointer",
                      backgroundColor: "white",
                    }}
                    className="closeImg"
                    onClick={handleCancleClick}
                  />
                </Box>
                <ValidatorForm onSubmit={handleEditRoleClick}>
                  <Box className="addplantMdal-innerWrap">
                    <Stack spacing={2} pt="10px">
                      <TextField
                        id="filled-basic"
                        name="role_name"
                        label="Role Name"
                        variant="filled"
                        className="addplantInputfield"
                        value={editRoles.role_name}
                        InputProps={{}}
                        onChange={handleChangeEditRole}
                      />
                    </Stack>

                    <Box className="addPlant-cardButtons">
                      <button
                        className="addPlantBtn"
                        // onClick={() => handleEditRoleClick()}
                      >
                        Update
                      </button>
                    </Box>
                  </Box>
                </ValidatorForm>
              </Card>
            </div>
          )}

          {/* =============== Delete Confirmation Modal End =============== */}
          <Grid container style={{ paddingTop: "20px" }}>
            <Grid item xs={12}>
              <Card className="manageDepartment-card">
                <Grid
                  container
                  style={{
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    paddingTop: "30px",
                    paddingBottom: "30px",
                  }}
                >
                  <Grid item sm={12} md={4} lg={4} xl={4}>
                    <Typography variant="subtitle2" gutterBottom>
                      <b>Department</b>
                    </Typography>
                    {!isShimmerDepartment ? (
                      departmentList.map((e, i) => {
                        return (
                          <div className="qqq">
                            <div
                              className="manageDepartment-wrap mb-2"
                              onClick={() => setDepartmentId(e.id)}
                            >
                              <div className="manageDepartment-fieldWrap">
                                {departmentId == e.id && (
                                  <img
                                    src={images.activeDeviceimg}
                                    alt=""
                                    className="activeDepartmentimg"
                                  />
                                )}
                                <div className="manageDepartment-field">
                                  <Typography
                                    variant="body2"
                                    gutterBottom
                                    style={{ margin: "0" }}
                                  >
                                    {e.department_name}
                                  </Typography>

                                  <div className="manageDepartment-fieldIcon">
                                    <img
                                      src={images.editImg}
                                      alt=""
                                      style={{
                                        width: "24px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => handleEditModal(e)}
                                    />
                                    <img
                                      src={images.deleteImg}
                                      alt=""
                                      style={{
                                        width: "24px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => handleConfirmationModal(i)}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <Textshimmer height="35px" width="90%" itemCount={5} />
                    )}
                  </Grid>
                  <Grid item sm={12} md={8} lg={8} xl={8}>
                    <div className="d-flex justify-content-between">
                      <Typography variant="subtitle2" gutterBottom>
                        <b>Roles</b>
                      </Typography>
                      <button
                        className="managementDepartment-button"
                        onClick={handleOpenaddDepartmentModal}
                      >
                        Add Department
                      </button>
                    </div>
                    <div className="managementDepartment-card">
                      {/* <Box className='managementDepartment-addRoleswrap'>
                                                <img src={images.addIcon} alt="" className='addDeviceImg' style={{ cursor: 'pointer' }} />
                                                <Typography variant="caption" display="lock" gutterBottom className="manageDepartment-assignRolesText">
                                                    <b>Add Roles</b>
                                                </Typography>
                                            </Box> */}
                      <Grid container>
                        <Grid item sm={6} md={6} lg={6} xl={6}>
                          {departmentList.length
                            ? departmentList.map((e, i) => {
                                if (e.id == departmentId) {
                                  return e.company_roles.map((e1) => {
                                    return (
                                      <Box className="managementDepartment roles mb-2">
                                        <Typography
                                          variant="body2"
                                          gutterBottom
                                          style={{ margin: "0" }}
                                        >
                                          {e1.role_name}
                                        </Typography>
                                        <div className="managementDepartment icon">
                                          <img
                                            src={images.editImg}
                                            alt=""
                                            style={{
                                              width: "24px",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => handleEditRoles(e1)}
                                          />
                                          <img
                                            src={images.deleteImg}
                                            alt=""
                                            style={{
                                              width: "24px",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              handleConfirmModal(e1)
                                            }
                                          />
                                        </div>
                                      </Box>
                                    );
                                  });
                                }
                              })
                            : []}

                          {!isEmpatyDevice && <div className="bold-hr"></div>}
                          <form className="manageDepartment roleInputWrap">
                            <label
                              for="input-field"
                              style={{ fontSize: "12px" }}
                            >
                              <b>Role</b>
                              <span style={{ color: "#E31E24" }}>*</span>
                            </label>
                            <div className="manageDepartment roleInput">
                              <input
                                type="text"
                                id="input-field"
                                name="role_name"
                                placeholder="Enter Role Name"
                                className="manageDepartment-input"
                                value={addRole.role_name}
                                onChange={handleChangeRole}
                              />
                              <button
                                className="manageDepartment addRoleButton"
                                onClick={(e) => handleClickAdd(e)}
                              >
                                Add
                              </button>
                            </div>
                          </form>
                        </Grid>
                      </Grid>
                    </div>
                    {(accessManagementAccess?.is_viewer &&
                      accessManagementAccess?.is_editor) ||
                    (!accessManagementAccess?.is_viewer &&
                      accessManagementAccess?.is_editor) ||
                    true ? (
                      <button
                        className="Plantmanagemet submit"
                        onClick={() => {
                          if (
                            openAddDepartmentModal ||
                            openDeleteModal ||
                            openEditDepartMent ||
                            openEditRoles ||
                            deleteRoleModal
                          ) {
                            return true;
                          }
                          navigate("/access-management");
                        }}
                      >
                        Submit
                      </button>
                    ) : (
                      <button
                        className="Plantmanagemet submit"
                        onClick={handleTostMsg}
                      >
                        Submit
                      </button>
                    )}
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Container>
        {excelPopup && (
          <div className="box-container">
            <Card className="deleteConfirmation-Modal">
              <Typography
                variant="h6"
                gutterBottom
                className="deleteConfirmation-title"
              >
                The requested file will be delivered to your email box.
              </Typography>
              <div className="pt-3 btn-div">
                <button className="submit-btn" onClick={handleSubmitExcelPopup}>
                  Ok
                </button>
                <button
                  className="reset-btn"
                  onClick={() => setExcelPopup(false)}
                >
                  Cancel
                </button>
              </div>
            </Card>
          </div>
        )}
        {isLoading && <Loader />}
      </div>
    </>
  );
}

export default ManageDepartment;
