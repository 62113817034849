import React, { useState } from "react";
import "../../../index.css";
import "../SignIn/SignIn.css";
import { images } from "../../../config/images";
import { Grid } from "@mui/material";
import { SliderAuth } from "../../../components/SliderAuth/SliderAuth";
import NewPassword from "../NewPassword/NewPassword";
import OtpEnter from "../OTP/Otp";
import SignIn from "./SignIn";
import ForgotPassword from "../ForgotPassword/ForgotPassoword";
import TwoStepVerificationOtp from "./TwoStepVerificationOtp";
import MfaVerification from "./MfaVerification";

const Login = () => {

  // Define state variables
  const [currentState, setCurrentState] = useState("");
  const [email, setEmail] = useState("");
  const [OTP, setOTP] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [mfaUserId, setMfaUserId] = useState("");

  // Handle click events to change state
  const onNextClick = (nextState) => {
    setCurrentState(nextState);
  };

  const currentComponent = () => {
    switch (currentState) {
      case "forgotPassword":
        return (
          <ForgotPassword
            email={email}
            setEmail={setEmail}
            onNextClick={() => onNextClick("verifyOTP")}
            onCancelClick={() => onNextClick("signIn")}
          />
        );
      case "verifyOTP":
        return (
          <OtpEnter
            OTP={OTP}
            email={email}
            onNextClick={() => onNextClick("changePassword")}
            onCancelClick={() => onNextClick("signIn")}
            setOTP={setOTP}
            setEmail={setEmail}
          />
        );
      case "changePassword":
        return (
          <NewPassword
            email={email}
            OTP={OTP}
            onNextClick={() => onNextClick("login")}
            onCancelClick={() => onNextClick("signIn")}
            setEmail={setEmail}
            setOTP={setOTP}
          />
        );
      case "verify2Step":
        return (
          <TwoStepVerificationOtp
            mobileNo={mobileNo}
            // onNextClick={() => onNextClick("changePassword")}
            onCancelClick={() => onNextClick("")}

          />
        );
      case "verifyMFA":
        return (
          <MfaVerification
            email={email}
            mfaUserId={mfaUserId}
            // onNextClick={() => onNextClick("changePassword")}
            onCancelClick={() => onNextClick("")}

          />
        );

      default:
        return <SignIn
          setMobileNo={setMobileNo}
          setMfaUserId={setMfaUserId}
          onNextClick={(msg) => onNextClick(msg)} />;
    }
  };

  return (
    <>
      <Grid container className="main-container">
        <SliderAuth />
        <Grid item xs={12} md={5} lg={4} className="form-container">
          <div className="pb-4 pt-5">
            <img src={images.hikarlogo} alt="" className="signIn-logo" />
          </div>

          {currentComponent()}
          <div className="footer-content">
            <p>
              Powered by <br />
              <b>Hikar&#174;Technomation</b> Private Limited &#169; All Rights
              Reserved
            </p>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
export default Login;
