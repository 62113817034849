import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MainEshDashboard from "../container/MainEshDashboard/MainEshDashboard";
import Header from "../container/Header/Header";
import UserProfile from "../container/UserProfile/UserProfile";
import ChangePassword from "../container/ChangePassword/ChangePassoword";
import EhsForm from "../container/MainEshDashboard/EhsForm/EhsForm";
import EhsDatabase from "../container/MainEshDashboard/EhsDatabase/EhsDatabase";
import AdvanceAnalytics from "../container/MainEshDashboard/AdvanceAnalytics/AdvanceAnalytics";
import UserManagement from "../container/MainEshDashboard/UserManagement/UserManagement";
import AccessManagement from "../container/MainEshDashboard/Role&AccessManagement/AccessManagement";
import AddAccessManagement from "../container/MainEshDashboard/Role&AccessManagement/AddAccessManagement/AddAccessManagement";
import ReportManagement from "../container/MainEshDashboard/ReportManagement/ReportManagement";
import ManageDepartment from "../container/MainEshDashboard/Role&AccessManagement/ManageDepartment/ManageDepartment";
import ReportManagementConfiguration from "../container/MainEshDashboard/ReportManagement/ReportManagementConfiguration/ReportManagementConfiguration";
import { UnknowRoutes } from "../components/UnknowRoutes";
import Dashboard from "../container/MainEshDashboard/Dashboard/Dashboard";
import RemoveCurrentUserToken from "../components/UserOnBoard/RemoveCurrentUserToken";
import FooterText from "../components/Footer/Footer";
import NotificationDashboard from "../container/Notification/Notification";
import History from "../container/Notification/History";

const AuthenticationRoutes = () => {
    return (
        <Router>
            <Header />
            <FooterText/>
            <Routes>
                <Route path="/" element={<Dashboard/>} />
                <Route path="/userprofile" element={<UserProfile />} />
                <Route path="/change-password" element={<ChangePassword />} />
                <Route path="/ehs-form" element={<EhsForm />} />
                <Route path="/ehs-form/:id" element={<EhsForm />} />
                <Route path="/ehs-form/history/:historyId" element={<EhsForm />} />
                <Route path="/ehs-database" element={<EhsDatabase />} />
                <Route path="/advance-analytics" element={<AdvanceAnalytics />} />
                <Route path="/user-management" element={<UserManagement />} />
                <Route path="/access-management" element={<AccessManagement />} />
                <Route path="/acsessmanagement/add-department" element={<AddAccessManagement />} />
                <Route path="/acsessmanagement/edit/:id" element={<AddAccessManagement />} />
                <Route path="/acsessmanagement/managedepartment" element={<ManageDepartment />} />
                <Route path="/report-management" element={<ReportManagement />} />
                <Route path="/reportmanagement/report/:reportId/configuration" element={<ReportManagementConfiguration />} />
                <Route path="/reportmanagement/report/:reportId/configuration/setting" element={<ReportManagementConfiguration />} />
                <Route path="/create-password/:uidb64/:token/" element={<RemoveCurrentUserToken />} />
                <Route path="/notificationDashboard"  element={<NotificationDashboard />}/>
                <Route path="/history" element={<History/>}/>
                
                <Route path="*" element={<UnknowRoutes />} />
            </Routes>
        </Router>
    );
};

export default AuthenticationRoutes;
