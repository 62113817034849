import { Box, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  DatePicker,
  LocalizationProvider,
  DateTimePicker,
} from "@mui/x-date-pickers";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import React from "react";
import { useDispatch } from "react-redux";

const POPDatePicker = ({
  time,
  startDateReFormat,
  endDateReFormat,
  handleStartDate,
  handleEndDate,
  handlePOP1StartDate,
  handlePOP1EndDate,
  startDate1ReFormat,
  endDate1ReFormat,
 
}) => {

  return (
    <>
      {time ? (
        <>
          <Box
            style={{ width: "22%", marginRight: "8px" }}
            className="box-datepicker"
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label="Start Date"
                className="date-picker-production datepicker-color"
                value={startDateReFormat}
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
                onChange={(newValue) => handleStartDate(newValue)}
              />
            </LocalizationProvider>
          </Box>
          <Box
            className="header-btn-month"
            style={{ width: "22%", marginRight: "8px" }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label="End Date"
                className="date-picker-production datepicker-color"
                value={endDateReFormat}
                onChange={(newValue) => handleEndDate(newValue)}
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
              />
            </LocalizationProvider>
          </Box>
          <Box
            style={{ width: "22%", marginRight: "8px" }}
            className="box-datepicker"
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label="Start Date"
                className="date-picker-production second-datepicker-color"
                value={startDate1ReFormat}
                onChange={(newValue) => handlePOP1StartDate(newValue)}
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
              />
            </LocalizationProvider>
          </Box>
          <Box
            className="header-btn-month"
            style={{ width: "22%", marginRight: "8px" }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label="End Date"
                className="date-picker-production second-datepicker-color"
                value={endDate1ReFormat}
                onChange={(newValue) => handlePOP1EndDate(newValue)}
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
              />
            </LocalizationProvider>
          </Box>
        </>
      ) : (
       
            <>
              <Box
                style={{ width: "22%", marginRight: "8px" }}
                className="box-datepicker"
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className="date-picker-production datepicker-color"
                    label="Start Date"
                    value={startDateReFormat}
                    inputFormat="DD/MM/YYYY"
                    onChange={(newValue) => handleStartDate(newValue)}
                    disableFuture
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={{ background: "#ffff" }}
                        InputLabelProps={{
                          style: {
                            color: "#C0255F",
                          },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
              <Box
                className="header-btn-month"
                style={{ width: "22%", marginRight: "8px" }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className="date-picker-production datepicker-color"
                    label="End Date"
                    value={endDateReFormat}
                    inputFormat="DD/MM/YYYY"
                    onChange={(newValue) => handleEndDate(newValue)}
                    disableFuture
                    renderInput={(params) => (
                      <TextField {...params} style={{ background: "#ffff" }} />
                    )}
                  />
                </LocalizationProvider>
              </Box>

              <Box
                style={{ width: "22%", marginRight: "8px" }}
                className="box-datepicker"
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className="date-picker-production second-datepicker-color"
                    label="Start Date"
                    value={startDate1ReFormat}
                    inputFormat="DD/MM/YYYY"
                    onChange={(newValue) => handlePOP1StartDate(newValue)}
                    disableFuture
                    renderInput={(params) => (
                      <TextField {...params} style={{ background: "#ffff" }} />
                    )}
                  />
                </LocalizationProvider>
              </Box>
              <Box
                className="header-btn-month"
                style={{ width: "22%", marginRight: "8px" }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className="date-picker-production second-datepicker-color"
                    label="End Date"
                    value={endDate1ReFormat}
                    inputFormat="DD/MM/YYYY"
                    onChange={(newValue) => handlePOP1EndDate(newValue)}
                    disableFuture
                    renderInput={(params) => (
                      <TextField {...params} style={{ background: "#ffff" }} />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </>

      )}
    </>
  );
};

export default POPDatePicker;
