export const images ={
    slider1: require("../assets/images/slider1.jpg"),
    slider2: require("../assets/images/slider2.jpg"),
    slider3: require("../assets/images/slider3.jpg"),
    slider4: require("../assets/images/slider4.jpg"),
    slider5: require("../assets/images/slider5.jpg"),
    hikarlogo:require("../assets/images/hikar-logo.png"),
    minimizeScreen: require("../assets/images/Minimize-screen.png"),
    passwordImg: require("../assets/images/PasswordImg.png"),
    addImage: require("../assets/images/AddImage.png"),
    whiteplusIcon: require("../assets/images/whitePlusIcon.png"),
    deleteIcon: require("../assets/images/DeleteIcon.png"),
    excelLogo: require("../assets/images/ExcelLogo.png"),
    closeIcon: require("../assets/images/close.png"),
    generated : require("../assets/images/arrows.png"),
    approved : require("../assets/images/approved.png"),
    rejected : require("../assets/images/rejected.png"),
    pending : require("../assets/images/pending.png"),
    ehsForm : require("../assets/images/ehs-form.png"),
    ehsDatabase : require("../assets/images/ehs-database.png"),
    advanceAnalytics : require("../assets/images/advnace-analytics.png"),
    userManagement: require("../assets/images/user-management.png"),
    roleAccess : require("../assets/images/role-access.png"),
    reportManagement : require("../assets/images/report-management.png"),
    addIcon : require("../assets/images/whitePlusIcon.png"),
    deleteIcon : require("../assets/images/DeleteIcon.png"),
    editIcon : require("../assets/images/EditIcon.png"),
    excelIcon : require("../assets/images/ExcelLogo.png"),
    downloadpdf : require("../assets/images/download-pdf-icon.png"),
    exportImage : require("../assets/images/export-image.png"),
    importImage: require("../assets/images/import-image.png"),
    editImage : require("../assets/images/Edit.png"),
    deleteImage : require("../assets/images/delete.png"),
    editImg: require("../assets/images/EditImg.png"),
    deleteImg: require("../assets/images/DeleteImg.png"),
    alertImage: require("../assets/images/AlertImage.png"),
    userImg: require("../assets/images/UsersImg.png"),
    upArrow: require("../assets/images/UpArrow.png"),
    downIcon: require("../assets/images/DownIcon.png"),
    settingImage: require("../assets/images/SettingImage.png"),
    activeDeviceimg: require("../assets/images/activeDeviceimg.png"),
    notificationImg : require("../assets/images/notification.png"),
    history : require("../assets/images/history.png"),
    eyecloseIcon : require("../assets/images/EyecloseIcon.png"),
    viewIcon : require("../assets/images/EyeIcon.png")
}