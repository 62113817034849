import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import { images } from "../../../config/images";
import CloseIcon from "@mui/icons-material/Close";
import { deleteLocation, getLocation, updateLocationDataAPI } from "./services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserAccessModule } from "../../../components/UserAccess/UserAccessmodule";
import { useSelector } from "react-redux";

const Location = (props) => {
  const [updateLocation, setUpdateLocation] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [getLocationData, setGetLocationData] = useState([]);
  const [locationCount, setLocationCount] = useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [locationId, setLocationId] = useState();
  const [filterData, setFilterData] = useState({
    location_code: "",
    location_name: "",
    created_by__first_name :""
  });
  const [updateLocationData, setUpdateLocationData] = useState({
    location_code: "",
    location_name: "",
  });

  const { user_access, is_admin } = useSelector((state) => state.userState);

  const notify = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION?.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const notifySuccess = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION?.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const handleEditClick = (e) => {

    if (!is_admin) {
      const userAccess = UserAccessModule(
        user_access,
        "EHS Modules",
        "EHS Database"
      );

      if (userAccess == null || userAccess.is_editor === false) {
        notify("You don't have access");
        return true;
      }
      
    }

    setUpdateLocationData({
      location_name: e.location_name,
      location_code: e.location_code,
    });
    setLocationId(e.id);
    setUpdateLocation(true);
  };

  const handleDeletePopup = (e) => {

    if (!is_admin) {
      const userAccess = UserAccessModule(
        user_access,
        "EHS Modules",
        "EHS Database"
      );

      if (userAccess == null || userAccess.is_editor === false) {
        notify("You don't have access");
        return true;
      }
      
    }

    setLocationId(e.id);
    setDeletePopup(true);
  };

  const handleSubmit = async () => {
    try {
      const resp = await deleteLocation(locationId);
      if (resp.status == 200 || resp.status == 201) {
        handleGetLocationData()
        setDeletePopup(false);
        notifySuccess(resp.data.message);
      }
    } catch (error) {
      notify(error.response.data.message);
      setDeletePopup(false);
    }
  };

  useEffect(() => {
    handleGetLocationData();
  }, [filterData, page, rowsPerPage,props.count]);

  const handleChangeSearch = (e) => {
    setFilterData({ ...filterData, [e.target.name]: e.target.value });
  };

  const handleChangeLocationData = (e) => {
    setUpdateLocationData({
      ...updateLocationData,
      [e.target.name]: e.target.value,
    });
  };
  const handleGetLocationData = async () => {
    const params = {
      page: page,
      rowsPerPage: rowsPerPage,
    };
    try {
      const resp = await getLocation(filterData, params);
      if (resp.status == 200 || resp.status == 201) {
        setGetLocationData(resp.data.payload.results);
        setLocationCount(resp.data.payload);
      }
    } catch (error) {
      notify(error.response.data.message);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUpdateLocation = async () => {
    if (updateLocationData.location_code == "") {
      notify("Please Enter Location Code");
      return;
    }
    if (updateLocationData.location_name == "") {
      notify("Please Enter Location Name");
      return;
    }
    const params = {
      location_code: updateLocationData.location_code,
      location_name: updateLocationData.location_name,
    };
    try {
      const resp = await updateLocationDataAPI(locationId, params);
      if (resp.status == 200 || resp.status == 201) {
        setUpdateLocation(false);
        handleGetLocationData();
        notifySuccess(resp.data.message);
      }
    } catch (error) {
      notify(error.response.data.message);
    }
  };
  return (
    <>
      <div className="pt-2">
        <div className="table-responsive">
          <Table className="Ehs-form-table">
            <TableHead>
              <TableRow>
                <TableCell>Location Code</TableCell>
                <TableCell>Location Name</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Edit or Delete</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Filter"
                    name="location_code"
                    value={filterData.location_code}
                    onChange={handleChangeSearch}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Filter"
                    name="location_name"
                    value={filterData.location_name}
                    onChange={handleChangeSearch}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Filter"
                    name="created_by__first_name"
                    value={filterData.created_by__first_name}
                    onChange={handleChangeSearch}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input type="search" placeholder="Filter" name="last_name" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getLocationData?.map((e) => {
                return (
                  <TableRow>
                    <TableCell>{e.location_code}</TableCell>
                    <TableCell>{e.location_name}</TableCell>
                    <TableCell>
                      {e.user_first_name} {e.user_last_name}
                    </TableCell>
                    <TableCell>
                      {" "}
                      <div className="d-flex gap-2">
                        <img
                          src={images.editImage}
                          alt=""
                          onClick={() => handleEditClick(e)}
                          style={{ cursor: "pointer" }}
                        />
                        <img
                          src={images.deleteImage}
                          alt=""
                          onClick={() => handleDeletePopup(e)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            className="table-pagination"
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={locationCount ? locationCount.count : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {updateLocation && (
            <div className="box-container">
              <Box className="add-material-box">
                <div className="p-2">
                  <div className="add-material-div">
                    <h6>Update Location</h6>
                    <CloseIcon
                      style={{
                        cursor: "pointer",
                        fontSize: "31px",
                        marginTop: "-5px",
                      }}
                      onClick={() => setUpdateLocation(false)}
                    />
                  </div>
                  <div>
                    <div className="dropdown-area pt-2">
                      <h6>Location Name</h6>
                      <input
                        className="materialcode-dropdown"
                        placeholder="Enter Location Name"
                        name="location_name"
                        value={updateLocationData.location_name}
                        onChange={handleChangeLocationData}
                      ></input>
                    </div>
                    <div className="dropdown-area pt-2">
                      <h6>Location Code</h6>
                      <input
                        className="materialcode-dropdown"
                        placeholder="Enter Location Code"
                        name="location_code"
                        value={updateLocationData.location_code}
                        onChange={handleChangeLocationData}
                      ></input>
                    </div>

                    <div className="pt-3 btn-div">
                      <button
                        className="reset-btn"
                        onClick={() => setUpdateLocation(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="submit-btn"
                        onClick={handleUpdateLocation}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </Box>
            </div>
          )}
        </div>
      </div>
      {deletePopup && (
        <div className="box-container">
          <Card className="deleteConfirmation-Modal">
            <Typography
              variant="h6"
              gutterBottom
              className="deleteConfirmation-title"
            >
              Are You Sure<br></br> You Want To Delete?
            </Typography>
            <div className="pt-3 btn-div">
              <button className="submit-btn" onClick={handleSubmit}>
                Yes
              </button>
              <button className="reset-btn">No</button>
            </div>
          </Card>
        </div>
      )}
    </>
  );
};
export default Location;
