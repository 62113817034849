import React from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import TabPanel from "@mui/lab/TabPanel";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import { withStyles } from "@material-ui/core/styles";
import { updateUserProfileCommunication } from "../service";
import "./CommunicationTab.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
// import { RedirectAdminDashboard } from '../../../components/CommonFunction/RedirectAdminDashboard';

const CommunicationTab = ({
  userProfile,
  setUserProfile,
  getUserProfile,
  setValue,
}) => {
  const { id, is_super_admin } = useSelector((state) => state.userState);

  const navigate = useNavigate();
  const notify = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const handleChangeCommunicationSetting = (name, value) => {
    setUserProfile({ ...userProfile, [name]: !value });
  };

  const handleCommunicationSettingsDataForm = async () => {
    const param = {
      first_name: userProfile.first_name,
      last_name: userProfile.last_name,
      communication_email: userProfile.communication_email,
      communication_phone_call: userProfile.communication_phone_call,
    };
    // const formData = new FormData();
    // formData.append("first_name", userProfile.first_name);
    // formData.append("last_name", userProfile.last_name);
    // formData.append("communication_email", userProfile.communication_email);
    // formData.append("communication_phone_call", userProfile.communication_phone_call);

    try {
      const resp = await updateUserProfileCommunication(id, param);
      if (resp) {
        notify("Updated Successfully");
        getUserProfile();
        navigate("/");
        // RedirectAdminDashboard(is_super_admin, navigate);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <TabPanel value="3" className="tab-panel-data">
        <Grid container spacing={8}>
          <Grid item xs={4} sm={12} md={7}>
            <Typography
              variant="subtitle1"
              gutterBottom
              pt="20px"
              style={{ fontWeight: 600, color: "#001323" }}
            >
              {" "}
              Communications{" "}
            </Typography>
            <Typography variant="subtitle1">
              Would you like updates about <b>Hikar</b> promotions, products and
              services?
            </Typography>
            <Grid
              container
              spacing={2}
              pt={4}
              className="d-flex align-items-center"
            >
              <Grid item xs={4}>
                <Typography
                  variant="subtitle1"
                  style={{ fontWeight: 600, color: "#001323" }}
                >
                  Email
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Switch
                  checked={
                    userProfile ? userProfile.communication_email : false
                  }
                  name="communication_email"
                  onClick={() =>
                    handleChangeCommunicationSetting(
                      "communication_email",
                      userProfile ? userProfile.communication_email : false
                    )
                  }
                  inputProps={{ "aria-label": "controlled" }}
                  defaultChecked
                  size="small"
                  color="success"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="d-flex align-items-center">
              <Grid item xs={4}>
                <Typography
                  variant="subtitle1"
                  style={{ fontWeight: 600, color: "#001323" }}
                >
                  Phone Call
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Switch
                  checked={
                    userProfile ? userProfile.communication_phone_call : false
                  }
                  name="communication_phone_call"
                  onClick={() =>
                    handleChangeCommunicationSetting(
                      "communication_phone_call",
                      userProfile ? userProfile.communication_phone_call : false
                    )
                  }
                  inputProps={{ "aria-label": "controlled" }}
                  defaultChecked
                  size="small"
                  color="success"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box className="profileButton-wrap communicationTab">
          <button className="cancelButton" onClick={() => setValue("2")}>
            Cancel
          </button>
          <button
            className="updateButton"
            style={{ marginLeft: "10px" }}
            onClick={() => handleCommunicationSettingsDataForm()}
          >
            Update
          </button>
        </Box>
      </TabPanel>
    </>
  );
};
export default CommunicationTab;
