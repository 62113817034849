import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import SearchIcon from "@mui/icons-material/Search";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
// import Switch from "react-switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import { images } from "../../../config/images";
import {
  getUser,
  getUserDepartment,
  adduserDepartment,
  updateUserDepartment,
  deleteUserDepartment,
  exportUserReport,
  deleteMultipleUser,
} from "../services";
import "./UserManagement.css";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../components/Loader/Loader";
import { ValidatorForm } from "react-material-ui-form-validator";
import UserManagementTableShimmer from "./UserManagementShimmer";
import { Switch } from "@mui/material";
import { UserAccessModule } from "../../../components/UserAccess/UserAccessmodule";

const UserManagement = () => {
  const navigate = useNavigate();
  const [openUpdateUserManagement, setUpdateUserManagement] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAllDeleteModal, setOpenAllDeleteModal] = useState(false);
  const [userData, setUserData] = useState([]);
  const [addUserModal, setAddUserModal] = useState(false);
  const [department, setDepartment] = useState([]);
  const [selectDepartment, setSelectDepartment] = useState("none");
  const [selectRole, setSelectRole] = useState("none");
  const [addUserDepartment, setaddUserDepartment] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });
  const [updateUser, setupdateUser] = useState({
    department: "",
    role_name: "",
    first_name: "",
    last_name: "",
    email: "",
  });
  const [userRowId, setUserRowId] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectUserId, setSelectUserId] = useState([]);
  const [isShimmerUserData, setisShimmerUserData] = useState(false);
  const [excelPopup, setExcelPopup] = useState(false);

  const [searchUserDataList, setSearchUserList] = useState({
    first_name: "",
    last_name: "",
    department__department_name: "",
    user_role__name: "",
    email: "",
  });

  const { companyId, companyName, user_access, is_admin } = useSelector(
    (state) => state.userState
  );

  const notify = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast?.POSITION?.TOP_RIGHT,
      toastClassName: "custom-toast",
    });
  const notify1 = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast?.POSITION?.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const superAdminAccess = user_access?.length
    ? user_access.find(
        (user) => user.access_module?.access_module_name === "Admin Dashboard"
      )
    : null;
  const accessManagementUser = superAdminAccess
    ? superAdminAccess.sub_module.length
      ? superAdminAccess.sub_module.find(
          (f) => f.sub_module_name == "User Management"
        )
      : null
    : null;

  const handleTostMsg = () => {
    notify("You don't have access");
  };
  //Add user
  const handleAddUser = () => {
    if (!is_admin) {
      const userAccess = UserAccessModule(
        user_access,
        "EHS Modules",
        "User Management"
      );

      if (userAccess == null || userAccess.is_editor === false) {
        notify("You don't have access");
        return true;
      }
    }

    if (openUpdateUserManagement || openDeleteModal || addUserModal) {
      return true;
    }
    setAddUserModal(!addUserModal);
  };

  const handleCloseUser = () => {
    setAddUserModal(!addUserModal);
  };

  const handleSelectAll = (checked) => {
    if (!is_admin) {
      const userAccess = UserAccessModule(
        user_access,
        "EHS Modules",
        "User Management"
      );

      if (userAccess == null || userAccess.is_editor === false) {
        notify("You don't have access");
        return true;
      }
    }

    if (openUpdateUserManagement || openDeleteModal || addUserModal) {
      return true;
    }

    if (checked) {
      const allRowIds = userData.map((e) => e.id);
      setSelectedRows(allRowIds);
    } else {
      setSelectedRows([]);
    }
    setSelectAll(checked);
  };
  //Update user
  const handleUserUpdate = (e) => {
    if (openUpdateUserManagement || openDeleteModal || addUserModal) {
      return true;
    }

    if (!is_admin) {
      const userAccess = UserAccessModule(
        user_access,
        "EHS Modules",
        "User Management"
      );

      if (userAccess == null || userAccess.is_editor === false) {
        notify("You don't have access");
        return true;
      }
    }

    if (is_admin == true && e.is_admin == true && e.user_role == "Admin") {
      notify("Admin user is not update");
      return true;
    }

    setupdateUser({
      department: e.department_id,
      role_name: e.role_id,
      first_name: e.first_name,
      last_name: e.last_name,
      email: e.email,
    });
    setUpdateUserManagement(!openUpdateUserManagement);
    setOpenDeleteModal(false);
    setUserRowId(e.id);
  };

  const handleCloseUserUpdateModel = () => {
    setUpdateUserManagement(!openUpdateUserManagement);
  };

  //Delete user modal
  const handleConfirmationModal = (e, id) => {
    if (openUpdateUserManagement || openDeleteModal || addUserModal) {
      return true;
    }

    if (!is_admin) {
      const userAccess = UserAccessModule(
        user_access,
        "EHS Modules",
        "User Management"
      );

      if (userAccess == null || userAccess.is_editor === false) {
        notify("You don't have access");
        return true;
      }
    }

    if (!selectedRows.includes(id)) {
      notify("Please Select User");
      return true;
    }
    setUserRowId(id);
    setOpenDeleteModal(!openDeleteModal);
    setUpdateUserManagement(false);
  };

  const handleConfirmationModalCloseModel = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  // get userList
  const getUserList = async () => {
    try {
      setisShimmerUserData(true);
      const response = await getUser(searchUserDataList);
      if (response.status == 200 || response.status == 201) {
        setTimeout(() => {
          setisShimmerUserData(false);
        }, 1000);
      }
      if (
        response.status == 200 ||
        (response.status == 201 && response.data.payload.length)
      ) {
        setUserData(response.data.payload);
        setSelectUserId(response.data.payload.map((e) => e.id));
      }
    } catch (error) {
      setisShimmerUserData(false);
      console.log("Error from getUserList", error);
    }
  };

  //get user department list
  const getUserDepartmentList = async () => {
    const paramReq = {
      company: companyId,
    };
    const response = await getUserDepartment(paramReq);
    setDepartment(response.data.payload);
  };

  //Add user Department
  const handleChangeuserManagement = (e) => {
    setaddUserDepartment({
      ...addUserDepartment,
      [e.target.name]: e.target.value,
    });
  };

  // handleUpdateUser
  const handelChangeUpdateUser = (e) => {
    setupdateUser((prevState) => {
      const updatedUser = { ...prevState, [e.target.name]: e.target.value };
      if (e.target.name === "department") {
        updatedUser.role_name = "none";
      }
      return updatedUser;
    });
  };

  const handleSearchUserData = (e) => {
    setSearchUserList({
      ...searchUserDataList,
      [e.target.name]: e.target.value,
    });
  };

  const submitUserDepartment = async () => {
    if (selectDepartment === "none") {
      notify("Please Select Department");
      return;
    }

    if (selectRole === "none") {
      notify("Please Select Role");
      return;
    }

    if (addUserDepartment.first_name === "") {
      notify("Please Enter First Name");
      return;
    }

    if (addUserDepartment.last_name === "") {
      notify("Please Enter Last Name");
      return;
    }

    if (addUserDepartment.email === "") {
      notify("Please Enter Email");
      return;
    }

    const params = {
      company: companyId,
      department: Number(selectDepartment),
      role: Number(selectRole),
      first_name: addUserDepartment.first_name,
      last_name: addUserDepartment.last_name,
      email: addUserDepartment.email,
    };

    try {
      setLoading(true);
      const response = await adduserDepartment(params);
      if (response.status === 200 || response.status === 201) {
        notify1(response.data.message);
        getUserList();
        setAddUserModal(false);
        setAddUserModal(!addUserModal);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setAddUserModal(false);
      console.log("err", error);
      notify(error.response.data?.email?.[0]);
    }
  };

  useEffect(() => {
    getUserDepartmentList();
  }, []);

  useEffect(() => {
    getUserList();
  }, [searchUserDataList]);

  const handelUpdateUser = async () => {
    if (updateUser.role_name == "none") {
      notify("Please select role.");
      return;
    }

    if (updateUser.first_name === "") {
      notify("Please Enter First Name");
      return;
    }

    if (updateUser.last_name === "") {
      notify("Please Enter Last Name");
      return;
    }

    if (updateUser.email === "") {
      notify("Please Enter Email");
      return;
    }
    const param = {
      company: companyId,
      department: updateUser.department,
      role: updateUser.role_name,
      first_name: updateUser.first_name,
      last_name: updateUser.last_name,
      email: updateUser.email,
    };
    try {
      setLoading(true);
      const resp = await updateUserDepartment(userRowId, param);
      if (resp.status == 200 || resp.status == 201) {
        console.log("responce", resp.data);
        notify1(resp.data.message);
        setLoading(false);
        getUserList();
        setUpdateUserManagement(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("error", error);
      notify(error.response?.data?.message);
    }
  };

  const handelDeleteUser = async () => {
    try {
      const resp = await deleteUserDepartment(selectedRows);
      if (resp.status == 200 || resp.status == 201) {
        notify1(resp.data.message);
        setOpenDeleteModal(!openDeleteModal);
        window.location.reload(false);
        getUserList();
      }
    } catch (error) {
      toast.error("please select user id", {
        theme: "colored",
        position: toast.POSITION.TOP_RIGHT,
        toastClassName: "custom-toast",
      });
    }
  };
  const handleCloseModal = () => {
    setOpenAllDeleteModal(false);
  };
  const handelDeleteAllUser = async () => {
    const params = {
      user_id: selectedRows,
    };
    try {
      const resp = await deleteMultipleUser(params);
      if (resp.data.success == true) {
        notify1(resp.data.message);
        setOpenAllDeleteModal(false);
        getUserList();
      } else {
        setOpenAllDeleteModal(false);
        getUserList();
        toast.error(resp.data.message, {
          theme: "colored",
          position: toast.POSITION.TOP_RIGHT,
          toastClassName: "custom-toast",
        });
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };

  const handleSelectRow = (rowId) => {
    if (openUpdateUserManagement || openDeleteModal || addUserModal) {
      return true;
    }

    if (!is_admin) {
      const userAccess = UserAccessModule(
        user_access,
        "EHS Modules",
        "User Management"
      );

      if (userAccess == null || userAccess.is_editor === false) {
        notify("You don't have access");
        return true;
      }
    }

    const selectedIndex = selectedRows.indexOf(rowId);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = [...selectedRows, rowId];
    } else {
      newSelectedRows = selectedRows.filter((id) => id !== rowId);
    }

    setSelectedRows(newSelectedRows);
  };

  const handleUserExportReport = async () => {
    setExcelPopup(true);
  };

  const handleSubmitExcelPopup = async () => {
    if (openUpdateUserManagement || openDeleteModal || addUserModal) {
      return true;
    }
    const params = {
      id: selectedRows.length ? selectedRows : selectUserId,
    };
    const resp = await exportUserReport(params);
    if (resp.status == 200 || resp.status == 201) {
      setExcelPopup(false);
      notify1(resp.data.message);
    } else {
      setExcelPopup(false);
    }
  };

  const handleDelete = async () => {
    if (!is_admin) {
      const userAccess = UserAccessModule(
        user_access,
        "EHS Modules",
        "User Management"
      );

      if (userAccess == null || userAccess.is_editor === false) {
        notify("You don't have access");
        return true;
      }
    }
    setOpenAllDeleteModal(!openAllDeleteModal);
  };

  const handleNavigateView = (id) => {
    navigate(`/usermanagement/accessManagement/view/${id}`);
  };

  return (
    <>
      <div>{/*  */}</div>
      <div className="containerWrap">
        <Container maxWidth="xxl">
          <div className="deviceManagementHeader-wrap configure">
            <div className="deviceManagementTitle-wrap">
              <KeyboardBackspaceIcon
                className="backArrow"
                onClick={() => navigate("/")}
              />{" "}
              <Typography
                variant="h5"
                className="deviceManagementTitle"
                gutterBottom
                style={{ margin: "0" }}
                onClick={() => navigate("/")}
              >
                {companyName} - User Management
              </Typography>
            </div>
            <div className="addDevice-buttonWrap">
              <button
                className="manageDepartment-button"
                onClick={() => {
                  if (
                    openUpdateUserManagement ||
                    openDeleteModal ||
                    addUserModal
                  ) {
                    return true;
                  }
                  navigate("/access-management");
                }}
              >
                Access Management
              </button>

              {/* <img src={images.addImage} alt="" className='cloudActiveImg' onClick={handleUserUpdate} /> */}

              {addUserModal ? (
                <img src={images.addImage} alt="" className="cloudActiveImg" />
              ) : (
                <div>
                  <img
                    src={images.whiteplusIcon}
                    alt=""
                    className="cloudActiveImg"
                    onClick={handleAddUser}
                  />
                </div>
              )}
              <img
                src={images.deleteIcon}
                alt=""
                style={{
                  opacity: selectedRows.length == 0 ? "0.5" : "",
                  pointerEvents: selectedRows.length == 0 ? "none" : "",
                }}
                className="cloudActiveImg"
                onClick={() => handleDelete()}
              />
              <img
                src={images.excelLogo}
                alt=""
                className="cloudActiveImg"
                onClick={handleUserExportReport}
              />
            </div>
          </div>
          {/* =============== User Add Modal Start =============== */}
          <ValidatorForm onSubmit={submitUserDepartment}>
            {addUserModal && (
              <div className="box-container">
                <Card className="inviteUserModal">
                  <Box>
                    <Stack direction="row" spacing={{ sm: 42, md: 42, lg: 34 }}>
                      <Typography gutterBottom style={{ display: "none" }}>
                        ..
                      </Typography>
                      <img
                        src={images.closeIcon}
                        alt=""
                        style={{ width: "30px", cursor: "pointer" }}
                        onClick={handleCloseUser}
                      />
                    </Stack>
                    <Typography
                      gutterBottom
                      style={{
                        color: "#0E8951",
                        fontWeight: "600",
                        fontSize: "19px",
                        textAlign: "center",
                      }}
                    >
                      User Management
                    </Typography>
                  </Box>
                  <Box>
                    <div className="useInviteWrap">
                      <label
                        for="exampleInputEmail1"
                        class="form-label userInviteLabel"
                      >
                        <b>Department</b>{" "}
                        <span style={{ color: "#E31E24" }}>*</span>
                      </label>
                      <select
                        class="form-select form-select-lg updateUserDropdown"
                        value={selectDepartment}
                        onChange={(e) => setSelectDepartment(e.target.value)}
                        aria-label=".form-select-lg example"
                      >
                        <option value="none" selected disabled hidden>
                          Select Department
                        </option>
                        {/* <option value="1">Engineer</option>
                                        <option value="2">Admin</option> */}
                        {department.length
                          ? department.map((e) => {
                              return (
                                <option value={e.id}>
                                  {e.department_name}
                                </option>
                              );
                            })
                          : []}
                      </select>
                    </div>
                    <div className="useInviteWrap">
                      <label
                        for="exampleInputEmail1"
                        class="form-label userInviteLabel"
                      >
                        <b>Role</b>
                        <span style={{ color: "#E31E24" }}>*</span>
                      </label>
                      <select
                        class="form-select form-select-lg updateUserDropdown"
                        value={selectRole}
                        onChange={(e) => setSelectRole(e.target.value)}
                        aria-label=".form-select-lg example"
                      >
                        <option value="none" selected disabled hidden>
                          Select Role
                        </option>
                        {/* <option value="1">Engineer</option>
                                        <option value="2">Admin</option> */}
                        {department.length
                          ? department.map((e) => {
                              if (selectDepartment == e.id) {
                                return e.company_roles.map((e1) => {
                                  return (
                                    <option value={e1.id}>
                                      {e1.role_name}
                                    </option>
                                  );
                                });
                              }
                            })
                          : []}
                      </select>
                    </div>
                    <div className="useInviteWrap">
                      <label
                        for="exampleInputEmail1"
                        class="form-label userInviteLabel"
                      >
                        <b>First Name</b>
                        <span style={{ color: "#E31E24" }}>*</span>
                      </label>
                      <input
                        type="text"
                        name="first_name"
                        class="form-control userInviteLabelplaceHolder"
                        id="exampleInputEmail1"
                        placeholder="Enter First Name"
                        onChange={handleChangeuserManagement}
                      />
                    </div>
                    <div className="useInviteWrap">
                      <label
                        for="exampleInputEmail1"
                        class="form-label userInviteLabel"
                      >
                        <b>Last Name</b>
                        <span style={{ color: "#E31E24" }}>*</span>
                      </label>
                      <input
                        type="text"
                        name="last_name"
                        class="form-control userInviteLabelplaceHolder"
                        id="exampleInputEmail1"
                        placeholder="Enter Last Name"
                        onChange={handleChangeuserManagement}
                      />
                    </div>
                    <div className="useInviteWrap">
                      <label
                        for="exampleInputEmail1"
                        class="form-label userInviteLabel"
                      >
                        <b>Email Id</b>
                        <span style={{ color: "#E31E24" }}>*</span>
                      </label>
                      <input
                        type="email"
                        name="email"
                        class="form-control userInviteLabelplaceHolder"
                        id="exampleInputEmail1"
                        placeholder="Enter Email Id"
                        onChange={handleChangeuserManagement}
                      />
                    </div>
                  </Box>
                  <Box className="">
                    <button className="ClientManagement InviteUser-Button">
                      Add User
                    </button>
                  </Box>
                </Card>
              </div>
            )}
          </ValidatorForm>
          {/* =============== User Add Modal End =============== */}
          {/* =============== User Upadte Modal Start =============== */}
          <ValidatorForm onSubmit={handelUpdateUser}>
            {openUpdateUserManagement && (
              <div className="box-container">
                <Card className="inviteUserModal">
                  <Box>
                    <Stack direction="row" spacing={{ sm: 42, md: 42, lg: 34 }}>
                      <Typography gutterBottom style={{ display: "none" }}>
                        ..
                      </Typography>
                      <img
                        src={images.closeIcon}
                        alt=""
                        style={{ width: "30px", cursor: "pointer" }}
                        onClick={handleCloseUserUpdateModel}
                      />
                    </Stack>
                    <Typography
                      gutterBottom
                      style={{
                        color: "#0E8951",
                        fontWeight: "600",
                        fontSize: "19px",
                        textAlign: "center",
                      }}
                    >
                      User Management
                    </Typography>
                  </Box>
                  <Box>
                    <div className="useInviteWrap">
                      <label
                        for="exampleInputEmail1"
                        class="form-label userInviteLabel"
                      >
                        <b>Department</b>{" "}
                        <span style={{ color: "#E31E24" }}>*</span>
                      </label>
                      <select
                        class="form-select form-select-lg updateUserDropdown"
                        value={updateUser.department}
                        name="department"
                        onChange={(e) => handelChangeUpdateUser(e)}
                        aria-label=".form-select-lg example"
                      >
                        <option value="none" selected disabled hidden>
                          Select Department
                        </option>
                        {/* <option value="1">Engineer</option>
                                        <option value="2">Admin</option> */}
                        {department.length
                          ? department.map((e) => {
                              return (
                                <option value={e.id}>
                                  {e.department_name}
                                </option>
                              );
                            })
                          : []}
                      </select>
                    </div>
                    <div className="useInviteWrap">
                      <label
                        for="exampleInputEmail1"
                        class="form-label userInviteLabel"
                      >
                        <b>Role</b>
                        <span style={{ color: "#E31E24" }}>*</span>
                      </label>
                      <select
                        class="form-select form-select-lg updateUserDropdown"
                        value={updateUser.role_name}
                        name="role_name"
                        onChange={(e) => handelChangeUpdateUser(e)}
                        aria-label=".form-select-lg example"
                      >
                        <option value="none" selected disabled hidden>
                          Select Role
                        </option>
                        {/* <option value="1">Engineer</option>
                                        <option value="2">Admin</option> */}
                        {department.length
                          ? department.map((e) => {
                              if (updateUser.department == e.id) {
                                return e.company_roles.map((e1) => {
                                  return (
                                    <option value={e1.id}>
                                      {e1.role_name}
                                    </option>
                                  );
                                });
                              }
                            })
                          : []}
                      </select>
                    </div>
                    <div className="useInviteWrap">
                      <label
                        for="exampleInputEmail1"
                        class="form-label userInviteLabel"
                      >
                        <b>First Name</b>
                        <span style={{ color: "#E31E24" }}>*</span>
                      </label>
                      <input
                        type="text"
                        name="first_name"
                        class="form-control userInviteLabelplaceHolder"
                        id="exampleInputEmail1"
                        placeholder="Enter First Name"
                        value={updateUser.first_name}
                        onChange={(e) => handelChangeUpdateUser(e)}
                      />
                    </div>
                    <div className="useInviteWrap">
                      <label
                        for="exampleInputEmail1"
                        class="form-label userInviteLabel"
                      >
                        <b>Last Name</b>
                        <span style={{ color: "#E31E24" }}>*</span>
                      </label>
                      <input
                        type="text"
                        name="last_name"
                        class="form-control userInviteLabelplaceHolder"
                        id="exampleInputEmail1"
                        placeholder="Enter Last Name"
                        value={updateUser.last_name}
                        onChange={(e) => handelChangeUpdateUser(e)}
                      />
                    </div>
                    <div className="useInviteWrap">
                      <label
                        for="exampleInputEmail1"
                        class="form-label userInviteLabel"
                      >
                        <b>Email Id</b>
                        <span style={{ color: "#E31E24" }}>*</span>
                      </label>
                      <input
                        type="email"
                        name="email"
                        class="form-control userInviteLabelplaceHolder"
                        id="exampleInputEmail1"
                        placeholder="Enter Email Id"
                        value={updateUser.email}
                        onChange={(e) => handelChangeUpdateUser(e)}
                      />
                    </div>
                  </Box>
                  <Box className="">
                    {/* <button className='ClientManagement InviteUser-Button userManagement' onClick={() => handelUpdateUser()} >Update</button> */}
                    <button className="ClientManagement InviteUser-Button userManagement">
                      Update
                    </button>
                  </Box>
                </Card>
              </div>
            )}
          </ValidatorForm>
          {/* =============== User Upadte Modal End =============== */}
          {/* =============== Delete user Modal Start =============== */}
          {openDeleteModal && (
            <div className="box-container">
              <Card className="deleteConfirmation-Modal">
                <Typography
                  variant="h6"
                  gutterBottom
                  className="deleteConfirmation-title"
                >
                  Are You Sure<br></br> You Want To Delete?
                </Typography>
                <div className="pt-3 btn-div">
                  <button className="submit-btn" onClick={handelDeleteUser}>
                    Yes
                  </button>
                  <button
                    className="reset-btn"
                    onClick={handleConfirmationModalCloseModel}
                  >
                    No
                  </button>
                </div>
              </Card>
            </div>
          )}

          {openAllDeleteModal && (
            <div className="box-container">
              <Card className="deleteConfirmation-Modal">
                <Typography
                  variant="h6"
                  gutterBottom
                  className="deleteConfirmation-title"
                >
                  Are You Sure<br></br> You Want To Delete?
                </Typography>
                <div className="pt-3 btn-div">
                  <button className="submit-btn" onClick={handelDeleteAllUser}>
                    Yes
                  </button>
                  <button className="reset-btn" onClick={handleCloseModal}>
                    No
                  </button>
                </div>
              </Card>
            </div>
          )}
          {/* =============== Delete user Modal End =============== */}
          {/* =============== User management table Start =============== */}
          <div className="userManagementTable">
            <Table
              aria-label=""
              className={`deviceManagementTable ${
                addUserModal || openDeleteModal || openUpdateUserManagement
                  ? "disabledRow"
                  : ""
              }`}
            >
              <TableHead>
                <TableRow className="deviceManagementTable-title">
                  <TableCell colSpan={2}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectAll}
                              onChange={(e) =>
                                handleSelectAll(e.target.checked)
                              }
                            />
                          }
                        />
                      </FormGroup>{" "}
                      First Name
                    </div>
                  </TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Department</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Email Id</TableCell>
                  <TableCell>Active</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="First Name"
                      name="first_name"
                      value={searchUserDataList.first_name}
                      onChange={handleSearchUserData}
                    />
                  </TableCell>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Last Name"
                      name="last_name"
                      value={searchUserDataList.last_name}
                      onChange={handleSearchUserData}
                    />
                  </TableCell>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Department"
                      name="department__department_name"
                      value={searchUserDataList.department__department_name}
                      onChange={handleSearchUserData}
                    />
                  </TableCell>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Role"
                      name="user_role__name"
                      value={searchUserDataList.user_role__name}
                      onChange={handleSearchUserData}
                    />
                  </TableCell>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Email Id"
                      name="email"
                      value={userData.email}
                      onChange={handleSearchUserData}
                    />
                  </TableCell>
                  <TableCell className="search-table-row"></TableCell>
                  <TableCell className="search-table-row"></TableCell>
                </TableRow>

                {!isShimmerUserData ? (
                  userData.length ? (
                    userData.map((e) => (
                      <TableRow className="deviceManagementTable-data">
                        <TableCell>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={selectedRows.includes(e.id)}
                                  onChange={() => handleSelectRow(e.id)}
                                />
                              }
                            />
                          </FormGroup>
                        </TableCell>
                        <TableCell>{e.first_name}</TableCell>
                        <TableCell>{e.last_name}</TableCell>
                        <TableCell>{e.department}</TableCell>
                        <TableCell>{e.user_role}</TableCell>
                        <TableCell>{e.email}</TableCell>
                        <TableCell>
                          <Stack
                            direction="row"
                            className="deviceManagementTable-icon "
                          >
                            <Switch
                              checked={e.is_login}
                              size="small"
                              color="success"
                            />
                            {/* <Switch
                              className="deviceManagement-switch"
                              checked={e.is_login}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              height={20}
                              width={40}
                              onColor="#50C878"
                            /> */}
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <Stack
                            direction="row"
                            className="deviceManagementTable-icon gap-2"
                          >
                            <img
                              src={images.editImg}
                              alt=""
                              className="cloudActiveImg"
                              onClick={() => handleUserUpdate(e)}
                            />
                            <img
                              src={images.deleteImg}
                              alt=""
                              className="cloudActiveImg"
                              onClick={() => handleConfirmationModal(e, e.id)}
                            />
                            {/* <img
                                src={images.viewIcon}
                                alt=""
                                className="cloudActiveImg"
                                onClick={() =>
                                  handleNavigateView(e.user_access?.id)
                                }
                              /> */}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow className="deviceManagementTable-data">
                      <TableCell
                        style={{
                          backgroundColor: "white",
                          textAlign: "center",
                        }}
                        colSpan={8}
                      >
                        No Data Found
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <UserManagementTableShimmer itemCount={7} />
                )}
              </TableHead>
              <TableBody></TableBody>
            </Table>
          </div>
          {/* =============== User management table Ends =============== */}
        </Container>
      </div>
      {isLoading && <Loader />}
      {excelPopup && (
        <div className="box-container">
          <Card className="deleteConfirmation-Modal">
            <Typography
              variant="h6"
              gutterBottom
              className="deleteConfirmation-title"
            >
              The requested file will be delivered to your email box.
            </Typography>
            <div className="pt-3 btn-div">
              <button className="submit-btn" onClick={handleSubmitExcelPopup}>
                Ok
              </button>
              <button
                className="reset-btn"
                onClick={() => setExcelPopup(false)}
              >
                Cancel
              </button>
            </div>
          </Card>
        </div>
      )}
    </>
  );
};
export default UserManagement;
