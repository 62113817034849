import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useSelector } from "react-redux";

ChartJS.register(ArcElement, Tooltip, Legend);

export function MaterialCOCPiechart({ analyticsPieChartData,materialColor, getUnitRedux, totalsum,count }) {
 
  const data = {
    labels: analyticsPieChartData.length
      ? analyticsPieChartData.map((row) => row.material.material_description)
      : [],
    datasets: [
      {
        label: "# of Votes",
        data: analyticsPieChartData.length
          ? analyticsPieChartData.map((row) => row.total)
          : [],
        backgroundColor: materialColor,
        borderColor:materialColor,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const dataset = data.datasets[context.datasetIndex];
            const total = dataset.data.reduce(
              (previousValue, currentValue) => previousValue + currentValue
            );
            const currentValue = dataset.data[context.dataIndex];
            const percentage = ((currentValue / total) * 100).toFixed(2) + "%";
            return `${currentValue} (${percentage})`;
          },
        },
      },
      legend: {
        display: false,
        labels: {
          display: false,
        },
      },
      yAxis: {
        stackLabels: {
          enabled: false, // Set to false to remove the stack labels
          style: {
            fontWeight: 'bold',
            color: 'gray',
          },
        },
        title: {
          text: null,
        }
      },
      datalabels: {
        color: "#fff",
        anchor: "center",
        align: "center",
        offset: 0,
        font: {
          weight: "bold",
          size: "14",
        },
      },
      formatter: function (value, context) {
        const dataset = data.datasets[context.datasetIndex];
        const total = dataset.data.reduce(
          (previousValue, currentValue) => previousValue + currentValue
        );
        const currentValue = dataset.data[context.dataIndex];
        const percentage = ((currentValue / total) * 100).toFixed(2) + "%";
        return `${value}\n(${percentage})`;
      },
    },
    cutout: "70%", // Adjust this value to set the width of the doughnut chart
  };

  return (
    <div style={{ width: "100%", height: "195px" }} className="doughnut-chart1" id={count}>
      <>
        <Doughnut
          data={data}
          id="canvas"
          className="donut-chart-css"
          style={{ margin: "auto" }}
          options={options}
        />
        <p className="doughnut-chartText">
          {typeof totalsum === "number"
            ? totalsum.toFixed(2)
            : totalsum}
        </p>
        <p className="doughnut-chartText-unit">{totalsum ? getUnitRedux : ""}</p>
      </>
    </div>
  );
}
