import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    token: "",
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    mobileNoRe: null,
    MFA_UserId: "",
    company_logo: "",
    companyId: "",
    is_super_admin: false,
    is_admin: false,
    is_created_by_super_admin_user_company :false,
    companyName: "",
    companyDescription: "",
    is_redirect: false,
    user_access: [],
    redirectHikPred: '',
    user_country: "",
    user_country_name: "",
    user_country_code_name: "",
    user_country_code: "",
    user_country_currency: "",
    user_country_currency_name: "",
    user_country_currency_symbol: "",

}

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUserDetails(state, { payload }) {
            return { ...state, ...payload };
        },
        clearUserDetails(state) {
            return initialState;
        },
    },
});

export const {
    setUserDetails,
    clearUserDetails,
} = userSlice.actions;

export default userSlice.reducer;


