
// Function to get admin access for a specific module
const getAdminAccess = (user_access, moduleName) => {
    return user_access?.find(user => user.access_module?.access_module_name === moduleName) || null;
};

// Function to handle user access for a specific sub-module
const handleUserAccess = (adminAccess, module) => {
    const accessManagementUser = adminAccess?.sub_module?.find(f => f.sub_module_name === module) || null;
    return accessManagementUser;
};

export const UserAccessModule = (userAccess, mainModule, subModule) => {

    const adminAccessEHSForm = getAdminAccess(userAccess, mainModule);

    // Example usage of handleUserAccess function
    const accessEHSFormUser = handleUserAccess(adminAccessEHSForm, subModule);

    return accessEHSFormUser;
};


