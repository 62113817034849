import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { images } from "../../../config/images";
import CloseIcon from "@mui/icons-material/Close";
import { deleteMaterial, getMaterial, updateMaterialData } from "./services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserAccessModule } from "../../../components/UserAccess/UserAccessmodule";
import { useSelector } from "react-redux";

const Material = (props) => {
  const [updatePopup, setUpdatePopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [materialCount, setMaterialCount] = useState("");
  const [materialId, setMaterialId] = useState("");

  const [filterData, setFilterData] = useState({
    material_code: "",
    material_description: "",
    unit: "",
    hwm_rules_category_no: "",
    created_by__first_name: "",
    created_by__last_name: "",
  });
  const [getMaterialData, setGetMaterialData] = useState([]);
  const [updateMaterailData, setUpdateMaterialData] = useState({
    material_code: "",
    material_description: "",
    unit: "",
    hwm_rules_category_no: "",
  });

  const { user_access, is_admin } = useSelector((state) => state.userState);


  useEffect(() => {
    handlegetMaterialData();
  }, [filterData, page, rowsPerPage, props.count]);

  const notify = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION?.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const notifySuccess = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION?.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const handleUpdateClick = (e) => {
    if (!is_admin) {
      const userAccess = UserAccessModule(
        user_access,
        "EHS Modules",
        "EHS Database"
      );

      if (userAccess == null || userAccess.is_editor === false) {
        notify("You don't have access");
        return true;
      }
    }

    setUpdateMaterialData({
      material_code: e.material_code,
      material_description: e.material_description,
      unit: e.unit,
      hwm_rules_category_no: e.hwm_rules_category_no,
    });
    setMaterialId(e.id);
    setUpdatePopup(true);
  };

  const handleDeleteClick = (e) => {
    if (!is_admin) {
      const userAccess = UserAccessModule(
        user_access,
        "EHS Modules",
        "EHS Database"
      );

      if (userAccess == null || userAccess.is_editor === false) {
        notify("You don't have access");
        return true;
      }
    }

    setMaterialId(e.id);
    setDeletePopup(true);
  };
  const handleSubmit = async () => {
    try {
      const resp = await deleteMaterial(materialId);
      if (resp.status == 200 || resp.status == 201) {
        setDeletePopup(false);
        handlegetMaterialData();
        notifySuccess(resp.data.message);
      }
    } catch (error) {
      notify(error.response.data.message);
      setDeletePopup(false);
    }
  };
  const handleChangeSearch = (e) => {
    setFilterData({ ...filterData, [e.target.name]: e.target.value });
  };

  const handleChangeMaterialData = (e) => {
    setUpdateMaterialData({
      ...updateMaterailData,
      [e.target.name]: e.target.value,
    });
  };

  const handlegetMaterialData = async () => {
    const params = {
      page: page,
      rowsPerPage: rowsPerPage,
    };
    try {
      const resp = await getMaterial(filterData, params);
      if (resp.status == 200 || resp.status == 201) {
        setGetMaterialData(resp.data.payload.results);
        setMaterialCount(resp.data.payload);
      }
    } catch (error) {
      notify(error.response.data.message);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUpdateMaterialData = async () => {
    if (updateMaterailData.material_code == "") {
      notify("Please enter material code");
      return;
    }
    if (updateMaterailData.material_description == "") {
      notify("Please enter material decription");
      return;
    }
    if (updateMaterailData.unit == "") {
      notify("Please enter unit");
      return;
    }
    if (updateMaterailData.hwm_rules_category_no == "") {
      notify("Please enter hwm rules category no.");
      return;
    }
    const params = {
      material_code: updateMaterailData.material_code,
      material_description: updateMaterailData.material_description,
      unit: updateMaterailData.unit,
      hwm_rules_category_no: updateMaterailData.hwm_rules_category_no,
    };
    try {
      const resp = await updateMaterialData(materialId, params);
      if (resp.status == 200 || resp.status == 201) {
        setUpdatePopup(false);
        handlegetMaterialData();
        notifySuccess(resp.data.message);
      }
    } catch (error) {
      notify(error.response.data.message);
    }
  };
  return (
    <>
      <div className="pt-2">
        <div className="table-responsive">
          <Table className="Ehs-form-table">
            <TableHead>
              <TableRow>
                <TableCell>Material Code</TableCell>
                <TableCell>Material Description</TableCell>
                <TableCell>Unit</TableCell>
                <TableCell>HWM RULES Category No</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Edit or Delete</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Filter"
                    name="material_code"
                    value={filterData.material_code}
                    onChange={handleChangeSearch}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Filter"
                    name="material_description"
                    value={filterData.material_description}
                    onChange={handleChangeSearch}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Filter"
                    name="unit"
                    value={filterData.unit}
                    onChange={handleChangeSearch}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Filter"
                    name="hwm_rules_category_no"
                    value={filterData.hwm_rules_category_no}
                    onChange={handleChangeSearch}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Filter"
                    name="created_by__first_name"
                    value={filterData.created_by__first_name}
                    onChange={handleChangeSearch}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  {/* <SearchIcon className="search-icon" /> */}
                  {/* <input
                    type="search"
                    placeholder="Filter"
                    name="created_by__first_name"
                    value={filterData.created_by__first_name}
                    onChange={handleChangeSearch}
                  /> */}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getMaterialData?.map((e) => {
                return (
                  <TableRow>
                    <TableCell>{e.material_code}</TableCell>
                    <TableCell>{e.material_description}</TableCell>
                    <TableCell>{e.unit}</TableCell>
                    <TableCell>{e.hwm_rules_category_no}</TableCell>
                    <TableCell>
                      {e.user_first_name} {e.user_last_name}
                    </TableCell>
                    <TableCell>
                      <div className="d-flex gap-2">
                        <img
                          src={images.editImage}
                          alt=""
                          onClick={() => handleUpdateClick(e)}
                          style={{ cursor: "pointer" }}
                        />
                        <img
                          src={images.deleteImage}
                          alt=""
                          style={{ cursor: "pointer" }}
                          onClick={() => handleDeleteClick(e)}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            className="table-pagination"
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={materialCount ? materialCount.count : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {updatePopup && (
            <div className="box-container">
              <Box className="add-material-box">
                <div className="p-2">
                  <div className="add-material-div">
                    <h6>Update Material</h6>
                    <CloseIcon
                      style={{
                        cursor: "pointer",
                        fontSize: "31px",
                        marginTop: "-5px",
                      }}
                      onClick={() => setUpdatePopup(false)}
                    />
                  </div>
                  <div>
                    <div className="dropdown-area pt-2">
                      <h6>Unit</h6>
                      <input
                        className="materialcode-dropdown"
                        placeholder="Enter Unit"
                        name="unit"
                        value={updateMaterailData.unit}
                        onChange={handleChangeMaterialData}
                      ></input>
                    </div>
                    <div className="dropdown-area pt-2">
                      <h6>Material Code</h6>
                      <input
                        className="materialcode-dropdown"
                        placeholder="Enter Material Code"
                        name="material_code"
                        value={updateMaterailData.material_code}
                        onChange={handleChangeMaterialData}
                      ></input>
                    </div>
                    <div className="dropdown-area pt-2">
                      <h6>HWM RULES Category No</h6>
                      <input
                        className="materialcode-dropdown"
                        placeholder="Enter HWM RULES Category No"
                        name="hwm_rules_category_no"
                        value={updateMaterailData.hwm_rules_category_no}
                        onChange={handleChangeMaterialData}
                      ></input>
                    </div>
                    <div className="dropdown-area pt-2">
                      <h6>Material Description</h6>
                      <textarea
                        rows={3}
                        placeholder="Enter Material Description"
                        className="approve-textarea materialcode-dropdown"
                        name="material_description"
                        value={updateMaterailData.material_description}
                        onChange={handleChangeMaterialData}
                      ></textarea>
                    </div>

                    <div className="pt-2 btn-div">
                      <button
                        className="reset-btn"
                        onClick={() => setUpdatePopup(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="submit-btn"
                        onClick={handleUpdateMaterialData}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </Box>
            </div>
          )}
          {deletePopup && (
            <div className="box-container">
              <Card className="deleteConfirmation-Modal">
                <Typography
                  variant="h6"
                  gutterBottom
                  className="deleteConfirmation-title"
                >
                  Are You Sure<br></br> You Want To Delete?
                </Typography>
                <div className="pt-3 btn-div">
                  <button className="submit-btn" onClick={handleSubmit}>
                    Yes
                  </button>
                  <button
                    className="reset-btn"
                    onClick={() => setDeletePopup(false)}
                  >
                    No
                  </button>
                </div>
              </Card>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default Material;
