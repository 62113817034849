import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useNavigate, useParams } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { images } from "../../../config/images";
// import FooterText from '../../../components/FooterText/FooterText';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  deleteAccessManagement,
  exportAccessManagement,
  getDepartmentBycompany,
} from "../services";
import "./AccessManagement.css";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loader/Loader";
import AccessManagementTableShimmer from "./AccessManagementShimmer";
import { UserAccessModule } from "../../../components/UserAccess/UserAccessmodule";

function AccessManagement() {
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [accessManagementData, setAccessManagementData] = React.useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [search, setSearch] = useState({
    department__department_name: "",
    role__role_name: "",
  });
  const [isLoading, setLoading] = useState(false);
  const [selectUserId, setSelectUserId] = useState([]);
  const [isShimmerAccessManagementData, setisShimmerAccessManagementData] =
    useState(false);
  const [excelPopup, setExcelPopup] = useState(false);
  const navigate = useNavigate();

  const { companyName, companyId, user_access, is_admin } = useSelector(
    (state) => state.userState
  );

  const notify = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION?.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION?.TOP_RIGHT,
      toastClassName: "custom-toast",
    });
  const notifySuccess = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const superAdminAccess = user_access.length
    ? user_access.find(
        (user) => user.access_module?.access_module_name === "Admin Dashboard"
      )
    : null;
  const accessManagementAccess = superAdminAccess
    ? superAdminAccess.sub_module.length
      ? superAdminAccess.sub_module.find(
          (f) => f.sub_module_name == "Access Management"
        )
      : null
    : null;

  // Delete Confirmation Modal
  const handleConfirmationModal = () => {
    if (!is_admin) {
      const userAccess = UserAccessModule(
        user_access,
        "EHS Modules",
        "Role & Access Management"
      );

      if (userAccess == null || userAccess.is_editor === false) {
        notify("You don't have access");
        return true;
      }
    }

    if (!selectedRows.length) {
      notify("Please Select Department");
      return true;
    }
    setOpenDeleteModal(!openDeleteModal);
  };

  const getAccessManagementdata = async () => {
    try {
      setisShimmerAccessManagementData(true);
      const paramReq = {
        company: companyId,
      };
      const resp = await getDepartmentBycompany(paramReq, search);
      if (resp.data.success == true) {
        setTimeout(() => {
          setisShimmerAccessManagementData(false);
        }, 1000);
        setAccessManagementData(resp.data.payload);
        setSelectUserId(resp.data.payload.map((e) => e.id));
      }
    } catch (error) {
      setisShimmerAccessManagementData(false);
      console.log("Error from getAccessManagementdata", error);
    }
  };

  useEffect(() => {
    getAccessManagementdata();
  }, [search]);

  const handleSelectAll = (checked) => {
    if (openDeleteModal) {
      return true;
    }

    if (!is_admin) {
      const userAccess = UserAccessModule(
        user_access,
        "EHS Modules",
        "Role & Access Management"
      );

      if (userAccess == null || userAccess.is_editor === false) {
        notify("You don't have access");
        return true;
      }
    }

    if (checked) {
      const allRowIds = accessManagementData.map((e) => e.id);
      setSelectedRows(allRowIds);
    } else {
      setSelectedRows([]);
    }
    setSelectAll(checked);
  };

  const handleSelectRow = (rowId) => {
    if (openDeleteModal) {
      return true;
    }

    if (!is_admin) {
      const userAccess = UserAccessModule(
        user_access,
        "EHS Modules",
        "Role & Access Management"
      );

      if (userAccess == null || userAccess.is_editor === false) {
        notify("You don't have access");
        return true;
      }
    }

    const selectedIndex = selectedRows.indexOf(rowId);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = [...selectedRows, rowId];
    } else {
      newSelectedRows = selectedRows.filter((id) => id !== rowId);
    }

    setSelectedRows(newSelectedRows);
  };

  // delete Access Management

  const deleteAccessManagementdata = async () => {
    if (!selectedRows.length) {
      notify("Please Select Department");
      return true;
    }

    if (selectedRows.length > 1) {
      notify("Please Select only one Department");
      return true;
    }
    try {
      setLoading(true);
      const resp = await deleteAccessManagement(selectedRows[0]);
      if (resp.data.success == true) {
        setLoading(false);
        window.location.reload(false);
        getAccessManagementdata();
        handleConfirmationModal();
        notifySuccess(resp.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.log("handleSubmit", error);
    }
  };

  // handle Search
  const handleChangeSearch = (e) => {
    setSearch({ ...search, [e.target.name]: e.target.value });
  };

  const handleDepartmentExcel = async () => {
    setExcelPopup(true);
  };
  const handleSubmitExcelPopup = async () => {
    if (openDeleteModal) {
      return true;
    }

    if (!is_admin) {
      const userAccess = UserAccessModule(
        user_access,
        "EHS Modules",
        "Role & Access Management"
      );

      if (userAccess == null || userAccess.is_editor === false) {
        notify("You don't have access");
        return true;
      }
    }

    const params = {
      id: selectedRows.length ? selectedRows : selectUserId,
    };
    try {
      const resp = await exportAccessManagement(params);
      if (resp.status == 200 || resp.status == 201) {
        setExcelPopup(false);
        notifySuccess(resp.data.message);
      }
    } catch (error) {
      setExcelPopup(false);
      notify(error.response.data.message);
    }
  };
  return (
    <>
      <div className="containerWrap">
        <Container maxWidth="xxl">
          <div className="deviceManagementHeader-wrap">
            <div className="deviceManagementTitle-wrap">
              <KeyboardBackspaceIcon
                onClick={() => navigate("/")}
                className="backArrow"
              />{" "}
              <Typography
                variant="h5"
                className="deviceManagementTitle"
                gutterBottom
                style={{ margin: "0" }}
                onClick={() => navigate("/")}
              >
                {companyName} - Access Management
              </Typography>
            </div>
            <div className="addDevice-buttonWrap">
              <button
                className="manageDepartment-button"
                onClick={() => {
                  if (openDeleteModal) {
                    return true;
                  }

                  if (!is_admin) {
                    const userAccess = UserAccessModule(
                      user_access,
                      "EHS Modules",
                      "Role & Access Management"
                    );

                    if (userAccess == null || userAccess.is_editor === false) {
                      notify("You don't have access");
                      return true;
                    }
                  }
                  navigate("/acsessmanagement/managedepartment");
                }}
              >
                Manage Department
              </button>

              <img
                src={images.addImage}
                alt=""
                className="cloudActiveImg"
                onClick={() => {
                  if (openDeleteModal) {
                    return true;
                  }
                  navigate("/acsessmanagement/add-department");
                }}
              />

              <img
                src={images.deleteIcon}
                alt=""
                className="cloudActiveImg"
                onClick={handleConfirmationModal}
              />
              <div>
                <img
                  src={images.excelLogo}
                  alt=""
                  className="cloudActiveImg"
                  onClick={handleDepartmentExcel}
                />
              </div>
            </div>
          </div>
          {/* =============== Delete Confirmation Modal Start =============== */}
          {openDeleteModal && (
            <div className="box-container">
              <Card className="deleteConfirmation-Modal">
                <Typography
                  variant="h6"
                  gutterBottom
                  className="deleteConfirmation-title"
                >
                  Are You Sure<br></br> You Want To Delete?
                </Typography>
                <div className="pt-3 btn-div">
                  <button
                    className="submit-btn"
                    onClick={deleteAccessManagementdata}
                  >
                    Yes
                  </button>
                  <button
                    className="reset-btn"
                    onClick={handleConfirmationModal}
                  >
                    No
                  </button>
                </div>
              </Card>
            </div>
          )}
          {/* =============== Delete Confirmation Modal End =============== */}
          <div className="tableResponsive accessManagement">
            <Table aria-label="" className="deviceManagementTable">
              <TableHead>
                <TableRow className="deviceManagementTable-title">
                  <TableCell colSpan={2}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectAll}
                              onChange={(e) =>
                                handleSelectAll(e.target.checked)
                              }
                            />
                          }
                        />
                      </FormGroup>{" "}
                      Department
                    </div>
                  </TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell className="accessManagement-text">
                    Access
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Department"
                      name="department__department_name"
                      onChange={handleChangeSearch}
                      value={search.department__department_name}
                    />
                  </TableCell>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Role"
                      name="role__role_name"
                      onChange={handleChangeSearch}
                      value={search.role__role_name}
                    />
                  </TableCell>
                  <TableCell className="search-table-row"></TableCell>
                </TableRow>

                {!isShimmerAccessManagementData ? (
                  accessManagementData.length ? (
                    accessManagementData.map((e) => {
                      return (
                        <TableRow className="deviceManagementTable-data">
                          <TableCell className="accessManagement-checkbox">
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={selectedRows.includes(e.id)}
                                    onChange={() => handleSelectRow(e.id)}
                                  />
                                }
                              />
                            </FormGroup>
                          </TableCell>
                          <TableCell>{e.department}</TableCell>
                          <TableCell>{e.role}</TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            <img
                              src={images.editImg}
                              alt=""
                              className="cloudActiveImg"
                              onClick={() => {
                                if (openDeleteModal) {
                                  return true;
                                }

                                if (!is_admin) {
                                  const userAccess = UserAccessModule(
                                    user_access,
                                    "EHS Modules",
                                    "Role & Access Management"
                                  );

                                  if (
                                    userAccess == null ||
                                    userAccess.is_editor === false
                                  ) {
                                    notify("You don't have access");
                                    return true;
                                  }
                                }
                                navigate(`/acsessmanagement/edit/${e.id}`);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow className="deviceManagementTable-data">
                      <TableCell
                        style={{
                          backgroundColor: "white",
                          textAlign: "center",
                        }}
                        colSpan={4}
                      >
                        No Data Found
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <AccessManagementTableShimmer itemCount={7} />
                )}
              </TableHead>
            </Table>
          </div>
          {/* <FooterText className='accessManagement-footer' /> */}
        </Container>
        {excelPopup && (
          <div className="box-container">
            <Card className="deleteConfirmation-Modal">
              <Typography
                variant="h6"
                gutterBottom
                className="deleteConfirmation-title"
              >
                The requested file will be delivered to your email box.
              </Typography>
              <div className="pt-3 btn-div">
                <button className="submit-btn" onClick={handleSubmitExcelPopup}>
                  Ok
                </button>
                <button
                  className="reset-btn"
                  onClick={() => setExcelPopup(false)}
                >
                  Cancel
                </button>
              </div>
            </Card>
          </div>
        )}
        {isLoading && <Loader />}
      </div>
    </>
  );
}

export default AccessManagement;
