import { combineReducers } from "redux";
import userSlice from "../Slice/userSlice";
import headerSlice from "../../container/Header/headerSlice";
import AnalyticsSlice from "../../container/MainEshDashboard/AdvanceAnalytics/AnalyticsSlice";

const mainReducer = combineReducers({
    userState: userSlice,
    headerState : headerSlice,
    analyticsState: AnalyticsSlice
});

const RootReducer = (state, action) => {
    if (action.type === "CLEAR_STORE") {
        // Reset each slice state to its initial state
        state = undefined;
    }
    return mainReducer(state, action);
};

export default RootReducer;