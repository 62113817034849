import axios from "axios";
import { urls } from "../config/urls";
import store from "../redux/store/store";



export const request = axios.create({
    baseURL: urls.apiBaseUrl + "/api/v1",
    headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
    },
});



request.interceptors.request.use(
    function (config) {
        const user = store.getState().userState;
        if (user?.token) {
            config.headers.Authorization = `token ${user?.token}`;
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

// Interceptor for axiosInstance
request.interceptors.response.use(
    (response) => {
        // Return the resolved response as a promise
        return Promise.resolve(response);
    },
    async (error) => {
        // const user = store.getState().userState;
        // // Handle 401 Unauthorized errors
        if (error.response && error.response.status === 401) {
            // Clear local storage and redirect to the home page
            localStorage.clear();
            window.location.href = "/";
        }

        // Propagate the error as a rejected promise
        return Promise.reject(error);
    }
);


export const multipartRequest = axios.create({
    baseURL: urls.apiBaseUrl + "/api/v1",
    headers: {
        "Accept": "application/json",
        "Content-Type": "multipart/form-data",
    },
});

multipartRequest.interceptors.request.use(
    function (config) {
        const user = store.getState().userState;
        if (user?.token) {
            config.headers.Authorization = `token ${user?.token}`;
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

// Interceptor for axiosInstance
multipartRequest.interceptors.response.use(
    (response) => {
        // Return the resolved response as a promise
        return Promise.resolve(response);
    },
    async (error) => {
        const user = store.getState().userState;
        // Handle 401 Unauthorized errors
        if (error.response && error.response.status === 401) {
            // Clear local storage and redirect to the home page
            localStorage.clear();
            window.location.href = "/";
        }

        // Propagate the error as a rejected promise
        return Promise.reject(error);
    }
);
