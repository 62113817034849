import { request } from "../../../api/api";

export const getReportByPlant = (params) => {
    return request.post('/report_management/getReport/', params);
}

export const addReportApi = (params) => {
    return request.post('/report_management/addReport/', params);
}

export const updateReportApi = (id, params) => {
    return request.put(`/report_management/updateReport/${id}/`, params);
}

export const deleteReportApi = (id) => {
    return request.put(`/report_management/deleteReport/${id}/`);
}

export const getDeviceAPI = (params) => {
    return request.post("/dashboard/getDeviceByType/", params);
};
export const createSoftTag = (data) => {
    return request.post('/device_configuration/device/createSoftTag/', data);
}

export const updateSoftTag = (data) => {
    return request.post('/device_configuration/device/updateSoftTag/', data);
}
export const createReportConfigure = (params) => {
    return request.post("/report_management/addReportpConfiguration/", params);
};

export const getReportConfigure = (params) => {
    return request.post("/report_management/getReportConfiguration/", params);
};

export const updateReportConfigure = (id, params) => {
    return request.put(`/report_management/updateReportpConfiguration/${id}/`, params);
};

export const deviceTypeWiseReportGateway = (params) =>{
  return request.post(`/report_management/getDeviceTypeWiseGatewayViewSet/`,params)
}

export const deviceTypeWiseReportMachine = (params)=>{
 return request.post(`/report_management/getDeviceTypeWiseMachineViewSet/`,params)
}

export const deviceTypeWiseDevice = (params)=>{
return request.post(`/report_management/getDeviceTypeWiseDeviceViewSet/`,params)
}


export const getDeviceProduct = (params) =>{
    return request.post(`/report_management/getDeviceProduct/`,params)
}

export const getDeviceTypeByProduct = (params)=>{
    return request.post(`/report_management/getDeviceTypeByProduct/`,params)
} 


