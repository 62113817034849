import { Box, Card, Typography } from "@mui/material";
import React from "react";
import "../SelectPlantShimmer/SelectPlantShimmer.css"
export const ShimmerAddGroup = () => {
  return (
    <>
      <Card className="alarmManagement groupDetails-wrap mb-2 alarm-shimmer-effect">
        <Box className="groupDetails-innerWrap">
          <Box>
            <Typography variant="subtitle2" gutterBottom>
              {" "}
              <div class="header-plant-shimmer"></div>
            </Typography>
            <Typography
              variant="caption"
              display="block"
              gutterBottom
              className="addDeviceDetailsCardetails-text"
            >
              {" "}
              <div class="header-alarm-shimmer"></div>
            </Typography>
          </Box>
          <Box className="alarmManagement groupDetailsBtnWrap">
            <div
              class="configuration-alarm-shimmer"
              style={{ height: "20px" }}
            ></div>

            <div class="shimmer-icon"></div>
            <div class="shimmer-icon"></div>
          </Box>
        </Box>
      </Card>
    </>
  );
};
