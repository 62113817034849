import { Box, Card, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";


const RemoveCurrentUserToken = () => {

    const navigate = useNavigate();
    const params = useParams();


    const handleLogout = () => {
        localStorage.clear();
        navigate(`/create-password/${params.uidb64}/${params.token}/`);
        window.location.reload(false);
    };

    const handleLogoutmodal = () => {
        navigate("/");
    };
    return (
        <>
                <div className="loader-container">
                    <Card className="logoutModal">
                        <Typography variant="h6" gutterBottom className="logoutModal-text">
                            You have to logout first ,<br></br> Are you sure want to logout??
                        </Typography>
                    <Box className="logoutmodal-btnWrap">
                        <button className="logoutBtn" onClick={handleLogout}>
                                Yes
                            </button>
                        <button className="logoutBtn" onClick={handleLogoutmodal}>
                                No
                            </button>
                        </Box>
                    </Card>
                </div>
        </>
    )
}

export default RemoveCurrentUserToken;