import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PersonIcon from "@mui/icons-material/Person";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LogoutIcon from "@mui/icons-material/Logout";
import { Divider, ListItemIcon } from "@mui/material";
import { images } from "../../config/images";
import headerLogo from "../../assets/images/hikar-logo.png";
import profileImg from "../../assets/images/user_male_icon.png";
import expandScreen from "../../assets/images/expandScreen.png";
import "./Header.css";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { ehsFormNotificationAlert, logoutAPI } from "./service";
import { getNotificationHeader } from "../Notification/services";
import Switch from "@mui/material/Switch";
import { toast } from "react-toastify";
import { setHeaderDetails } from "./headerSlice";

export default function Header() {
  const [headerSet, setHeaderSet] = useState();
  const navigate = useNavigate();
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [count, setCount] = useState(0);
  const open = Boolean(anchorEl);

  const dispatch = useDispatch();

  const userState = useSelector((state) => state.userState);
  const { checkSwitch } = useSelector((state) => state.headerState);

  const notifySuccess = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const notify = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION?.TOP_RIGHT,
      toastClassName: "custom-toast",
    });
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = async () => {
    const params = {
      token: userState?.token,
    };

    try {
      const resp = await logoutAPI(params);
      if (resp.status === 200 || resp.status === 201) {
        navigate("/");
        window.location.reload(false);
        localStorage.clear();
      } else {
        console.error("Logout failed with status:", resp.status);
        // Handle other status codes if needed
      }
    } catch (error) {
      console.error("Error occurred during logout:", error);
      // Handle error in logout process
    }
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };

  const openFullscreen = () => {
    const element = document.documentElement; // Adjust this selector as needed

    if (!isFullscreen) {
      try {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        }
        setIsFullscreen(true);
      } catch (error) {
        console.error("Fullscreen request failed:", error);
      }
    } else {
      try {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
        setIsFullscreen(false);
      } catch (error) {
        console.error("Fullscreen exit failed:", error);
      }
    }
  };

  function updateDateTime() {
    var tempDate = new Date();
    var date1 =
      tempDate.getDate() +
      "/" +
      (tempDate.getMonth() + 1) +
      "/" +
      tempDate.getFullYear() +
      ", " +
      " " +
      new Date().toLocaleTimeString("en-US", {
        hour12: false,
        hour: "numeric",
        minute: "numeric",
      });
    document.getElementById("currentDateTime").textContent = date1;
    document.getElementById("currentDateTime").innerHTML = date1;
  }

  setInterval(updateDateTime, 1000);

  useEffect(() => {
    handleCount();
  }, []);

  const handleCount = async () => {
    try {
      const resp = await getNotificationHeader();
      if (resp.status == 200 || resp.status == 201) {
        setCount(resp.data.count);
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  const handleChange = async (value) => {
    const params = {
      is_notification_alert: value,
    };
    try {
      const resp = await ehsFormNotificationAlert(params);
      if (resp.status == 200 || resp.status == 201) {
        notifySuccess(resp.data.message);
        dispatch(
          setHeaderDetails({
            checkSwitch: resp.data.payload.is_notification_alert,
          })
        );
      }
    } catch (error) {
      notify(error.resposne.data.message);
    }
  };

  return (
    <>
      <AppBar position="static" className="headerWrap">
        <Container maxWidth="none">
          <Toolbar disableGutters className="toolbarWrap">
            <div className="headerTitle-wrap">
              <Typography variant="h6" noWrap component="a">
                {userState?.company_logo ? (
                  <img
                    src={userState?.company_logo}
                    alt="logo"
                    className="headerLogo"
                    onClick={() => navigate("/")}
                  />
                ) : (
                  <img
                    src={headerLogo}
                    alt="logo"
                    className="headerLogo"
                    onClick={() => navigate("/")}
                  />
                )}
              </Typography>
              <p className="headerTitle">EHS Dashboard</p>
            </div>
            <Box sx={{ flexGrow: 0 }} className="headerToolbar-wrap">
              {/* {headerImages[headerSet]} */}
              <div className="dateTime-wrap">
                <p id="currentDateTime"></p>
              </div>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => navigate(`/notificationDashboard`)}
                className="notification"
              >
                <img src={images.notificationImg} alt="" />
                <span className="badge">{count > 99 ? "99+" : count}</span>
              </div>
              <div
                className="expandScreen-btn"
                onClick={openFullscreen}
                style={{ cursor: "pointer" }}
              >
                {isFullscreen ? (
                  <img src={images.minimizeScreen} alt="Minimize Screen" />
                ) : (
                  <img src={expandScreen} alt="Expand Screen" />
                )}
              </div>

              <div onClick={handleClick} className="profileToggle-menu">
                <Avatar alt="" src={profileImg} />
                <p className="profileName">
                  {userState?.firstName} {userState?.lastName}
                </p>
                <KeyboardArrowDownIcon className="ToggleIcon" />
              </div>

              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                onClose={handleClose}
                onClick={handleClose}
                open={open}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgb(84, 108, 138))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: -27,
                      right: 0,
                      width: 0,
                      height: 0,
                      borderLeft: "25px solid transparent",
                      borderRight: "25px solid transparent",
                      borderBottom: "50px solid #fff",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem >
                  <div className="profile-item">
                    <Avatar alt="" src={profileImg} className="profile-image" />
                    <div className="profile-detail">
                      <p className="userName">
                        {userState?.firstName} {userState?.lastName}
                      </p>
                      <p className="userEmail">{userState?.email}</p>
                    </div>
                  </div>
                </MenuItem>

                <Divider />
                <MenuItem
                  className="menu-item"
                  sx={{ px: 3, py: 2 }}
                  onClick={() => navigate("/userprofile")}
                >
                  <ListItemIcon>
                    <PersonIcon fontSize="small" />
                  </ListItemIcon>
                  Profile
                </MenuItem>
                <MenuItem
                  className="menu-item"
                  sx={{ px: 3, py: 2 }}
                  // onClick={() => handleSuperAdminDashboard(is_super_admin)}
                >
                  <div style={{ marginLeft: "-20px" }}>
                    <Switch
                      checked={checkSwitch}
                      onClick={() => handleChange(!checkSwitch)}
                      {...label}
                    />
                  </div>
                  Alert Notification
                </MenuItem>
                <MenuItem
                  className="menu-item"
                  sx={{ px: 3, py: 2 }}
                  onClick={() => navigate("/change-password")}
                >
                  <ListItemIcon>
                    <img src={images.passwordImg} alt="" class />
                  </ListItemIcon>
                  Change Password
                </MenuItem>
                <MenuItem
                  className="menu-item"
                  sx={{ px: 3, py: 2 }}
                  onClick={() => handleLogOut()}
                >
                  <ListItemIcon>
                    <LogoutIcon fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}
