import React, { useEffect, useRef, useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Link, useNavigate } from "react-router-dom";
import { images } from "../../config/images";
import SearchIcon from "@mui/icons-material/Search";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { getHistory } from "./services";
import dayjs from "dayjs";
import { addDays } from "date-fns";
import { DateRangePicker } from "react-date-range";

const History = () => {
  const [historyData, setHistoryData] = useState([]);
  const [historyDataCount, setHistoryDataCount] = useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [filterData, setFilterData] = useState({
    created_at__date: "",
    ehs_form__batch_number: "",
    ehs_form__material_code__material_description: "",
    modified_at__date: "",
    search: "",
  });
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [state, setState] = useState({
    selection: {
      startDate: "",
      endDate: "",
      key: "selection",
    },
  });

  const dateFormat = "YYYY-MM-DD";

  const formatStartDate = state.selection.startDate
    ? dayjs(state.selection.startDate).format(dateFormat)
    : "";

  const formatEndDate = state.selection.endDate
    ? dayjs(state.selection.endDate).format(dateFormat)
    : "";

  const handleClickOutside = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setShowDatePicker(false);
    }
  };

  // Add event listener for clicks outside the date picker
  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    handleHistory();
  }, [filterData, page, rowsPerPage, formatStartDate, formatEndDate]);

  const handleHistory = async () => {
    const params = {
      page: page,
      rowsPerPage: rowsPerPage,
      start_datetime: formatStartDate ? `${formatStartDate} 00:00:00` : "",
      end_datetime: formatEndDate ? `${formatEndDate} 23:59:59` : "",
    };
    try {
      const resp = await getHistory(filterData, params);
      if (resp.status == 200 || resp.status == 201) {
        setHistoryData(resp.data.results);
        setHistoryDataCount(resp.data.count);
      }
    } catch (error) {
      setHistoryData([])
      setHistoryDataCount(0)
      console.log("handleSubmit", error);
    }
  };
  const handleChangeSearch = (e) => {
    setFilterData({ ...filterData, [e.target.name]: e.target.value });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      <div className="container-wraper">
        <div className="ehs-form-sec">
          <div
            className="Ehs-form-title"
            onClick={() => navigate("/notificationDashboard")}
          >
            <KeyboardBackspaceIcon />
            <h6 className="mt-1">History</h6>
          </div>
          <div
            ref={inputRef}
            style={{ position: "relative", display: "inline-block" }}
          >
            <input
              type="text"
              readOnly
              value={`${formatStartDate || "Start Date"} - ${
                formatEndDate || "End Date"
              }`}
              onClick={() => setShowDatePicker(!showDatePicker)}
              placeholder="Select a date range"
              className="datepicker-input"
              style={{ cursor: "pointer" }} // Optional styling for better UX
            />
            {showDatePicker && (
              <div style={{ position: "absolute", zIndex: 1000 }}>
                <DateRangePicker
                  className="history-datepicker"
                  ranges={[state.selection]}
                  onChange={(item) =>
                    setState({ selection: { ...item.selection } })
                  }
                />
              </div>
            )}
          </div>
        </div>
        <div className="pt-4 table-responsive">
          <Table className="Ehs-form-table">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Batch Number</TableCell>
                <TableCell>Material Description</TableCell>
                <TableCell>History</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Filter"
                    name="created_at__date"
                    value={filterData.created_at__date}
                    onChange={handleChangeSearch}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Filter"
                    name="search"
                    value={filterData.search}
                    onChange={handleChangeSearch}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Filter"
                    name="ehs_form__batch_number"
                    value={filterData.ehs_form__batch_number}
                    onChange={handleChangeSearch}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Filter"
                    name="ehs_form__material_code__material_description"
                    value={
                      filterData.ehs_form__material_code__material_description
                    }
                    onChange={handleChangeSearch}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Filter"
                    name="modified_at__date"
                    value={filterData.modified_at__date}
                    onChange={handleChangeSearch}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {historyData?.map((e) => {
                return (
                  <TableRow>
                    <TableCell>
                      <Link
                        to={`/ehs-form/history/${e.ehs_form}`}
                        className="batch-link"
                      >
                        {dayjs(e.created_at).format("YYYY-MM-DD")}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {" "}
                      <Link
                        to={`/ehs-form/history/${e.ehs_form}`}
                        className="batch-link"
                      >
                        {dayjs(e.created_at).format("HH:mm:ss")}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {" "}
                      <Link
                        to={`/ehs-form/history/${e.ehs_form}`}
                        className="batch-link"
                      >
                        {e.batch_number}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {" "}
                      <Link
                        to={`/ehs-form/history/${e.ehs_form}`}
                        className="batch-link"
                      >
                        {e.material_description}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {" "}
                      <Link
                        to={`/ehs-form/history/${e.ehs_form}`}
                        className="batch-link"
                      >
                        {dayjs(e.modified_at).format("YYYY-MM-DD")}
                      </Link>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>{" "}
        <TablePagination
          className="table-pagination"
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={historyDataCount ? historyDataCount : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default History;
