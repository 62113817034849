import React, { useState } from "react";
import CreatePassword from "./CreatePassword";
import GetOtp from "./GetOtp";
import ReEnterPassword from "./ReEnterPassword";

const RedirectMainSite = () => {


    const [currentState, setCurrentState] = useState("");
    const [email, setEmail] = useState("");
    const [OTP, setOTP] = useState("");
    const [userDetail, setUserdetail] = useState("")

    const onNextClick = (nextState) => {
        setCurrentState(nextState);
    };

    const currentComponent = () => {
        switch (currentState) {
            case "GetOtp":
                return (
                    <GetOtp
                        email={email}
                        OTP={OTP}
                        setOTP={setOTP}
                        onNextClick={(msg) => onNextClick(msg)}
                        onCancelClick={(msg) => onNextClick(msg)}
                        userDetail={userDetail}

                    />
                );
            case "ReEnterPassword":
                return (
                    <ReEnterPassword
                        setEmail={setEmail}
                        email={email}
                        OTP={OTP}
                        setOTP={setOTP}
                        onCancelClick={(msg) => onNextClick(msg)}
                        userDetail={userDetail}
                    />
                );

            default:
                return <CreatePassword
                    setEmail={setEmail}
                    onNextClick={(msg) => onNextClick(msg)}
                    setUserdetail={setUserdetail}
                />
        }
    };

    return (
        <>
            {currentComponent()}
        </>
    )
}

export default RedirectMainSite;