import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useNavigate, useParams } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Switch } from "@mui/material";
import { images } from "../../../../config/images";
import "./AddAccessManagement.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getAccessModule,
  getDepartment,
  addAccessManagement,
  getAccessManagementById,
} from "../../services";
import { useSelector } from "react-redux";
import Textshimmer from "../../../../components/Shimmer/TextShimmer/TextShimmer";
import { editAccessManagement } from "../../../UserProfile/service";
import Loader from "../../../../components/Loader/Loader";

function AddAccessManagement() {
  const navigate = useNavigate();
  const [openAddDepartmentModal, setOpenAddDepartmentModal] =
    React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [department, setDepartment] = useState([]);
  const [selectDepartment, setSelectDepartment] = useState("none");
  const [accessModule, setAccessModule] = useState([]);
  const [subModule, setSubModule] = useState([]);
  const [accessModuleId, setAccessModuleId] = useState("");
  const [selectAccessManagement, setSelectAccessManagement] = useState([]);
  const [selectRole, setSelectRole] = useState("none");
  const [isLoading, setLoading] = useState(false);
  const [isShimmerAccessModule, setisShimmerAccessModule] = useState(false);
  console.log("selectAccessManagement", selectAccessManagement);

  console.log("subModule", accessModuleId);

  const params = useParams();

  const { companyId } = useSelector((state) => state.userState);
  const handleOpenaddDepartmentModal = () => {
    setOpenAddDepartmentModal(!openAddDepartmentModal);
  };

  const notify = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast?.POSITION?.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  useEffect(() => {
    // Find the specific plant that matches the plantId
    const matchedAccessModule = accessModule.length
      ? accessModule.find((e) => e.id === accessModuleId)
      : null;

    if (matchedAccessModule) {
      // If the matchedPlant exists, check if its device array is empty
      setSubModule(
        matchedAccessModule.sub_module.length
          ? matchedAccessModule.sub_module
          : []
      );
    }
  }, [accessModule, accessModuleId]);

  // handleSelectDepartment
  const handleSelectDepartment = (e) => {
    setSelectRole("none");
    setSelectDepartment(e.target.value);
  };
  // Delete Confirmation Modal
  const handleConfirmationModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  const getDepartmentList = async () => {
    const paramReq = {
      company: companyId,
    };
    const response = await getDepartment(paramReq);
    if (response.status == 200 || response.status == 201) {
      setDepartment(response.data.payload);
    }
  };

  const getAccessmodule = async () => {
    try {
      setisShimmerAccessModule(true);
      const resp = await getAccessModule();
      if (resp.status == 200 || resp.status == 201) {
        setTimeout(() => {
          setisShimmerAccessModule(false);
        }, 1000);
        setAccessModule(resp.data.payload);
      }
    } catch (error) {
      setisShimmerAccessModule(false);
    }
  };
  useEffect(() => {
    getDepartmentList();
    getAccessmodule();
  }, []);

  const getAccessManagementByIdData = async () => {
    const resp = await getAccessManagementById(params.id);
    if (resp.status == 200) {
      setSelectDepartment(resp.data.department_id);
      setSelectRole(resp.data.role_id);

      const selectedData = resp.data.access_module_management.map((e) => {
        return {
          access_module: e.access_module.id,
          sub_module: e.sub_module.map((e) => e.id),
          is_viewer: e.is_viewer,
          is_editor: e.is_editor,
        };
      });
      setSelectAccessManagement(selectedData);
    }
  };

  useEffect(() => {
    if (params.id) {
      getAccessManagementByIdData();
    }
  }, []);

  const submitEditAccessManagement = async () => {
    if (selectDepartment == "none") {
      notify("Please Select Department");
      return true;
    }

    if (selectRole == "none") {
      notify("Please Select Role");
      return true;
    }

    const param = {
      department: selectDepartment,
      role: selectRole,
      access_management: selectAccessManagement.map((item) => {
        // If sub_module contains -1, filter it out
        if (item.sub_module.includes(-1)) {
          item.sub_module = item.sub_module.filter((value) => value !== -1);
        }
        return item;
      }),
      company: companyId,
    };

    try {
      setLoading(true);
      const resp = await editAccessManagement(params.id, param);

      if (resp.data.success == true) {
        setLoading(false);
        navigate("/access-management");
      }
    } catch (error) {
      setLoading(false);
      notify(error.response.data.message);
    }
  };

  const handleSelectAccessManagement = (accessModuleId) => {
    setSelectAccessManagement((prevState) => {
      // Check if an object with the same access_module already exists
      const existingObjectIndex = prevState.findIndex(
        (item) => item.access_module === accessModuleId
      );

      // If it exists, remove the object by filtering out the matching item
      if (existingObjectIndex !== -1) {
        return prevState.filter((item, index) => index !== existingObjectIndex);
      }

      // If it doesn't exist, add a new object with the given accessModuleId
      const newAccessManagement = {
        access_module: accessModuleId,
        sub_module: [],
        is_viewer: false,
        is_editor: false,
      };

      return [...prevState, newAccessManagement];
    });
    setAccessModuleId(accessModuleId)
  };

  const handleSetAccessViewer = (accessModuleId, is_viewer) => {
    // Extract all the id's from the selectAccessManagement array

    setSelectAccessManagement((prevState) =>
      prevState.map((item) => {
        // Check if the access_module matches the current accessModuleId
        if (item.access_module === accessModuleId) {
          return {
            ...item,
            is_viewer
          };
          // Update the is_viewer value based on the provided parameter
        }
        return item; // Return the unchanged item if access_module doesn't match
      })
    );
  };

  const handleSetAccessEditer = (accessModuleId, is_editor) => {
 
    setSelectAccessManagement((prevState) =>
      prevState.map((item) => {
        // Check if the access_module matches the current accessModuleId
        if (item.access_module === accessModuleId) {
          // Update the is_viewer value based on the provided parameter
          return {
            ...item,
            is_editor,
            is_viewer: true,
          };
        }
        return item; // Return the unchanged item if access_module doesn't match
      })
    );
  };

  const handleSubModuleSetId = (accessModuleId, id, accessModuleName) => {
    console.log("accessModuleName", accessModuleName);
    setSelectAccessManagement((prevState) =>
      prevState.map((item) => {
        // Check if the access_module matches the current accessModuleId
        if (item.access_module === accessModuleId) {
          // Check if the id is already in the sub_module array
          const subModuleIndex = item.sub_module.indexOf(id);
          if (subModuleIndex !== -1) {
            // Remove the id from the sub_module array
            const newSubModule = [...item.sub_module];
            newSubModule.splice(subModuleIndex, 1);
            return { ...item, sub_module: newSubModule };
          } else {
            // Add the id to the sub_module array
            return {
              ...item,
              is_viewer: true,
              is_editor: true,
              sub_module:
                accessModuleName == "EHS Forms"
                  ? [id]
                  : [...item.sub_module, id],
            };
          }
        }
        return item; // Return the unchanged item if access_module doesn't match
      })
    );
  };

  const isViewerChecked = selectAccessManagement.length
    ? selectAccessManagement.find((f) => f.access_module == accessModuleId)
      ? selectAccessManagement.find((f) => f.access_module == accessModuleId)
          .is_viewer
      : false
    : false;
  const isEditorChecked = selectAccessManagement.length
    ? selectAccessManagement.find((f) => f.access_module == accessModuleId)
      ? selectAccessManagement.find((f) => f.access_module == accessModuleId)
          .is_editor
      : false
    : false;
  
  console.log("isViewerCheckedisViewerChecked", isViewerChecked);

  const submitAddAccessManagement = async () => {
    if (selectDepartment == "none") {
      notify("Please Select Department");
      return true;
    }

    if (selectRole == "none") {
      notify("Please Select Role");
      return true;
    }

    const param = {
      department: selectDepartment,
      role: selectRole,
      company: companyId,
      access_management: selectAccessManagement,
    };

    try {
      setLoading(true);
      const resp = await addAccessManagement(param);

      if (resp.data.success == true) {
        setLoading(false);
        navigate("/access-management");
      }
    } catch (error) {
      setLoading(false);
      notify(error.response.data.message);
    }
  };
  return (
    <>
      <div>
        <ToastContainer autoClose={4000}></ToastContainer>
      </div>
      <div className="containerWrap">
        <Container maxWidth="xxl">
          <div className="deviceManagementHeader-wrap">
            <div className="deviceManagementTitle-wrap">
              <KeyboardBackspaceIcon
                onClick={() => {
                  navigate("/access-management");
                }}
                className="backArrow"
              />{" "}
              <Typography
                variant="h5"
                className="deviceManagementTitle"
                gutterBottom
                style={{ margin: "0" }}
                onClick={() => {
                  navigate("/access-management");
                }}
              >
                Access Management
              </Typography>
            </div>
            <div className="addDevice-buttonWrap">
              <button
                className="manageDepartment-button"
                onClick={() => navigate("/acsessmanagement/managedepartment")}
              >
                Manage Department
              </button>
              <img src={images.excelLogo} alt="" className="cloudActiveImg" />
            </div>
          </div>
          {/* =============== Add Department Modal Start =============== */}
          {openAddDepartmentModal && (
            <div className="box-container">
              <Card className="addDepartment-card">
                <Box>
                  {/* <Stack direction="row" spacing={{ sm: 14, md: 20, lg: 25 }}>
                    </Stack> */}
                  <Box>
                    <Stack direction="row" spacing={{ sm: 42, md: 42, lg: 38 }}>
                      <Typography gutterBottom style={{ display: "none" }}>
                        ..
                      </Typography>
                      <img
                        src={images.closeIcon}
                        alt=""
                        style={{ width: "30px", cursor: "pointer" }}
                        onClick={handleOpenaddDepartmentModal}
                      />
                    </Stack>
                    <Typography
                      gutterBottom
                      style={{
                        color: "#0E8951",
                        fontWeight: "600",
                        fontSize: "19px",
                        textAlign: "center",
                      }}
                    >
                      Add Department
                    </Typography>
                  </Box>
                  <div className="departmentWrap">
                    <label for="exampleInputEmail1" class="form-label">
                      Department <span>*</span>
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter Department"
                    ></input>
                  </div>
                  <button className="addDepartmentButton">
                    Add Department
                  </button>
                </Box>
              </Card>
            </div>
          )}
          {/* =============== Add Department Modal End =============== */}
          {/* =============== Delete Confirmation Modal Start =============== */}
          {openDeleteModal && (
            <div className="box-container">
              <Card className="deleteConfirmation-Modal">
                <Typography
                  variant="h6"
                  gutterBottom
                  className="deleteConfirmation-title"
                >
                  Are You Sure<br></br> You Want To Delete?
                </Typography>
                <div className="pt-3 btn-div">
                  <button
                    className="submit-btn"
                    onClick={handleConfirmationModal}
                  >
                    Yes
                  </button>
                  <button
                    className="reset-btn"
                    onClick={handleConfirmationModal}
                  >
                    No
                  </button>
                </div>
              </Card>
            </div>
          )}
          {/* =============== Delete Confirmation Modal End =============== */}
          <Grid container style={{ paddingTop: "20px" }}>
            <Grid item xs={12}>
              <Card className="manageDepartment-card">
                <Grid
                  container
                  style={{
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    paddingTop: "30px",
                    paddingBottom: "30px",
                  }}
                >
                  <Grid item sm={12} md={4} lg={4} xl={4}>
                    <Typography variant="subtitle2" gutterBottom>
                      <b>Department</b>{" "}
                      <span style={{ color: "#E31E24" }}>*</span>
                    </Typography>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      className="addAccessManagement selectDropDown"
                      value={selectDepartment}
                      onChange={(e) => handleSelectDepartment(e)}
                    >
                      <option selected disabled hidden value="none">
                        Select Department
                      </option>
                      {department.map((e, i) => {
                        return (
                          <option value={e.id}>{e.department_name}</option>
                        );
                      })}
                    </select>
                    <Box className="accessDepartment-textWrap">
                      <div>
                        <Typography variant="subtitle2" gutterBottom>
                          <b>Select Access Module</b>{" "}
                          <span style={{ color: "#E31E24" }}>*</span>
                        </Typography>
                      </div>
                      {/* <div>
                        <img
                          src={images.addIcon}
                          alt=""
                          className="addDeviceImg"
                          style={{ cursor: "pointer" }}
                        />
                      </div> */}
                    </Box>
                    <div className="manageDepartment-wrap">
                      {!isShimmerAccessModule ? (
                        accessModule.length ? (
                          accessModule.map((e1, i) => {
                            const isChecked = selectAccessManagement.find(
                              (f) => f.access_module == e1.id
                            )
                              ? true
                              : false;
                            return (
                              <div
                                className="addAccessManagement-fieldWrap"
                                onClick={() => setAccessModuleId(e1.id)}
                              >
                                {accessModuleId == e1.id && (
                                  <img
                                    src={images.activeDeviceimg}
                                    alt=""
                                    className="activeAccessDepartmentImg"
                                  />
                                )}
                                <div className="manageDepartment-field">
                                  <Typography
                                    variant="body2"
                                    gutterBottom
                                    style={{ margin: "0" }}
                                  >
                                    <Checkbox
                                      checked={isChecked}
                                      onClick={() =>
                                        handleSelectAccessManagement(e1.id)
                                      }
                                    ></Checkbox>{" "}
                                    {e1.access_module_name}
                                  </Typography>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <>No Data Found</>
                        )
                      ) : (
                        <Textshimmer height="45px" width="90%" itemCount={5} />
                      )}
                    </div>
                  </Grid>
                  <Grid item sm={12} md={8} lg={8} xl={8}>
                    <Typography variant="subtitle2" gutterBottom>
                      <b>Role</b> <span style={{ color: "#E31E24" }}>*</span>
                    </Typography>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      className="addAccessManagement selectDropDown Roles"
                      value={selectRole}
                      onChange={(e) => setSelectRole(e.target.value)}
                    >
                      <option selected disabled hidden value="none">
                        Select Role
                      </option>
                      {department.length
                        ? department.map((e, i) => {
                            if (selectDepartment == e.id) {
                              return e.company_roles.map((e1) => {
                                return (
                                  <option value={e1.id}>{e1.role_name}</option>
                                );
                              });
                            }
                          })
                        : []}
                    </select>
                    <Typography variant="subtitle2" gutterBottom>
                      <b>Select Sub Module</b>{" "}
                      <span style={{ color: "#E31E24" }}>*</span>
                    </Typography>
                    <div className="managementDepartment-card">
                      <div className="accessManagement switchWrap">
                        <Grid container spacing={2} pt={4}>
                          <Grid item xs={4} sm={6}>
                            <Typography
                              variant="body2"
                              gutterBottom
                              style={{ margin: "0" }}
                            >
                              Viewer
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Switch
                              onClick={(e) =>
                                handleSetAccessViewer(
                                  accessModuleId,
                                  !isViewerChecked
                                )
                              }
                              // defaultChecked
                              checked={isViewerChecked}
                              size="small"
                              color="success"
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={4} sm={6}>
                            <Typography
                              variant="body2"
                              gutterBottom
                              style={{ margin: "0" }}
                            >
                              Editor
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Switch
                              onClick={(e) =>
                                handleSetAccessEditer(
                                  accessModuleId,
                                  !isEditorChecked
                                )
                              }
                              checked={isEditorChecked}
                              size="small"
                              color="success"
                            />
                          </Grid>
                        </Grid>
                      </div>
                      <Grid container className="pt-3">
                        {accessModule.length
                          ? accessModule.map((e, i) => {
                              if (accessModuleId === e.id) {
                                const getAccessManagement =
                                  selectAccessManagement.find(
                                    (f) => f.access_module === accessModuleId
                                  );

                                const subModules = e.sub_module.map((e1) => {
                                  const isChecked = getAccessManagement
                                    ? getAccessManagement.sub_module.includes(
                                        e1.id
                                      )
                                    : false;

                                  return (
                                    <Grid
                                      item
                                      xs={6}
                                      sm={3}
                                      md={3}
                                      lg={3}
                                      xl={3}
                                      key={e1.id}
                                    >
                                      <Box className="managementDepartment roles ">
                                        <Typography variant="body2">
                                          <Checkbox
                                            onClick={() =>
                                              handleSubModuleSetId(
                                                accessModuleId,
                                                e1.id,
                                                e.access_module_name
                                              )
                                            }
                                            checked={isChecked}
                                          />
                                          {e1.sub_module_name}
                                        </Typography>
                                      </Box>
                                    </Grid>
                                  );
                                });

                                return (
                                  <Grid container spacing={2}>
                                    {subModules}
                                  </Grid>
                                );
                              }
                            })
                          : []}
                      </Grid>
                    </div>
                    <button
                      className="Plantmanagemet submit"
                      onClick={() =>
                        params.id
                          ? submitEditAccessManagement()
                          : submitAddAccessManagement()
                      }
                    >
                      Submit
                    </button>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Container>
        {isLoading && <Loader />}
      </div>
    </>
  );
}
export default AddAccessManagement;
