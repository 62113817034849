import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import { images } from "../../../config/images";
import CloseIcon from "@mui/icons-material/Close";
import {
  deleteContainerAPI,
  getContainer,
  updateContainerDataAPI,
} from "./services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserAccessModule } from "../../../components/UserAccess/UserAccessmodule";
import { useSelector } from "react-redux";

const Container = (props) => {
  const [updateContainer, setUpdateContainer] = useState(false);
  const [deleteContainer, setDeleteContainer] = useState(false);
  const [getContainerData, setGetContainerData] = useState([]);
  const [containerCount, setContainerCount] = useState();
  const [containerId, setContainerId] = useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filterData, setFilterData] = useState({
    container_code: "",
    container_name: "",
    created_by__first_name: "",
  });
  const [updateContainerData, setUpdateContainerData] = useState({
    container_code: "",
    container_name: "",
  });

  const { user_access, is_admin } = useSelector((state) => state.userState);

  const notify = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION?.TOP_RIGHT,
      toastClassName: "custom-toast",
    });
  const notifySuccess = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION?.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const handleSubmit = async () => {
    try {
      const resp = await deleteContainerAPI(containerId);
      if (resp.status == 200 || resp.status == 201) {
        setDeleteContainer(false);
        handleGetContainerData();
        notifySuccess(resp.data.message);
      }
    } catch (error) {
      notify(error.response.data.message);
      setDeleteContainer(false);
    }
  };
  useEffect(() => {
    handleGetContainerData();
  }, [filterData, page, rowsPerPage, props.count]);

  const handleEditClick = (e) => {
    if (!is_admin) {
      const userAccess = UserAccessModule(
        user_access,
        "EHS Modules",
        "EHS Database"
      );

      if (userAccess == null || userAccess.is_editor === false) {
        notify("You don't have access");
        return true;
      }
    }

    setUpdateContainerData({
      container_code: e.container_code,
      container_name: e.container_name,
    });
    setContainerId(e.id);
    setUpdateContainer(true);
  };

  const handleChangeSearch = (e) => {
    setFilterData({ ...filterData, [e.target.name]: e.target.value });
  };
  const handleGetContainerData = async () => {
    const params = {
      page: page,
      rowsPerPage: rowsPerPage,
    };
    try {
      const resp = await getContainer(filterData, params);
      if (resp.status == 200 || resp.status == 201) {
        setGetContainerData(resp.data.payload.results);
        setContainerCount(resp.data.payload);
      }
    } catch (error) {
      notify(error.response.data.message);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangeContainerData = (e) => {
    setUpdateContainerData({
      ...updateContainerData,
      [e.target.name]: e.target.value,
    });
  };
  const handleUpdateContainer = async () => {
    if (updateContainerData.container_code == "") {
      notify("Please Enter Location Code");
      return;
    }
    if (updateContainerData.container_name == "") {
      notify("Please Enter Location Name");
      return;
    }
    const params = {
      container_code: updateContainerData.container_code,
      container_name: updateContainerData.container_name,
    };
    try {
      const resp = await updateContainerDataAPI(containerId, params);
      if (resp.status == 200 || resp.status == 201) {
        setUpdateContainer(false);
        handleGetContainerData();
        notifySuccess(resp.data.message);
      }
    } catch (error) {
      notify(error.response.data.message);
    }
  };

  const handleDeleteContainer = (e) => {
    if (!is_admin) {
      const userAccess = UserAccessModule(
        user_access,
        "EHS Modules",
        "EHS Database"
      );

      if (userAccess == null || userAccess.is_editor === false) {
        notify("You don't have access");
        return true;
      }
     
    }

    setContainerId(e.id);
    setDeleteContainer(true);
  };
  return (
    <>
      <div className="pt-2">
        <div className="table-responsive">
          <Table className="Ehs-form-table">
            <TableHead>
              <TableRow>
                <TableCell>Container Code</TableCell>
                <TableCell>Container Name</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Edit or Delete</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Filter"
                    name="container_code"
                    value={filterData.container_code}
                    onChange={handleChangeSearch}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Filter"
                    name="container_name"
                    value={filterData.container_name}
                    onChange={handleChangeSearch}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Filter"
                    name="created_by__first_name"
                    value={filterData.created_by__first_name}
                    onChange={handleChangeSearch}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input type="search" placeholder="Filter" name="last_name" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getContainerData?.map((e) => {
                return (
                  <TableRow>
                    <TableCell>{e.container_code}</TableCell>
                    <TableCell>{e.container_name}</TableCell>
                    <TableCell>
                      {e.user_first_name} {e.user_last_name}
                    </TableCell>
                    <TableCell>
                      {" "}
                      <div className="d-flex gap-2">
                        <img
                          src={images.editImage}
                          onClick={() => handleEditClick(e)}
                          alt=""
                          style={{ cursor: "pointer" }}
                        />
                        <img
                          src={images.deleteImage}
                          onClick={() => handleDeleteContainer(e)}
                          alt=""
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            className="table-pagination"
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={containerCount ? containerCount.count : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {updateContainer && (
            <div className="box-container">
              <Box className="add-material-box">
                <div className="p-2">
                  <div className="add-material-div">
                    <h6>Update Container</h6>
                    <CloseIcon
                      style={{
                        cursor: "pointer",
                        fontSize: "31px",
                        marginTop: "-5px",
                      }}
                      onClick={() => setUpdateContainer(false)}
                    />
                  </div>
                  <div>
                    <div className="dropdown-area pt-2">
                      <h6>Container Name</h6>
                      <input
                        className="materialcode-dropdown"
                        placeholder="Enter Container Name"
                        name="container_name"
                        value={updateContainerData.container_name}
                        onChange={handleChangeContainerData}
                      ></input>
                    </div>
                    <div className="dropdown-area pt-2">
                      <h6>Container Code</h6>
                      <input
                        className="materialcode-dropdown"
                        placeholder="Enter Container Code"
                        name="container_code"
                        value={updateContainerData.container_code}
                        onChange={handleChangeContainerData}
                      ></input>
                    </div>

                    <div className="pt-3 btn-div">
                      <button
                        className="reset-btn"
                        onClick={() => setUpdateContainer(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="submit-btn"
                        onClick={handleUpdateContainer}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </Box>
            </div>
          )}
          {deleteContainer && (
            <div className="box-container">
              <Card className="deleteConfirmation-Modal">
                <Typography
                  variant="h6"
                  gutterBottom
                  className="deleteConfirmation-title"
                >
                  Are You Sure<br></br> You Want To Delete?
                </Typography>
                <div className="pt-3 btn-div">
                  <button className="submit-btn" onClick={handleSubmit}>
                    Yes
                  </button>
                  <button
                    className="reset-btn"
                    onClick={() => setDeleteContainer(false)}
                  >
                    No
                  </button>
                </div>
              </Card>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Container;
