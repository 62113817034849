import { request } from "../../api/api"

export const signInApi = (data) => {
    return request.post('/user/logIn/', data)
}

export const forgotPasswordApi = (data) => {
    return request.post('/user/forgotPassword/', data)
}

export const otpVerifyApi = (data) => {
    return request.post('/user/verifyOtp/', data)
}

export const newPasswordApi = (data) => {
    return request.post('/user/updatePassword/', data)
}


// login 2 step verify
export const login2StepApi = (data) => {
    return request.post('/user/verifyMobileOTP/', data)
}

// login mfa verify
export const loginMfaApi = (data) => {
    return request.post('/user/validateMFA/', data)
}

// UIDBy userInfo
export const getUidByUserInfo = (data) => {
    return request.post('/user/userCreatePassword/', data)
}

export const createPassworOtpdApi = (data) => {
    return request.post('/user/createPasswordOTP/', data)
}

export const accOTPVerifyApi = (data) => {
    return request.post('/user/verifyOtp/', data)
}
export const redirectUserDetailApi = (data) => {
    return request.post('/user/userData/', data)
}

// resend OTP

export const resendOtpApi = (data) => {
    return request.post('/user/reSendOTP/', data)
}

export const logoutAPI = (data) => {
    return request.post(`/user/logOut/`, data)
}
