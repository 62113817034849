import { request } from "../../../api/api";
import { sapApi } from "../../../config/urls";

export const addEHSForm = (params) => {
  return request.post(`/ehs_form/addEHSForm/`, params);
};
export const approveEHSForm = (params) => {
  return request.post(`/ehs_form/approveEHSForm/`, params);
};
export const getEHSApprovalHistory = (params) => {
  return request.post(`/ehs_form/getEHSApprovalHistory/`, params);
};
export const getEHSFormDetails = (filterData, params) => {
  return request.get(`/ehs_form/getEHSFormDetails/?${filterData}&page=${params.page + 1}&page_size=${params.rowsPerPage}&start_datetime=${params.start_datetime}&end_datetime=${params.end_datetime}`);
};
export const getEHSFormDataAPI = (id) => {
  return request.get(`/ehs_form/getEHSFormDetails/${id}`);
};
export const getMaterialDATA = () => {
  return request.get(`/ehs_database/getMaterial/`);
};
export const getLocationDATA = () => {
  return request.get(`/ehs_database/getLocation/`);
};
export const getContainerDATA = () => {
  return request.get(`/ehs_database/getContainer/`);
};

export const getAuthorityAPI = () => {
  return request.get(`/ehs_form/getAuthorityList/`);
};
export const SI_Waste_posting_Api = data => {
  return sapApi.post("/sap_po_rp/SI_WASTE_POSTING", data);
}
export const redirectNotiToEHSForm = (params)=>{
  return request.post(`/ehs_form/redirectNotiToEHSForm/`,params)
}
export const exportEHSFormExcel =(params) =>{
  return request.post(`/ehs_form/exportEHSFormExcelReport/`,params)
}
export const ehsFormHistoryPDF =(params) =>{
  return request.post(`/ehs_form/ehsFormHistoryPDF/`,params)
}
//SAP API
export const SI_BULK_MOVEMENT_KHAN_Api = data => {
  return sapApi.post("/sap_po_rp/SI_BULK_MOVEMENT_KHAN", data);
}
export const getMaterialList =() =>{
  return request.get(`/ehs_form/getMaterialList/`)
}

export const getLocationList =() =>{
  return request.get(`/ehs_form/getLocationList/`)
}

export const getContainerList =() =>{
  return request.get(`/ehs_form/getContainerList/`)
}