import React, { useEffect, useState } from "react";
import logo from "../../../assets/images/ElectrothermLogo-sm.png";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Link, useNavigate, useParams } from "react-router-dom";
import { SliderAuth } from "../../../components/SliderAuth/SliderAuth";
import { createPassworOtpdApi, getUidByUserInfo } from "../services";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./RedirectUser.css";
import Loader from "../../../components/Loader/Loader";

export default function CreatePassword({
  onNextClick,
  setEmail,
  setUserdetail,
}) {
  const [userInfo, setuserInfo] = useState();
  const [isLoading, setLoading] = useState(false);
  const [showLoginLink, setShowLoginLink] = useState(false);
  const navigate = useNavigate();

  const notify = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const notifySuccess = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const param = useParams();

  const getUIDByUserInfo = async () => {
    setLoading(true);
    const uidObj = {
      uidb64: param.uidb64,
    };
    try {
      const resp = await getUidByUserInfo(uidObj);
      console.log("resp", resp);
      if (resp.status == 200) {
        setLoading(false);
        setuserInfo(resp.data.payload);
        setEmail(resp.data.payload.email);
        setUserdetail(resp.data.payload);
      }
    } catch (error) {
      setLoading(false);
      console.log("handlesubmit", error);
    }
  };

  useEffect(() => {
    getUIDByUserInfo();
  }, []);

  const submitcreatePassOTP = async () => {
    setLoading(true);
    const param = {
      email: userInfo?.email,
    };
    try {
      const resp = await createPassworOtpdApi(param);
      if (resp.data.success == true) {
        setLoading(false);
        onNextClick("GetOtp");
        notifySuccess(resp.data.message);
      }
      if (resp.data.success == false) {
        setLoading(false);
        notify(resp.data.message);
        setShowLoginLink(true);
      }
    } catch (error) {
      setLoading(false);
      console.log("handleSubmit", error);
    }
  };
  const handleTermsClick = () => {
    window.location.replace("https://hikartech.com/terms-and-conditions/");
  };

  console.log("userInfo", userInfo);

  return (
    <>
      <div>
        <ToastContainer autoClose={8000}></ToastContainer>
      </div>
      <Grid container className="main-container">
        <SliderAuth />
        <Grid
          item
          xs={12}
          md={5}
          lg={4}
          className="createPassword signIn-form-wrap"
        >
          <h1 className="createPassword-text">
            Welcome {userInfo ? userInfo.first_name : ""}
          </h1>
          <div className="pb-4">
            <img
              src={
                userInfo
                  ? userInfo.company_logo
                    ? userInfo.company_logo
                    : require("../../../assets/hikar.png")
                  : require("../../../assets/hikar.png")
              }
              alt=""
              className={"logo-deafult"}
              style={{ width: "100px" }}
            />
          </div>
          {/* <h1 className="text-center p-5 pb-4">Forgot Password?</h1> */}
          <h4 className="createPasswordText text-center">Create Password</h4>
          <TextField
            id="filled-basic"
            label="Email ID"
            variant="filled"
            style={{ width: "100%", marginBottom: "20px" }}
            value={userInfo ? userInfo.email : ""}
            InputLabelProps={{
              style: {
                color: "#001323", // black color
              },
              disabled: "true",
            }}
          />
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            className="pb-3"
          >
            <Grid item xs={6} onClick={() => navigate("/")}>
              <div>
                <button className="createPassword-button">Cancel</button>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <button
                  className="createPassword-button getOTP"
                  onClick={() => submitcreatePassOTP()}
                >
                  Get OTP
                </button>
              </div>
            </Grid>
          </Grid>
          {showLoginLink && (
            <p>
              Click here to{" "}
              <Link to="/" style={{ textDecoration: "none" }}>
                login
              </Link>
            </p>
          )}

          {/* </ValidatorForm> */}
        </Grid>
      </Grid>
      {isLoading && <Loader />}
    </>
  );
}
