import { multipartRequest, request } from "../../../api/api";

export const addMaterial = (params) => {
  return request.post("/ehs_database/addMaterial/", params);
};
export const addLocation = (params) => {
  return request.post("/ehs_database/addLocation/", params);
};
export const addContainer = (params) => {
  return request.post(`/ehs_database/addContainer/`, params);
};
export const getMaterial = (filterData, params) => {
  return request.get(
    `/ehs_database/getMaterial/?search=${
      filterData.created_by__first_name
    }&material_code=${
      filterData.material_code
    }&material_description=${filterData.material_description}&unit=${
      filterData.unit
    }&hwm_rules_category_no=${
      filterData.hwm_rules_category_no
    }&page=${
      params.page + 1
    }&page_size=${params.rowsPerPage}`
  );
};

export const getLocation = (filterData, params) => {
  return request.get(
    `/ehs_database/getLocation/?search=${filterData.created_by__first_name}&location_code=${
      filterData.location_code
    }&location_name=${filterData.location_name}&page=${
      params.page + 1
    }&page_size=${params.rowsPerPage}`
  );
};
export const getContainer = (filterData, params) => {
  return request.get(
    `/ehs_database/getContainer/?search=${filterData.created_by__first_name}&container_code=${
      filterData.container_code
    }&container_name=${filterData.container_name}&page=${
      params.page + 1
    }&page_size=${params.rowsPerPage}`
  );
};

export const updateMaterialData = (id, params) => {
  return request.put(`/ehs_database/updateMaterial/${id}/`, params);
};

export const updateLocationDataAPI = (id, params) => {
  return request.put(`/ehs_database/updateLocation/${id}/`, params);
};

export const updateContainerDataAPI = (id, params) => {
  return request.put(`/ehs_database/updateContainer/${id}/`, params);
};

export const deleteMaterial = (id) =>{
    return request.put(`/ehs_database/deleteMaterial/${id}/`)
}
export const deleteLocation =(id) =>{
    return request.put(`/ehs_database/deleteLocation/${id}/`)
}
export const deleteContainerAPI =(id) =>{
    return request.put(`/ehs_database/deleteContainer/${id}/`)
}

export const exportMaterialCsv = () =>{
   return request.get(`/ehs_database/exportMaterialCSV/`)
}
export const getMaterialDataAPI =()=>{
  return request.get(`/ehs_database/getMaterial/`)
}
export const getLocationDataAPI =()=>{
  return request.get(`/ehs_database/getLocation/`)
}
export const getContainerDataAPI =()=>{
  return request.get(`/ehs_database/getContainer/`)
}
export const exportLocationCSV = () =>{
  return request.get(`/ehs_database/exportLocationCSV/`)
}

export const exportContainerCSV = () =>{
  return request.get(`/ehs_database/exportContainerCSV/`)
}

export const importCSVConfiguration = (data) => {
  return multipartRequest.post('/ehs_database/importMaterial/', data)
}
export const importCSVLocationConfiguration = (data) => {
  return multipartRequest.post('/ehs_database/importLocation/', data)
}
export const importCSVContainerConfiguration = (data) => {
  return multipartRequest.post('/ehs_database/importContainer/', data)
}

export const getDashboardCount = (params) => {
  let url = '/ehs_form/getDashboardTopCount/';
  
  const queryStrings = [];
  if (params.start_date) {
    queryStrings.push(`start_date=${params.start_date}`);
  }
  if (params.end_date) {
    queryStrings.push(`end_date=${params.end_date}`);
  }
  
  if (queryStrings.length > 0) {
    url += '?' + queryStrings.join('&');
  }

  return request.get(url);
}