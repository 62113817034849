import { Box, TextField } from "@mui/material";
import {
  DatePicker,
  LocalizationProvider,
  DateTimePicker,
} from "@mui/x-date-pickers";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";

const COCDatePicker = ({
  time,
  startDateReFormat,
  endDateReFormat,
  handleStartDate,
  handleEndDate,
}) => {
  return (
    <>
      {time ? (
        <>
          <>
            <Box>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="Start Date"
                  value={startDateReFormat}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  disableFuture
                  onChange={(newValue) => handleStartDate(newValue)}
                />
              </LocalizationProvider>
            </Box>
            <Box>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="End Date"
                  value={endDateReFormat}
                  onChange={(newValue) => handleEndDate(newValue)}
                  disableFuture
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                />
              </LocalizationProvider>
            </Box>
          </>
        </>
      ) : (
        <>
          <>
            <div className="d-flex gap-3">
              <Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Start Date"
                    value={startDateReFormat}
                    onChange={(newValue) => handleStartDate(newValue)}
                    disableFuture
                    inputFormat="DD/MM/YYYY"
                    renderInput={(params) => (
                      <TextField {...params} style={{ background: "#ffff" }} />
                    )}
                  />
                </LocalizationProvider>
              </Box>
              <Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="End Date"
                    inputFormat="DD/MM/YYYY"
                    value={endDateReFormat}
                    onChange={(newValue) => handleEndDate(newValue)}
                    disableFuture
                    renderInput={(params) => (
                      <TextField {...params} style={{ background: "#ffff" }} />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </div>
          </>
        </>
      )}
    </>
  );
};

export default COCDatePicker;
