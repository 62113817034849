import React from "react";
import "../AnalyticsTabs/AnalyticsTabs.css";

const COCPOPTabs = ({ active, setActive }) => {
  return (
    <>
      <div className="analytics-tabs-btn">
        <div className="d-flex">
          <button
            className={`${
              active == "Consumer"
                ? "Analytics-tabs-overview"
                : "Reports-tabs-overview"
            }`}
            onClick={() => setActive("Consumer")}
          
          >
            Consumer over Consumer
          </button>
          <button
            className={`${
              active == "Period"
                ? "Analytics-tabs-overview"
                : "Reports-tabs-overview"
            }`}
            onClick={() => setActive("Period")}
            
          >
            Period over Period
          </button>
        </div>
      </div>
    </>
  );
};

export default COCPOPTabs;
