import { request, multipartRequest } from "../../api/api"

// User management - get api 
export const getUser = (params) => {
    return request.get(`/user_management/getUserManagement/?search=${params.first_name || params.last_name || params.department__department_name || params.user_role__name || params.email}`);
}

// User management - get department list
export const getUserDepartment = (data) => {
    return request.post('access_management/getDepartment/', data);
}

//User management - add department
export const adduserDepartment = (data) => {
    return request.post('/user_management/addUserManagement/', data)
}

//User management - upadate department
export const updateUserDepartment = (id, data) => {
    return request.put(`/user_management/updateUserManagement/${id}/`, data)
}

//User management - delete department
export const deleteUserDepartment = (id) => {
    return request.put(`/user_management/deleteUserManagement/${id}/`)
}

export const exportUserReport = (params) => {
    return request.post(`/user_management/exportUserManagementExcelReport/`, params)
}

export const deleteMultipleUser = (params) => {
    return request.post(`/user_management/deleteMultipleUserManagement/`, params)
}

// delete access management data
export const deleteAccessManagement = (id) => {
    return request.put(`/access_management/deleteAccessManagement/${id}/`)
}

export const exportAccessManagement = (params) => {
    return request.post(`/access_management/exportAccessManagementExcelReport/`, params)
}

export const getDepartmentBycompany = (data, serchParam) => {
    return request.get(`/access_management/getAccessManagement/?search=${serchParam.department__department_name || serchParam.role__role_name}`, data);
}

export const getCompanyByDetails = (params) => {
    return request.post(`/client_managent/getCompanyDetail/`, params)
}

export const getAccessModule = (id) => {
    return request.get(`/access_management/getAccessModule/`)
}

export const getDepartment = (data) => {
    return request.post('/access_management/getDepartment/', data);
}

// add Accessmanagement
export const addAccessManagement = (data) => {
    return request.post('/access_management/addAccessManagement/', data)
}

//Access Management -- accessModule

export const getAccessModuleByCompany = (id) => {
    return request.get(`/access_management/getAccessModule/?company_id=${id}`)
}

//Access Management -- delete department
export const deleteDepartment = (id) => {
    return request.put(`/access_management/deleteDepartment/${id}/`)
}

//Access Management -- delete role

export const deleteRole = (id) => {
    return request.put(`/access_management/deleteRole/${id}/`)
}

//Access management - add department
export const addDepartment = (data) => {
    return request.post('/access_management/addDepartment/', data)
}

//  get access managementBy Id data
export const getAccessManagementById = (id) => {
    return request.get(`/access_management/getAccessManagement/${id}/`)
}

//Access Management -- Edit Department

export const editDepartManage = (id, params) => {
    return request.put(`/access_management/updateDepartment/${id}/`, params)
}

//Access Management --- update Role

export const editRoleManage = (id, params) => {
    return request.put(`/access_management/updateRole/${id}/`, params)
}

//Access Management -- Add role

export const addRoleAPI = (params) => {
    return request.post(`/access_management/addRole/`, params)
}

export const exportDepartmentExcel = (params) => {
    return request.post(`/access_management/exportDepartmentManagementExcelReport/`, params)
}


//Client Management && Plant management - get plant api
export const getPlantByCompany = (data) => {
    return request.post('/plant_management/getPlant/', data)
}

export const getcompanyListPlantManegement = () => {
    return request.get('/client_managent/getCompanyList/')
}

export const getReportConfigure = (params) => {
    return request.post("/report_management/getReportConfiguration/", params);
};

export const getDepartmentForManagement = (params) => {
    return request.post(`/access_management/getDepartment/`, params)
}

export const getDepartmentRole = (params) => {
    return request.post(`/report_management/getDepartmentRoleUser/`, params)
}

export const createReportConfigure = (params) => {
    return request.post("/report_management/addReportpConfiguration/", params);
};

export const updateReportConfigure = (id, params) => {
    return request.put(`/report_management/updateReportpConfiguration/${id}/`, params);
};
